export const state = () => ({
  count: 1,
  appointments: [],
  chats: [],
  isActiveCCM: false,
  accumulatedTime: null,
  searchedPatients: [],
});

export const mutations = {
  SET_APPOINTMENTS(state, data) {
    data.map((e) => (e.id ? e : (e.id = state.count)));
    state.appointments = data;
    state.count++;
  },
  SET_CHATS(state, data) {
    state.chats = data;
  },
  SET_ACTIVE_CCM(state, data) {
    state.isActiveCCM = data;
  },
  SET_ACCUMULATED_TIME(state, data) {
    state.accumulatedTime = data;
  },
  SET_SEARCHED_PATIENTS(state, data) {
    state.searchedPatients = data;
  },
};

export const actions = {
  async fetchSearchedChatPatients(
    { state, commit },
    { organizationId, payload }
  ) {
    let result = await this.$backend.getSearchedChatPatients(
      organizationId,
      payload
    );
    commit("SET_SEARCHED_PATIENTS", result.data);
    return result;
  },
};

export const getters = {
  getChats: (state) => () => {
    return state.chats;
  },
  getSearchedPatients: (state) => () => {
    return state.searchedPatients;
  },
};
