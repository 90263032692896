export const state = () => ({
  filters: null,
  currentPage: 1,
  currentRoute: null,
});

export const mutations = {
  SET_FILTERS(state, data) {
    state.filters = data;
  },
  SET_CURRENT_PAGE(state, data) {
    state.currentPage = data;
  },
  SET_CURRENT_ROUTE(state, data) {
    state.currentRoute = data;
  },
};

export const actions = {
  setFilters({ commit }, { filters }) {
    commit("SET_FILTERS", filters);
  },
  setCurrentPage({ commit }, { currentPage }) {
    commit("SET_CURRENT_PAGE", currentPage);
  },
  setCurrentRoute({ commit }, { currentRoute }) {
    commit("SET_CURRENT_ROUTE", currentRoute);
  },
};

export const getters = {
  getFilters: (state) => () => {
    return state.filters;
  },
  getCurrentPage: (state) => () => {
    return state.currentPage;
  },
  getCurrentRoute: (state) => () => {
    return state.currentRoute;
  },
};
