export const state = () => ({
  data: [],
  pagination: {},
});

export const mutations = {
  SET_ARTICLES(state, articles) {
    if (articles.page === 1) {
      state.data = articles.data;
    } else {
      state.data.push(...articles.data);
    }
    state.pagination = articles.pagination;
  },
  ADD_ARTICLE(state, article) {
    state.data.unshift(article);
  },
  UPDATE_ARTICLE(state, updatedArticle) {
    const index = state.data.findIndex(
      (article) => article.id === updatedArticle.id
    );
    if (index !== -1) {
      state.data.splice(index, 1, updatedArticle);
    }
  },
  DELETE_ARTICLE(state, articleId) {
    state.data = state.data.filter((article) => article.id !== articleId);
  },
  SET_ARTICLES_ORDER(state, articles) {
    state.data = articles;
  },
};

export const actions = {
  async fetchArticles(
    { commit },
    { organization_id, topic_id, page = 1, per_page = 50 }
  ) {
    const response = await this.$backend.fetchArticles(organization_id, {
      topic_id,
      page,
      per_page,
    });
    commit("SET_ARTICLES", {
      data: response.data,
      pagination: response.pagination,
      page,
    });
  },
  async createArticle({ commit, state }, { organization_id, articleData }) {
    const response = await this.$backend.createArticle(
      organization_id,
      articleData
    );
    commit("ADD_ARTICLE", response.data);

    commit(
      "SET_ARTICLES_ORDER",
      state.data.map((item, index) => ({ ...item, position: index + 1 }))
    );
  },
  async updateArticle({ commit }, { organization_id, articleId, articleData }) {
    const response = await this.$backend.updateArticle(
      organization_id,
      articleId,
      articleData
    );
    commit("UPDATE_ARTICLE", response.data);
  },
  async deleteArticle({ commit }, { organization_id, articleId }) {
    await this.$backend.deleteArticle(organization_id, articleId);
    commit("DELETE_ARTICLE", articleId);
  },
  async orderArticles(
    { commit },
    { organization_id, topic_id, articles, start_order }
  ) {
    await this.$backend.orderArticles(
      organization_id,
      topic_id,
      articles.map((item) => item.id),
      start_order
    );
    commit(
      "SET_ARTICLES_ORDER",
      articles.map((item, index) => ({
        ...item,
        position: index + start_order,
      }))
    );
  },
};
