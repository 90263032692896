export const state = () => ({
  patient: null,
  now: null,
  expires: null,
});

export const mutations = {
  setPatientData(state, data) {
    state.patient = data.patient;
    state.now = data.now;
    state.expires = data.expires;
  },
};

export const actions = {
  async authenticate({ commit }, { magic_link_id }) {
    return this.$axios
      .$post(`auth/magic-link`, {
        id: magic_link_id,
      })
      .then((result) => {
        commit("setPatientData", result.data);
        this.patient = result.data.patient;
        console.log(result);
        this.loading = false;
      })
      .catch((e) => {
        console.log(e);
        this.loading = false;
        this.hasError = true;
        this.authError = this.$buildError(e).message;
      });
  },
};

export const getters = {
  // patient(state) {
  //   return state.patient;
  // },
};
