import moment from "moment-timezone";

export const state = () => ({
  all_bookings: [],
  todays_bookings: [],
  booking_event_list: [],
  selected_event: null,
  selectedBooking: {
    booking: null,
    status: null,
  },
  provider_hours: [],
  services: [],
});

export const mutations = {
  SET_SELECTED_BOOKING(state, data) {
    state.selectedBooking = data;
  },
  SET_BOOKINGS(state, data) {
    state.all_bookings = data.bookings;
    state.booking_event_list = data.bookings.map((booking) => {
      const start = `${booking.date} ${booking.start_time}`;
      const end = `${booking.date} ${booking.end_time}`;
      const userTimezone = booking.user.timezone || "UTC";

      return {
        title: `${booking.user?.display_name} - ${booking.patient?.display_name}: ${booking.service?.name}`,
        end: moment.tz(end, userTimezone).toDate(),
        start: moment.tz(start, userTimezone).toDate(),
        data: { ...booking },
        mappedDate: moment
          .tz(start, userTimezone)
          .locale(data.locale)
          .format("L LT"),
      };
    });
  },
  SET_TODAYS_BOOKINGS(state, data) {
    state.todays_bookings = data.bookings;
  },
  SET_SELECTED_EVENT(state, data) {
    state.selected_event = data;
  },
  UPDATE_SELECTED_EVENT_STATUS(state, data) {
    state.selected_event.data.status = data.status;
  },
  SET_PROVIDER_HOURS(state, data) {
    state.provider_hours = data;
  },
  SET_SERVICES(state, data) {
    state.services = data;
  },
};

export const actions = {
  async fetchBookings({ commit }, { organizationId, params, locale }) {
    const { data } = await this.$backend.fetchBookings(organizationId, params);
    commit("SET_BOOKINGS", { bookings: data, locale });
  },
  async fetchTodaysBookings({ commit }, { organizationId, params }) {
    const { data } = await this.$backend.fetchBookings(organizationId, params);
    commit("SET_TODAYS_BOOKINGS", { bookings: data });
  },
  async createBooking({ dispatch }, { organizationId, bookingData }) {
    await this.$backend.createBooking(organizationId, bookingData);
    await dispatch("fetchBookings", { organizationId });
  },
  async updateBooking(
    { dispatch },
    { organizationId, bookingId, bookingData }
  ) {
    await this.$backend.updateBooking(organizationId, bookingId, bookingData);
    await dispatch("fetchBookings", { organizationId });
  },
  async updateBookingStatus(
    { dispatch },
    { organizationId, bookingId, payload }
  ) {
    await this.$backend.updateBookingStatus(organizationId, bookingId, payload);
    await dispatch("fetchBookings", { organizationId });
  },
  async fetchProviderHours({ commit }, { organizationId, params }) {
    const { data } = await this.$backend.fetchProviderHours(
      organizationId,
      params
    );
    commit("SET_PROVIDER_HOURS", data);
  },
  async fetchServices({ commit }, organizationId) {
    const { data } = await this.$backend.fetchServices(organizationId);
    commit("SET_SERVICES", data);
    return data;
  },
};

export const getters = {
  all_bookings: (state) => {
    return state.all_bookings;
  },
  todays_bookings: (state) => {
    return state.todays_bookings;
  },
  booking_event_list: (state) => {
    return state.booking_event_list;
  },
  selected_event: (state) => {
    return state.selected_event;
  },
  selectedBooking: (state) => {
    return state.selectedBooking;
  },
  provider_hours: (state) => {
    return state.provider_hours;
  },
};
