import Vue from "vue";

export const state = () => ({
  trackers: [],
  currentTracker: {
    type: null,
    timer: 0,
    time: null,
  },
  timerStarted: false,
  startedTimer: null,
  timerToBeEdited: null,
  selectedDate: null,
  shouldInactivityModalShow: false,
  newCallJustEnded: false,
});

export const mutations = {
  SET_CALL_ENDED(state, data) {
    state.newCallJustEnded = data;
  },
  SET_SELECTED_DATE(state, data) {
    state.selectedDate = data;
  },
  SET_SHOW_INACTIVITY(state, data) {
    state.shouldInactivityModalShow = data;
  },
  SET_TIMER_TO_BE_EDITED(state, data) {
    state.timerToBeEdited = data;
  },
  START_TIMER(state, data) {
    state.currentTracker.time = data.time;
    state.currentTracker.type = data.type;
    state.currentTracker.timer += 1;
  },
  SET_COUNTER(state, data) {
    state.currentTracker = data;
  },
  setTrackers(state, data) {
    state.trackers = data;
  },
  SET_STARTED_TIMER(state, intervalId) {
    state.startedTimer = intervalId;
  },
  RESET_TIMER(state, data) {
    state.shouldInactivityModalShow = false;
    state.currentTracker.type = null;
    state.currentTracker.timer = 0;
    state.currentTracker.time = null;
  },
};

export const actions = {
  async fetchTrackers({ commit }, { organization_id }) {
    let data = await this.$axios.$get(`${organization_id}/trackers`);

    commit("setTrackers", data.data);
  },
  async startTimer({ commit }, { data }) {
    commit("RESET_TIMER", data);
    commit("START_TIMER", data);

    const intervalId = setInterval(() => {
      commit("START_TIMER", data);
    }, 1000);

    commit("SET_COUNTER", {
      type: data.type,
      timer: data.timer ? data.timer : 0,
    });
    commit("SET_STARTED_TIMER", intervalId);
  },
  async stopTimer({ commit, state }) {
    clearInterval(state.startedTimer);
    commit("SET_STARTED_TIMER", null);
    commit("RESET_TIMER");
  },
};

export const getters = {
  getCallState: (state) => () => {
    return state.newCallJustEnded;
  },
  getCurrentTracker: (state) => () => {
    return state.currentTracker;
  },
  getTrackers: (state) => () => {
    return state.trackers;
  },
  editTimer: (state) => () => {
    return state.timerToBeEdited;
  },
  inactivityReached: (state) => () => {
    return state.shouldInactivityModalShow;
  },

  getStartedTimer: (state) => () => {
    return state.startedTimer;
  },

  getSelectedDate: (state) => () => {
    return state.selectedDate;
  },
};
