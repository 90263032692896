import Vue from "vue";
import VueI18n from "vue-i18n";

// Tell Vue to use our plugin
Vue.use(VueI18n);

export default ({ app }) => {
  // Set the i18n instance on app
  // This way we can use it globally in our components through this.$i18n
  app.i18n = new VueI18n({
    // Set the initial locale
    locale: "en",

    // Set the fallback locale in case the current locale can't be found
    fallbackLocale: "en",
    silentFallbackWarn: false,
    silentTranslationWarn: true,
    // Associate each locale to a content file
    messages: {
      en: require("~/locales/en.json"),
      es: require("~/locales/es.json"),
      tr: require("~/locales/tr.json"),
      de: require("~/locales/de.json"),
    },
  });
};
