export const state = () => ({
  headers: [],
});

export const mutations = {
  SET_HEADERS(state, headers) {
    state.headers = headers;
  },
};

export const actions = {
  setTableHeaders({ commit }, { headers }) {
    commit("SET_HEADERS", headers);
  },
};

export const getters = {
  getHeaders:
    (state) =>
    (app, actions_key = "actions") => {
      let headers = [];
      let configHeaders = app.$vianovaConfig.options.tables
        ? app.$vianovaConfig.options.tables.patients_table_headers
        : [];

      if (state.headers.length) {
        headers = [...state.headers];
        headers = headers.map((header) => {
          const configHeader = configHeaders.find((a) => a.key === header.key);
          if (configHeader) {
            configHeader.label = app.$te(
              `tables.patients_table_headers.${header.key}`
            )
              ? app.$t(`tables.patients_table_headers.${header.key}`)
              : header.label;
          }
          return configHeader;
        });
      } else {
        headers = configHeaders.filter((header) => header.default);
        headers = headers.map((header) => {
          header.label = app.$te(`tables.patients_table_headers.${header.key}`)
            ? app.$t(`tables.patients_table_headers.${header.key}`)
            : header.label;
          return header;
        });
      }

      let action_index = -1;
      if (
        app.$vianovaConfig.options.tables &&
        app.$vianovaConfig.options.tables.action_index !== undefined
      ) {
        action_index = app.$vianovaConfig.options.tables.action_index;
      }
      if (action_index === -1) {
        action_index = headers.length;
      }

      if (
        !app.$vianovaConfig.options.tables.patients_table_options?.hide_actions
      ) {
        headers.splice(action_index, 0, {
          key: actions_key,
          label: app.$t("tables.actions"),
          display: true,
          stickyColumn: true,
        });
      }

      return headers;
    },
};
