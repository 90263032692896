import moment from "moment-timezone";
export const state = () => ({
  assessments: [],
  currentAssessment: null,
});

export const mutations = {
  setAssessments(state, data) {
    state.assessments = data;
  },
  addAssessment(state, data) {
    state.assessments.unshift(data);
  },
  deleteAssessment(state, id) {
    state.assessments = state.assessments.filter(
      (assessment) => assessment.id !== id
    );
  },
  updateAssessment(state, data) {
    state.assessments = state.assessments.map((assessment) => {
      if (assessment.id === data.id) {
        return data;
      }
      return assessment;
    });
  },
};

export const actions = {
  async fetchAssessments({ commit, state }, { patient_id, organization_id }) {
    const start_date = moment().subtract(1,"year").startOf('month').format('YYYY-MM-DD');
    const end_date = moment().endOf('month').format('YYYY-MM-DD');
    let data = await this.$axios.$get(
      `${organization_id}/patients/${patient_id}/activities/ccm_assessment/responses?start_date=${start_date}&end_date=${end_date}`
    );
    commit("setAssessments", data.data);
  },
  async deleteAssessment({ commit }, { patient_id, organization_id, id }) {
    await this.$axios.$delete(
      `${organization_id}/patients/${patient_id}/activities/responses/${id}`
    );
    commit("deleteAssessment", id);
  },
  async updateAssessment(
    { commit },
    { patient_id, organization_id, payload, id }
  ) {
    // Route::patch('patients/{patient}/activities/responses/{responseId}', [ActivitiesController::class, 'updateResponse']);
    let data = await this.$axios.$patch(
      `${organization_id}/patients/${patient_id}/activities/responses/${id}`,
      payload
    );
    commit("updateAssessment", data.data);
  },
  async createAssessment({ commit }, { patient_id, organization_id, payload }) {
    let data = await this.$axios.$post(
      `${organization_id}/patients/${patient_id}/activities/responses`,
      payload
    );
    commit("addAssessment", data.data.activity_response);

    return data.data;
  },
};

export const getters = {
  // patient(state) {
  //   return state.patient;
  // },
  getAssessments: (state) => () => {
    return state.assessments
  },
};
