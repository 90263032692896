export const state = () => ({
  track: [],
  trackDetails: null,
  activity_types: [],
  rcb_data: [],
});

export const mutations = {
  addTrackItemToTrack(state, data) {
    data.forEach((element) => {
      state.track.push(element);
    });
    // state.track.push(data);
  },
  setRcbData(state, data) {
    state.rcb_data = data;
  },
  setActivityTypes(state, data) {
    state.activity_types = data;
  },
  setTrack(state, data) {
    state.track = data;
  },
  setTrackDetails(state, data) {
    state.trackDetails = data;
  },
  deleteTrackItem(state, item_id) {
    var filtered = state.track.filter((element) => {
      return element.id !== Number.parseInt(item_id);
    });
    state.track = filtered;
  },
};
export const getters = {
  getActivityTypesFiltered: (state) => () => {
    return state.activity_types
      .map((e) => ({
        activity_type_id: e.id,
        activity_type_slug: e.slug,
        key: e.slug ? e.slug : e.data.slug,
        slug: e.slug ? e.slug : e.data.slug,
        display_name: e.name,
      }))
      .filter(
        (e) =>
          e.key != "glucose" &&
          e.key != "weight" &&
          e.key != "oxygen_saturation" &&
          e.key != "heart_rate" &&
          e.key != "blood_pressure" &&
          e.key != "appointment" &&
          e.key != "summary" &&
          e.key != "reminders" &&
          e.key != "tasks" &&
          e.key != "education" &&
          e.key != "medications"
      );
  },
  getActivityType: (state) => (activity_type_id) => {
    const item = state.activity_types.find(
      (todo) => todo.id === Number.parseInt(activity_type_id)
    );
    return item;
  },
  getActivityTypeBySlug: (state) => (slug) => {
    const item = state.activity_types.find((todo) => todo.slug === slug);
    return item;
  },
  getTasksForDay:
    (state, getters) =>
    (day, filter = null) => {
      let result = [];
      state.track.forEach((task) => {
        let activityType = null;
        if (task.data.activity_type_slug == "medication") {
          activityType = { name: "Medication", slug: "medication" };
        } else {
          activityType = getters.getActivityType(task.data.activity_type_id);
          if (!activityType) return;
        }

        task.activity_type = activityType;

        if (filter) {
          console.log(`display name`, task.data?.configuration?.display_name);
          if (task.data?.configuration?.display_name) {
            if (
              task.data?.configuration?.display_name
                .toLowerCase()
                .indexOf(filter.toLowerCase()) == -1
            ) {
              if (
                activityType.name.toLowerCase().indexOf(filter.toLowerCase()) ==
                -1
              ) {
                return;
              }
            }
          } else {
            return;
          }
        }

        let frequency = task.data.frequency;
        let interval = 999;
        let offset = 0;
        let end = 2000000000;

        if (task.data.reminder?.custom_data?.offset) {
          offset = Number.parseInt(task.data.reminder?.custom_data?.offset);
        } else if (task.data.configuration.offset) {
          offset = Number.parseInt(task.data.configuration.offset);
        }
        if (task.data.reminder?.custom_data?.interval) {
          interval = Number.parseInt(task.data.reminder?.custom_data?.interval);
        } else if (task.data.configuration.offset) {
          interval = Number.parseInt(task.data.configuration.interval);
        }
        if (task.data.reminder?.custom_data?.end) {
          end = Number.parseInt(task.data.reminder?.custom_data?.end);
        } else if (task.data.configuration.end) {
          end = Number.parseInt(task.data.configuration.end);
        }
        if (day >= offset && day < end + offset) {
          if (frequency === "daily") {
            result.push(task);
          } else if (frequency === "custom") {
            if (interval == 0) interval = 999;
            if (interval == 1) {
              if (day >= offset) {
                if (end && day > end) return;
                result.push(task);
              }
            } else if ((day - offset) % interval == 0) {
              if (end && day > end) return;
              result.push(task);
            }
          } else if (frequency === "once") {
            // let offset = task.data.reminder?.custom_data?.offset ?? 0;
            if (day == offset) {
              result.push(task);
            }
          } else if (frequency === "weekly") {
            let weeklyData = task.data.reminder.weekly_data;
            if (day % 7 == 0 && "monday" in weeklyData) {
              result.push(task);
            }
            if (day % 7 == 1 && "tuesday" in weeklyData) {
              result.push(task);
            }
            if (day % 7 == 2 && "wednesday" in weeklyData) {
              result.push(task);
            }
            if (day % 7 == 3 && "thursday" in weeklyData) {
              result.push(task);
            }
            if (day % 7 == 4 && "friday" in weeklyData) {
              result.push(task);
            }
            if (day % 7 == 5 && "saturday" in weeklyData) {
              result.push(task);
            }
            if (day % 7 == 6 && "sunday" in weeklyData) {
              result.push(task);
            }
          } else if (frequency === "monthly") {
            let dayOfMonth = Number.parseInt(task.data.reminder.day_of_month);
            if (day % 28 == dayOfMonth - 1) {
              result.push(task);
            }
          } else if (frequency === "until_done") {
            result.push(task);
          }
        }
      });
      return result;
    },
};
export const actions = {
  async getRcbData({ commit }) {
    let organizationId = localStorage.getItem("organizationId");
    let data = await this.$axios.$get(`${organizationId}/rcb_data`);
    commit("setRcbData", data.data);
    return data.data;
  },
  async addTrackItem({ dispatch }, { track_id, data }) {
    let organizationId = localStorage.getItem("organizationId");
    await this.$axios.$post(
      `admin/${organizationId}/diseases/${track_id}/plan`,
      data
    );
    dispatch("getTrack", track_id);
    // commit("addTrackItemToTrack", response);
  },
  async editTrackItem({ dispatch }, { track_id, item_id, data }) {
    let organizationId = localStorage.getItem("organizationId");
    await this.$axios.$patch(
      `admin/${organizationId}/diseases/${track_id}/plan/${item_id}`,
      data
    );
    dispatch("getTrack", track_id);
  },
  async deleteTrackItem({ commit }, { track_id, item_id }) {
    let organizationId = localStorage.getItem("organizationId");
    await this.$axios.$delete(
      `admin/${organizationId}/diseases/${track_id}/plan/${item_id}`
    );
    commit("deleteTrackItem", item_id);
  },
  async getActivityTypes({ commit }) {
    let organizationId = localStorage.getItem("organizationId");
    let data = await this.$axios.$get(`admin/${organizationId}/activity-types`);
    commit("setActivityTypes", data);
    return data;
  },
  async clearTrack({ commit }) {
    commit("setTrack", []);
  },
  async getTrackDetails({ commit }, track_id) {
    commit("setTrackDetails", null);
    let organizationId = localStorage.getItem("organizationId");
    let data = await this.$axios.$get(
      `admin/${organizationId}/diseases/${track_id}`
    );
    commit("setTrackDetails", data);
    return data;
  },
  async updateTrackDetails({ commit }, { track_id, data }) {
    let organizationId = localStorage.getItem("organizationId");
    let response = await this.$axios.$patch(
      `admin/${organizationId}/diseases/${track_id}`,
      data
    );
    commit("setTrackDetails", response);
    return response;
  },
  async getTrack({ commit }, track_id) {
    let organizationId = localStorage.getItem("organizationId");
    let data = await this.$axios.$get(
      `admin/${organizationId}/diseases/${track_id}/plan`
    );
    commit("setTrack", data);
    return data;
  },
};
