export const state = () => ({
  existing_filters: [],
  models: [],
  allowed_fields: [],
});

export const mutations = {
  SET_MODELS(state, data) {
    state.models = data;
  },
  SET_ALLOWED_FIELDS(state, data) {
    state.allowed_fields = data;
  },
  SET_EXISTING_FILTERS(state, data) {
    state.existing_filters = data;
  },
  SET_NEW_FILTERS(state, data) {
    state.existing_filters.push(data);
  },
};

export const actions = {
  async fetchExistingFilters({ commit }, { organizationId }) {
    let filters = await this.$axios.$get(`${organizationId}/advanced-queries`);
    commit("SET_EXISTING_FILTERS", filters.data);
  },
  async fetchModels({ commit }, { organizationId }) {
    let models = await this.$axios.$get(
      `${organizationId}/advanced-queries/models`
    );
    commit("SET_MODELS", models.data);
  },

  async fetchAllowedFields({ commit }, { organizationId, model }) {
    let fields = await this.$axios.$get(
      `${organizationId}/advanced-queries/configs/${model}`
    );
    commit("SET_ALLOWED_FIELDS", fields.data);
  },

  async getSingularFilter({ commit }, { organizationId, filterId }) {
    let singularFilter = this.$axios.$get(
      `${organizationId}/advanced-queries/${filterId}`
    );

    return singularFilter;
  },

  async addFilter({ commit }, { payload }) {
    let result = await this.$axios.$post(
      `${localStorage.getItem("organizationId")}/advanced-queries`,
      payload
    );
    commit("SET_NEW_FILTERS", result.data);
    return result;
  },

  async editFilter({ commit }, { payload, filterId }) {
    let result = await this.$axios.$put(
      `${localStorage.getItem("organizationId")}/advanced-queries/${filterId}`,
      payload
    );
    return result;
  },

  async deleteFilter({ commit }, { organizationId, filterId }) {
    let result = await this.$axios.$delete(
      `${organizationId}/advanced-queries/${filterId}`
    );
    return result.data;
  },

  async applyFilter({ commit }, { organizationId, filterId }) {
    let result = await this.$axios.$get(
      `${organizationId}/advenced-queries/${filterId}/execute`
    );
    return result;
  },
};

export const getters = {
  getModels: (state) => () => {
    return state.models;
  },

  getFilters: (state) => () => {
    return state.existing_filters;
  },
  getAllowedFields: (state) => () => {
    return state.allowed_fields;
  },
};
