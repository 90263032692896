export const state = () => ({
  data: [],
  pagination: {},
  patientRewards: {
    data: [],
    pagination: {},
  },
  claimed: {
    data: [],
    pagination: {},
  },
});

export const mutations = {
  SET_REWARDS(state, rewards) {
    if (rewards.page === 1) {
      state.data = rewards.data;
    } else {
      state.data.push(...rewards.data);
    }
    state.pagination = rewards.pagination;
  },
  SET_PATIENT_REWARDS(state, rewards) {
    if (rewards.page === 1) {
      state.patientRewards.data = rewards.data;
    } else {
      state.patientRewards.data.push(...rewards.data);
    }
    state.patientRewards.pagination = rewards.pagination;
  },
  SET_CLAIMED_REWARDS(state, claimedRewards) {
    if (claimedRewards.page === 1) {
      state.claimed.data = claimedRewards.data;
    } else {
      state.claimed.data.push(...claimedRewards.data);
    }
    state.claimed.pagination = claimedRewards.pagination;
  },
  ADD_REWARD(state, reward) {
    state.data.unshift(reward);
  },
  UPDATE_REWARD(state, updatedReward) {
    const index = state.data.findIndex(
      (reward) => reward.id === updatedReward.id
    );
    if (index !== -1) {
      state.data.splice(index, 1, updatedReward);
    }
  },
  DELETE_REWARD(state, rewardId) {
    state.data = state.data.filter((reward) => reward.id !== rewardId);
  },
  RESET_STATE(state) {
    Object.assign(state, {
      data: [],
      pagination: {},
      patientRewards: {
        data: [],
        pagination: {},
      },
      claimed: {
        data: [],
        pagination: {},
      },
    });
  },
};

export const actions = {
  async fetchRewards({ commit }, { organization_id, page = 1, per_page = 15 }) {
    const response = await this.$backend.fetchRewards(organization_id, {
      page,
      per_page,
    });

    commit("SET_REWARDS", {
      data: response.data,
      pagination: response.pagination,
      page,
    });
  },
  async fetchPatientRewards(
    { commit },
    { organization_id, patient_id, page = 1, per_page = 10 }
  ) {
    const response = await this.$backend.fetchPatientRewards(
      organization_id,
      patient_id,
      {
        page,
        per_page,
      }
    );
    commit("SET_PATIENT_REWARDS", {
      data: response.data,
      pagination: response.pagination,
      page,
    });
  },
  async fetchClaimedRewards(
    { commit },
    { organization_id, patient_id, page = 1, per_page = 10 }
  ) {
    const response = await this.$backend.fetchClaimedRewards(
      organization_id,
      patient_id,
      {
        page,
        per_page,
      }
    );
    commit("SET_CLAIMED_REWARDS", {
      data: response.data,
      pagination: response.pagination,
      page,
    });
  },
  async createReward({ commit }, { organization_id, rewardData }) {
    const response = await this.$backend.createReward(
      organization_id,
      rewardData
    );
    commit("ADD_REWARD", response.data);
  },
  async updateReward({ commit }, { organization_id, rewardId, rewardData }) {
    const response = await this.$backend.updateReward(
      organization_id,
      rewardId,
      rewardData
    );
    commit("UPDATE_REWARD", response.data);
  },
  async deleteReward({ commit }, { organization_id, rewardId }) {
    await this.$backend.deleteReward(organization_id, rewardId);
    commit("DELETE_REWARD", rewardId);
  },
  async claimReward({ commit }, { organization_id, patient_id, reward_id }) {
    await this.$backend.claimReward(organization_id, patient_id, reward_id);
  },
  resetStore({ commit }) {
    commit("RESET_STATE");
  },
};
