import Vue from "vue";

export const state = () => ({
  groups: [],
  search: null,
  organization_groups: {
    data: [],
    pagination: {
      current_page: 1,
      item_per_page: 10,
      total: 0,
      last_page: 1,
    },
  },
  loading: false,
  group: null,
  users: {},
});

export const mutations = {
  setGroup(state, data) {
    state.group = data;
  },
  setOrganizationGroups(state, data) {
    if (data === null) {
      state.organization_groups = {
        data: [],
        pagination: {
          current_page: 1,
          item_per_page: 10,
          total: 0,
          last_page: 1,
        }
      };
    } else {
      state.organization_groups.data = data.data;
      state.organization_groups.pagination = data.pagination;
    }

  },
  setGroups(state, data) {
    state.groups = data;
  },
  setGroupUsers(state, data) {
    Vue.set(state.users, data.group_id, data.data);
  },
  setSearch(state, data) {
    state.search = data;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
};

export const actions = {
  async createGroup({dispatch}, payload) {
    let data = await this.$axios.$post(`admin/${payload.organization_id}/groups`, payload);
    dispatch("fetchOrganizationGroups", {organization_id: payload.organization_id});
    return data;
  },
  async updateGroup({dispatch}, {group_id, payload}) {
    let data = await this.$axios.$patch(`admin/${payload.organization_id}/groups/${group_id}`, payload);
    dispatch("fetchOrganizationGroups", {organization_id: payload.organization_id});
    return data;
  },
  async fetchOrganizationGroups({commit}, {organization_id, page = 1, per_page = 100, clear = false}) {
    let data = await this.$axios.$get(`admin/${organization_id}/groups?page=${page}&per_page=${per_page}`);
    commit("setOrganizationGroups", data);
    return data.data;
  },
  async getGroup({commit}, {organization_id, group_id}) {
    commit("setLoading", true);
    commit("setGroup", null);
    if (group_id === null) return;
    let data = await this.$axios.$get(`admin/${organization_id}/groups/${group_id}`);
    commit("setGroup", data.data);
    commit("setLoading", false);
  },
  async fetchUsersForGroup(
    {commit},
    {organization_id, group_id, page = 1, per_page = 10}
  ) {
    let data = await this.$axios.$get(
      `${organization_id}/groups/${group_id}/users?page=${page}&per_page=${per_page}`
    );
    commit("setGroupUsers", {group_id, data: data.data});
    return data.data;
  },
  async deleteGroup({dispatch}, {organization_id, group_id}) {
    let data = await this.$axios.$delete(
      `admin/${organization_id}/groups/${group_id}`
    );
    dispatch("fetchOrganizationGroups", {organization_id: organization_id});
    return data;
  },
  async fetchGroups({commit, dispatch}, {organization_id, search}) {
    commit("setLoading", true);
    commit("setGroups", []);
    let data = await this.$axios.$get(`${organization_id}/groups`, {
      params: {
        search
      },
    });
    commit("setLoading", false);
    commit("setGroups", data.data);
    return data.data;
  },
};
export const getters = {
  groupUsers: (state) => (group_id) => {
    return state.users[group_id];
  },
  getGroupSearch: (state) => () => {
    return state.search;
  },
};
