export const state = () => ({
  data: [],
});

export const mutations = {
  SET_BADGES(state, data) {
    state.data = data;
  },
};

export const actions = {
  async fetchBadges({ commit }, { organization_id, patient_id }) {
    const response = await this.$backend.getBadges(organization_id, patient_id);

    commit("SET_BADGES", response.data);
  },
};
