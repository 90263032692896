export const state = () => ({
  paymentMethods: []
})

export const mutations = {
  SET_PAYMENT_METHODS(state, data) {
    state.paymentMethods = data
  },
};

export const actions = {
  async fetchPaymentIntent({state, commit}, {organizationId}) {
    let result = await this.$backend.getPaymentIntent(organizationId)
    return result.data
  },
  async fetchPaymentMethods({state, commit}, {organizationId}) {
    let result = await this.$backend.getPaymentMethods(organizationId)
    commit('SET_PAYMENT_METHODS', result.data)
    return result.data
  },
  async addPaymentMethod({state, commit}, {organizationId, payload}) {
    let result = await this.$backend.addPaymentMethod(organizationId, payload)
    return result.data
  },
  async addDefaultPaymentMethod({state, commit}, {organizationId, payload}) {
    let result = await this.$backend.addDefaultPaymentMethod(organizationId, payload)
    return result.data
  },
  async deletePaymentMethod({state, commit}, {organizationId, payment}) {
    let result = await this.$backend.deletePaymentMethod(organizationId, payment)
    return result.data
  },
  async createCharge({state, commit}, {organizationId, payload}) {
    let result = await this.$backend.createCharge(organizationId, payload)
    return result.data
  },
}

export const getters = {
  getPaymentMethods: (state) => () => {
    return state.paymentMethods;
  },
}
