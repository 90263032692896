//
//
//
//

export default {
  name: "emptyView",
  head() {
    return this.makeHeader();
  },
};
