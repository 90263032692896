export const state = () => ({
  search: "",
});

export const mutations = {
  SET_SEARCH(state, data) {
    state.search = data;
  },
};

export const actions = {};

export const getters = {
  getSearch: (state) => () => {
    return state.search;
  },
};
