//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { advancedFiltersGetters } from "~/store/helpers";
export default {
  props: {
    showModal: Boolean,
  },
  data() {
    return {
      searchInput: undefined,
      loading: false,
      selectedId: null,
    };
  },
  computed: {
    ...advancedFiltersGetters,
    patientFilters() {
      return this.getFilters().filter((fil) => {
        if (!this.searchInput) {
          return fil;
        } else {
          return (
            fil.name.toLowerCase().includes(this.searchInput.toLowerCase()) ||
            fil.description
              .toLowerCase()
              .includes(this.searchInput.toLowerCase())
          );
        }
      });
    },
  },
  methods: {
    async duplicateFilter(filter) {
      this.loading = true;

      let payload = {
        name: filter.name + " ( Copy )",
        model: filter.model,
        description: filter.description,
        query: filter.query,
      };
      await this.$store.dispatch("filters/addFilter", {
        payload: payload,
      });

      this.loading = false;
    },
    deleteModalOpen(id) {
      this.selectedId = id;
      this.$bvModal.show("delete");
    },
    deleteFilter() {
      let organizationId = localStorage.getItem("organizationId");
      this.$store
        .dispatch("filters/deleteFilter", {
          organizationId,
          filterId: this.selectedId,
        })
        .then(() => {
          this.$store.dispatch("filters/fetchExistingFilters", {
            organizationId: localStorage.getItem("organizationId"),
          });
        });
    },
    closeModal() {
      this.$emit("closeFilterModal", false);
    },
    selectFilter(val) {
      this.$emit("selectPatientFilter", val);
      localStorage.setItem("filterActive", val.id);
      this.$nextTick(async () => {
        try {
          this.$store.commit("modules/users/SET_LOADING", true);

          let result = await this.$axios.$get(
            `${localStorage.getItem("organizationId")}/advanced-queries/${
              val.id
            }/execute`
          );
          console.warn(result.data);
          this.$store.dispatch("modules/users/filterResults", {
            data: result.data,
          });
          this.$store.commit("modules/users/SET_LOADING", false);
        } catch (error) {
          console.error(error);
        }
      });
    },
  },
};
