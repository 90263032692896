
import MetisMenu from "metismenujs/dist/metismenujs";
import { mapState } from "vuex";
import {
  layoutComputed,
  organizationsGetters,
  organizationMutations,
  configGetters,
} from "~/store/helpers";
/**
 * Sidebar component
 */
export default {
  data() {
    return {
      itemId: null,
      organizations: [],
      text: null,
      flag: null,
      value: null,
      logoRedirect: "/patients",
      adminMenuStyle: {
        padding: "20px 1.1rem",
        color: "#94A7BD",
        margin: "0px 10px",
        "font-weight": "400",
      },
      previousRoute: null,
      chatRoute: null,
    };
  },
  computed: {
    ...layoutComputed,
    ...configGetters,
    navItems() {
      return [
        {
          id: 13,
          label: "menuitems.dashboard.text",
          icon: "uil-left-arrow-to-left",
          name: "dashboard",
          link: "/",
          display: true,
        },
      ];
    },
  },

  mounted() {
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    this._activateMenuDropdown();
  },
  methods: {
    previousRouteComp() {
      return localStorage.getItem("previousRoute");
    },
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    /**
     * remove active and mm-active class
     */
    _removeAllClass(className) {
      const els = document.getElementsByClassName(className);
      while (els[0]) {
        if (els[0].children[0].children[0].className == "is-online") {
          els[0].children[0].children[0].style.display = "block";
        }
        els[0].classList.remove(className);
      }
    },
    _activateMenuDropdown() {
      this._removeAllClass("mm-active");
      this._removeAllClass("mm-show");
      this.itemId = this.$route.params.id;
      this.$nextTick(() => {
        var links = document.getElementsByClassName("side-nav-link-ref");
        var matchingMenuItem = null;
        const paths = [];

        for (var i = 0; i < links.length; i++) {
          paths.push(links[i]["pathname"]);
        }
        let pathName = window.location.pathname.includes("/reports")
          ? "/reports"
          : this.$route.name == "index-name"
          ? "/"
          : window.location.pathname;

        var itemIndex = paths.indexOf(pathName);

        if (itemIndex === -1) {
          const strIndex = window.location.pathname.lastIndexOf("/");
          const item = window.location.pathname.substr(0, strIndex).toString();
          matchingMenuItem = links[paths.indexOf(item)];
        } else {
          matchingMenuItem = links[itemIndex];
        }

        if (matchingMenuItem) {
          matchingMenuItem.classList.add("active");
          var parent = matchingMenuItem.parentElement;
          /**
           * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
           * We should come up with non hard coded approach
           */
          if (parent) {
            parent.classList.add("mm-active");
            if (parent.children[0].children[0].className == "is-online") {
              parent.children[0].children[0].style.display = "none";
            }
            const parent2 = parent.parentElement.closest("ul");
            if (parent2 && parent2.id !== "side-menu") {
              parent2.classList.add("mm-show");
              const parent3 = parent2.parentElement;

              if (parent3) {
                parent3.classList.add("mm-active");
                var childAnchor = parent3.querySelector(".has-arrow");
                var childDropdown = parent3.querySelector(".has-dropdown");
                if (childAnchor) childAnchor.classList.add("mm-active");
                if (childDropdown) childDropdown.classList.add("mm-active");

                const parent4 = parent3.parentElement;
                if (parent4 && parent4.id !== "side-menu") {
                  parent4.classList.add("mm-show");
                  const parent5 = parent4.parentElement;
                  if (parent5 && parent5.id !== "side-menu") {
                    parent5.classList.add("mm-active");
                    const childanchor = parent5.querySelector(".is-parent");
                    if (childanchor && parent5.id !== "side-menu") {
                      childanchor.classList.add("mm-active");
                    }
                  }
                }
              }
            }
          }
        }
      });
    },
  },
};
