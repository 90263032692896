var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"layout-wrapper"}},[_c('Topbar',{attrs:{"page":_vm.$route.fullPath.includes('/patients')
        ? 'patients'
        : _vm.$route.name == 'index-name'
        ? 'dashboard'
        : null}}),_vm._v(" "),(
      !_vm.$route.path.includes('care-plan') && !_vm.$route.path.includes('calendar')
    )?_c('Sidebar',{attrs:{"type":_vm.leftSidebarType,"width":_vm.layoutWidth}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"main-content",style:([_vm.$route.name == 'profile' ? { 'margin-left': '0' } : {}])},[_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"container-fluid"},[(
            !_vm.$route.path.includes('calendar') &&
            !_vm.$route.path.includes('care-plan')
          )?_c('transition',{attrs:{"name":"slide-bottom"}},[_c('Nuxt')],1):_c('Nuxt')],1)]),_vm._v(" "),_c('Footer',{style:([_vm.$route.name == 'profile' ? { left: '0' } : {}])})],1),_vm._v(" "),_c('RightSidebar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }