export const state = () => ({
  data: [],
  pagination: {},
});

export const mutations = {
  SET_DOCUMENTS(state, documents) {
    if (documents.page === 1) {
      state.data = documents.data;
    } else {
      state.data.push(...documents.data);
    }
    state.pagination = documents.pagination;
  },
  ADD_DOCUMENT(state, document) {
    state.data.unshift(document);
  },
  UPDATE_DOCUMENT(state, updatedDocument) {
    const index = state.data.findIndex(
      (document) => document.id === updatedDocument.id
    );
    if (index !== -1) {
      state.data.splice(index, 1, updatedDocument);
    }
  },
  DELETE_DOCUMENT(state, documentId) {
    state.data = state.data.filter((document) => document.id !== documentId);
  },
};

export const actions = {
  async fetchDocuments(
    { commit },
    {
      organization_id,
      page = 1,
      per_page = 20,
      patient_id = null,
      profile_type = null,
    }
  ) {
    const response = await this.$backend.fetchDocuments(organization_id, {
      page,
      per_page,
      patient_id,
      profile_type,
    });
    commit("SET_DOCUMENTS", {
      data: response.data,
      pagination: response.pagination,
      page,
    });
  },
  async createDocument({ commit, state }, { organization_id, documentData }) {
    const response = await this.$backend.createDocument(
      organization_id,
      documentData
    );
    commit("ADD_DOCUMENT", response.data);
  },
  async createFromSharedDocuments(
    { commit, state, actions },
    { organization_id, documentData }
  ) {
    await this.$backend.createFromSharedDocuments(
      organization_id,
      documentData
    );
  },
  async updateDocument(
    { commit },
    { organization_id, documentId, documentData }
  ) {
    const response = await this.$backend.updateDocument(
      organization_id,
      documentId,
      documentData
    );
    commit("UPDATE_DOCUMENT", response.data);
  },
  async deleteDocument({ commit }, { organization_id, documentId }) {
    await this.$backend.deleteDocument(organization_id, documentId);
    commit("DELETE_DOCUMENT", documentId);
  },
};
