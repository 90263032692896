import moment from "moment";
import Vue from "vue";

export const state = () => ({
  cgmDataLoaded: false,
  ccm_assessments: [],
  clinical_alert_counts: {
    low: 0,
    medium: 0,
    high: 0,
    urgent: 0,
  },
  trackers: {
    rpm: [],
    ccm: [],
    rtm: [],
  },
  clinical_report_data: {
    blood_pressure: [],
    glucose: [],
    oxygen_saturation: [],
    weight: [],
  },
  reportsLoaded: false,
  filters: {
    distributions: [],
    vitals: [],
  },
  alerts_count: {},
  weight: {
    all: {
      combined: [],
      evening: [],
      morning: [],
    },
    evening: {
      dates: [],
      values: [],
    },
    morning: {
      dates: [],
      values: [],
    },
    combined: {
      dates: [],
      values: [],
    },
  },
  heart_rate: {
    all: {
      combined: [],
      evening: [],
      morning: [],
    },
    evening: {
      dates: [],
      values: [],
    },
    morning: {
      dates: [],
      values: [],
    },
    combined: {
      dates: [],
      values: [],
    },
  },
  glucose: {
    all: {
      combined: [],
      evening: [],
      morning: [],
    },
    evening: {
      dates: [],
      values: [],
    },
    morning: {
      dates: [],
      values: [],
    },
    combined: {
      dates: [],
      values: [],
    },
  },
  oxygen_saturation: {
    all: {
      combined: [],
      evening: [],
      morning: [],
    },
    evening: {
      dates: [],
      values: [],
    },
    morning: {
      dates: [],
      values: [],
    },
    combined: {
      dates: [],
      values: [],
    },
  },
  device_usages: {},
  blood_pressure: {
    all: {
      combined: [],
      evening: [],
      morning: [],
    },
    evening: {
      dates: [],
      values: [],
    },
    morning: {
      dates: [],
      values: [],
    },
    combined: {
      dates: [],
      values: [],
    },
  },
  rtm_data: [],
  sorted_rtm_data: [],
  rtm_days: 0,
  rtm_minutes: "00:00",
  notes: [],
  rtm_csv_data: null,
  ccm_csv_data: '',
  rpm_csv_data: '',
  vitalRanges: [],
  ccmNotes: [],
  rtmNotes: [],
  rpmNotes: [],
  ccm_seconds: "00:00",
  rpm_seconds: "00:00",
  rpm_days: 0,
  allData: null, //temporary
  totalCCMMinutes: "00:00",
  totalRPMMinutes: "00:00",
  hasCGMData: false,
});

export const mutations = {
  SET_CCM_CSV(state) {
    state.ccm_csv_data = ""
    if (state.ccm_assessments.length) {
      for (let index = 0; index < state.ccm_assessments.length; index++) {
        const element = state.ccm_assessments[index];
        let subHeader = `\n${element.activity.name} ${moment(element.created_at).utc().format('L LT')}\nQuestion, Answer\n`
        state.ccm_csv_data = state.ccm_csv_data + subHeader

        let questions = element.response_data.questions.map(e => {
          return `"${e.question}", ${e.answer}`
        }).join('\n')

        state.ccm_csv_data = state.ccm_csv_data + questions
      }
    }
    if (state.ccmNotes.length) {
      let cvsNoteHeader = "\nNotes, \nType, Date Time, Note\n"
      state.ccm_csv_data = state.ccm_csv_data + cvsNoteHeader
      let cvsNoteContent = state.ccmNotes
        .map((note) => {
          return `${note.type.toUpperCase()},${moment(note.created_at)
            .utc()
            .local()
            .format("L LT")},"${note.description}"`;
        })
        .join("\n");
      state.ccm_csv_data = state.ccm_csv_data + cvsNoteContent
    }
    console.log('TRACKERS', state.trackers.ccm)
    if (state.trackers.ccm.length) {
      let csvTrackersHeader =
        "\nManual Trackers, \nStarted by, Date, Started at, Ended at, Total Time\n";
      let csvTrackersContent = state.trackers.ccm
        .map((tracker) => {
          return `${tracker.started_by.display_name},${moment(
            tracker.started_at
          ).format("MM/DD/YYYY")}, ${moment
            .utc(tracker.started_at)
            .format("hh:mm:ss A")}, ${moment
              .utc(tracker.stopped_at)
              .format("hh:mm:ss A")}, ${tracker.accumulated_time}`;
        })
        .join("\n");

      state.ccm_csv_data = state.ccm_csv_data + csvTrackersHeader + csvTrackersContent
    };
  },
  SET_RPM_CSV(state) {
    state.rpm_csv_data = ""
    const AllMeasurements = {
      "Weight": state.weight.all.combined,
      "Heart Rate": state.heart_rate.all.combined,
      "Blood Pressure": state.blood_pressure.all.combined,
      "Glucose": state.glucose.all.combined,
      'Oxygen Saturation': state.oxygen_saturation.all.combined
    }


    for (const key in AllMeasurements) {
      if (AllMeasurements[key].length) {
        let header = `\n${key}\n`
        state.rpm_csv_data = state.rpm_csv_data + header
        let currentMeasurement = AllMeasurements[key].flat(1)
        const sub_headers = "Date, Time, Value\n"
        state.rpm_csv_data = state.rpm_csv_data + sub_headers
        let measurementContent = currentMeasurement.map(e => {
          return `${moment(e.created_at).format('MM/DD/YYYY')}, ${moment(e.created_at).format('HH:mm A')}, ${e.value} ${e.unit}`
        }).join('\n')
        state.rpm_csv_data = state.rpm_csv_data + measurementContent
      }
    }
    if (state.rpmNotes.length) {
      let cvsNoteHeader = "\nNotes, \nType, Date Time, Note\n";

      let cvsNoteContent = state.rpmNotes
        .map((note) => {
          return `${note.type.toUpperCase()},${moment(note.created_at)
            .utc()
            .local()
            .format("L LT")},"${note.description}"`;
        })
        .join("\n"); state.rpm_csv_data = state.rpm_csv_data + cvsNoteHeader + cvsNoteContent
    }
  },
  SET_REPORT_TRACKERS(state, data) {
    state.trackers.rpm = data.filter((e) => {
      return e.type === "rpm" && e.type_object === "time_tracker";
    });
    if (state.trackers.rpm.length) {
      let csvTrackersHeader =
        "\nManual Trackers, \nStarted by, Date, Started at, Ended at, Total Time\n";
      let csvTrackersContent = state.trackers.rpm
        .map((tracker) => {
          return `${tracker.started_by.display_name},${moment(
            tracker.started_at
          ).format("MM/DD/YYYY")}, ${moment
            .utc(tracker.started_at)
            .format("hh:mm:ss A")}, ${moment
              .utc(tracker.stopped_at)
              .format("hh:mm:ss A")}, ${tracker.accumulated_time}`;
        })
        .join("\n");

      state.rpm_csv_data = state.rpm_csv_data + csvTrackersHeader + csvTrackersContent
    }
    state.trackers.ccm = data.filter((e) => {
      return e.type === "ccm" && e.type_object === "time_tracker";
    });
    state.trackers.rtm = data.filter((e) => {
      return e.type === "rtm" && e.type_object === "time_tracker";
    });
  },
  SET_CCM_ASSESSMENTS(state, data) {
    state.ccm_assessments = data;
  },
  SET_HAS_CGM_DATA(state, data) {
    state.hasCGMData = data;
  },
  SET_CCM_RPM_MINUTES(state, data) {
    let seconds = Number(data.data);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    state[data.key] = `${String(minutes).length === 1 ? "0" + minutes : minutes
      }:${String(remainingSeconds).length === 1
        ? "0" + remainingSeconds
        : remainingSeconds
      }`;
  },
  SET_RTM_DATA(state, data) {
    state.rtm_data = data.activity_responses;
    state.rtm_days = data.rtm_days;
    const minutes = Math.floor(data.rtm_seconds / 60);
    const remainingSeconds = data.rtm_seconds % 60;

    state.rtm_minutes = `${String(minutes).length === 1 ? "0" + minutes : minutes
      }:${String(remainingSeconds).length === 1
        ? "0" + remainingSeconds
        : remainingSeconds
      }`;
    let csvTaskHeader = "Task Name,Date Time,Value\n";
    let csvTaskContent =
      csvTaskHeader +
      state.rtm_data
        .map((task) => {
          return `${task.activity_type_slug === "pain_scale"
            ? "Pain Score"
            : task.response_data.subcategory
            },${moment(task.created_at).utc().local().format("L LT")}, ${task.response_data.unit === "seconds"
              ? task.billable_time + " mins"
              : task.response_data.value
            }`;
        })
        .join("\n");

    let cvsNoteHeader = "\nNotes, \nType, Date Time, Note\n";
    let cvsNoteContent = state.rtmNotes
      .map((note) => {
        return `${note.type.toUpperCase()},${moment(note.created_at)
          .utc()
          .local()
          .format("L LT")},"${note.description}"`;
      })
      .join("\n");
    let csvTrackersHeader =
      "\nManual Trackers, \nStarted by, Date, Started at, Ended at, Total Time\n";
    let csvTrackersContent = state.trackers.rtm
      .map((tracker) => {
        return `${tracker.started_by.display_name},${moment(
          tracker.started_at
        ).format("MM/DD/YYYY")}, ${moment
          .utc(tracker.started_at)
          .format("hh:mm:ss A")}, ${moment
            .utc(tracker.stopped_at)
            .format("hh:mm:ss A")}, ${tracker.accumulated_time}`;
      })
      .join("\n");
    let csvContent = "";
    if (state.rtm_data.length) {
      csvContent = csvContent + csvTaskContent;
    }

    if (state.rtmNotes.length) {
      csvContent = csvContent + cvsNoteHeader + cvsNoteContent;
    }

    if (state.trackers.rtm.length) {
      csvContent = csvContent + csvTrackersHeader + csvTrackersContent;
    }

    state.rtm_csv_data = csvContent;

    const sortedData = {};

    state.rtm_data.forEach((obj) => {
      const { name } = obj.activity;

      if (!sortedData[name]) {
        sortedData[name] = [obj];
      } else {
        sortedData[name].push(obj);
      }
    });

    const sortedRTMData = Object.values(sortedData);
    let slicedAndSortedRTMData = [];

    for (let index = 0; index < sortedRTMData.length; index++) {
      const element = sortedRTMData[index];
      let slicedRTMData = [];
      for (let i = 0; i < element.length; i += 18) {
        let subArray = element.slice(i, i + 18);
        slicedRTMData.push(subArray);
      }
      slicedAndSortedRTMData.push(slicedRTMData);
    }
    state.sorted_rtm_data = slicedAndSortedRTMData;
  },
  SET_CGM_LOADER(state, data) {
    state.cgmDataLoaded = data;
  },
  SET_CLINICAL_ALERT_COUNTS(state, data) {
    let mixedArray = [
      ...data.blood_pressure,
      ...data.glucose,
      ...data.weight,
      ...data.oxygen_saturation,
      ...data.heart_rate,
    ];
    state.clinical_alert_counts.high = mixedArray.filter((e) => {
      return e.alert.level === "HIGH";
    }).length;
    state.clinical_alert_counts.medium = mixedArray.filter(
      (e) => e.alert.level === "MEDIUM"
    ).length;
    state.clinical_alert_counts.low = mixedArray.filter(
      (e) => e.alert.level === "LOW"
    ).length;
    state.clinical_alert_counts.urgent = mixedArray.filter(
      (e) => e.alert.level === "URGENT"
    ).length;
  },
  SET_CLINICAL_DATA(state, data) {
    if (data.key === "blood_pressure") {
      let mappedDataArray = [];

      for (
        let index = 0;
        index < Object.values(data.blood_pressure).length;
        index++
      ) {
        const bloodPressureValues = Object.values(data.blood_pressure)[index];
        const heartRateValues = Object.values(data.heart_rate)[index];
        let values = {
          blood_pressure: null,
          heart_rate: null,
          created_at: null,
          bpAlert: null,
          hrAlert: null,
        };
        let ArrayToIterate =
          bloodPressureValues.length > heartRateValues.length
            ? bloodPressureValues
            : heartRateValues;
        for (let i = 0; i <= ArrayToIterate.length; i++) {
          let bpValue = bloodPressureValues[i];
          let hrValue = heartRateValues[i];
          let correspondingHRReading = heartRateValues.filter((e) => {
            return (
              moment(e?.created_at).format("DD/MM/YYYY HH:mm") ===
              moment(bpValue?.created_at).format("DD/MM/YYYY HH:mm")
            );
          });

          let correspondingBPReading = bloodPressureValues.filter((e) => {
            return (
              moment(e?.created_at).format("DD/MM/YYYY HH:mm") ===
              moment(hrValue?.created_at).format("DD/MM/YYYY HH:mm")
            );
          });
          if (hrValue && !correspondingBPReading.length) {
            values.heart_rate = hrValue.value;
            values.hrAlert = hrValue.alert;
            values.created_at = hrValue.created_at;
            mappedDataArray.push(values);
            values = {
              blood_pressure: null,
              heart_rate: null,
              created_at: null,
            };
          }
          if (bpValue && !correspondingHRReading.length) {
            values.blood_pressure = bpValue.value;
            values.bpAlert = bpValue.alert;
            values.created_at = bpValue.created_at;
            mappedDataArray.push(values);
            values = {
              blood_pressure: null,
              heart_rate: null,
              created_at: null,
            };
          }

          if (bpValue && correspondingHRReading.length) {
            values.blood_pressure = bpValue.value;
            values.heart_rate =
              correspondingHRReading[correspondingHRReading.length - 1].value;
            values.created_at = bpValue.created_at;
            values.bpAlert = bpValue.alert;
            values.hrAlert =
              correspondingHRReading[correspondingHRReading.length - 1].alert;
            mappedDataArray.push(values);
            values = {
              blood_pressure: null,
              heart_rate: null,
              created_at: null,
            };
          }
        }

        state.clinical_report_data.blood_pressure = mappedDataArray;
      }
    } else {
      state.clinical_report_data[data.key] = Object.values(data.data).flat(
        Infinity
      );
    }
  },
  SET_REPORTS_LOADER(state, data) {
    state.reportsLoaded = data;
  },
  SET_FILTERS(state, data) {
    state.filters[data.key] = data.data;
  },
  SET_VITAL_RANGES(state, data) {
    state.vitalRanges = data;
  },
  SET_ALL_DATA(state, data) {
    if (
      data.key === "alerts_count" ||
      data.key === "device_usages" ||
      data.key === "ccm_seconds" ||
      data.key === "rpm_seconds" ||
      data.key === "rpm_days"
    ) {
      state[data.key] = data.data;
      return;
    }
    if (data.key === "notes") {
      state[data.key] = data.data;
      let notes = data.data;
      state.ccmNotes = notes
        .filter((e) => e.type === "ccm")
        .map((note) => ({ ...note, shouldPageBreak: false }));
      state.rtmNotes = notes
        .filter((e) => e.type === "rtm")
        .map((note) => ({ ...note, shouldPageBreak: false }));
      state.rpmNotes = notes
        .filter((e) => e.type === "rpm")
        .map((note) => ({ ...note, shouldPageBreak: false }));
      return;
    }

    let currentMonthDates = Object.keys(data.data);

    state[data.key].morning.dates = currentMonthDates;
    state[data.key].evening.dates = currentMonthDates;
    state[data.key].combined.dates = currentMonthDates;

    let values = Object.values(data.data);
    let combinedValues = [];
    let morningValues = [];
    let eveningValues = [];
    state[data.key].all.combined = []
    state[data.key].all.evening = []
    state[data.key].all.morning = []
    for (let i = 0; i < values.length; i++) {
      const element = values[i].filter((e) => {
        return e && e.value !== "Task Done";
      });
      if (!element.length) {
        combinedValues.push(data.key === "blood_pressure" ? "0/0" : null);
        morningValues.push(data.key === "blood_pressure" ? "0/0" : null);
        eveningValues.push(data.key === "blood_pressure" ? "0/0" : null);
      } else {
        
        state[data.key].all.combined.push(element);
        combinedValues.push(element.at(-1).value);
        let morningDayValues = element.filter((e) => {
          return moment(e.created_at).isBetween(
            moment(e.created_at).set("hour", 5).set("minute", 0),
            moment(e.created_at).set("hour", 12).set("minute", 0)
          );
        });
        if (!morningDayValues.length) {
          morningValues.push(data.key === "blood_pressure" ? "0/0" : null);
        } else {
          state[data.key].all.morning.push(morningDayValues);
          morningValues.push(morningDayValues.at(-1)?.value);
        }
        let eveningDayValues = element.filter((e) => {
          return !moment(e.created_at).isBetween(
            moment(e.created_at).set("hour", 5).set("minute", 0),
            moment(e.created_at).set("hour", 12).set("minute", 0)
          );
        });
        if (!eveningDayValues.length) {
          eveningValues.push(data.key === "blood_pressure" ? "0/0" : null);
        } else {
          state[data.key].all.evening.push(eveningDayValues);
          eveningValues.push(eveningDayValues.at(-1)?.value);
        }
      }
    }
    state[data.key].combined.values = combinedValues;
    state[data.key].evening.values = eveningValues;
    state[data.key].morning.values = morningValues;
  },
  SET_SHOULD_NOTE_PAGE_BREAK(state, { index, value, type }) {
    if (index >= 0 && index < state[type].length) {
      Vue.set(state[type][index], "shouldPageBreak", value);
    }
  },
};

export const actions = {
  setAllData({ commit }, { data }) {
    commit("SET_ALL_DATA", { data: data.notes, key: "notes" });
    commit("SET_ALL_DATA", {
      data: data.blood_pressure,
      key: "blood_pressure",
    });
    commit("SET_ALL_DATA", {
      data: data.heart_rate,
      key: "heart_rate",
    });
    commit("SET_ALL_DATA", {
      data: data.glucose,
      key: "glucose",
    });
    commit("SET_ALL_DATA", {
      data: data.weight,
      key: "weight",
    });
    commit("SET_ALL_DATA", {
      data: data.oxygen_saturation,
      key: "oxygen_saturation",
    });
    commit("SET_ALL_DATA", {
      data: data.alerts_count,
      key: "alerts_count",
    });
    commit("SET_ALL_DATA", {
      data: data.device_usages,
      key: "device_usages",
    });
    commit("SET_CCM_RPM_MINUTES", {
      data: data.rpm_seconds,
      key: "rpm_seconds",
    });
    commit("SET_CCM_RPM_MINUTES", {
      data: data.ccm_seconds,
      key: "ccm_seconds",
    });
    commit("SET_ALL_DATA", {
      data: Number(data.rpm_days.split(" ")[0]),
      key: "rpm_days",
    });

    commit("SET_CLINICAL_DATA", {
      key: "blood_pressure",
      blood_pressure: data.blood_pressure,
      heart_rate: data.heart_rate,
    });

    commit("SET_CLINICAL_DATA", {
      key: "weight",
      data: data.weight,
    });

    commit("SET_CLINICAL_DATA", {
      key: "oxygen_saturation",
      data: data.oxygen_saturation,
    });

    commit("SET_CLINICAL_DATA", {
      key: "glucose",
      data: data.glucose,
    });

    commit("SET_CLINICAL_ALERT_COUNTS", {
      glucose: Object.values(data.glucose)
        .flat(Infinity)
        .filter((e) => {
          return e.alert;
        }),
      weight: Object.values(data.weight)
        .flat(Infinity)
        .filter((e) => {
          return e.alert;
        }),
      oxygen_saturation: Object.values(data.oxygen_saturation)
        .flat(Infinity)
        .filter((e) => {
          return e.alert;
        }),
      heart_rate: Object.values(data.heart_rate)
        .flat(Infinity)
        .filter((e) => {
          return e.alert;
        }),
      blood_pressure: Object.values(data.blood_pressure)
        .flat(Infinity)
        .filter((e) => {
          return e.alert;
        }),
    });

    commit("SET_RPM_CSV");

    commit("SET_REPORTS_LOADER", true);
  },
  setCcmAssessments({ commit }, data) {
    commit("SET_CCM_ASSESSMENTS", data);
    commit("SET_CCM_CSV");
  },
  setRTMData({ commit }, { data }) {
    commit("SET_RTM_DATA", data);
  },
};

export const getters = {
  getAllData: (state) => (key) => {
    return state[key];
  },
  getVitalRanges: (state) => () => {
    return state.vitalRanges;
  },
};
