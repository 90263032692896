export const state = () => ({
  existingPatient: [],
  coordinators: [],
  loading: false,
});

export const mutations = {
  SET_EXISTING_PATIENT(state, data) {
    state.existingPatient = data;
  },
  SET_COORDINATORS(state, data) {
    state.coordinators = data;
  },
  SET_LOADER(state, data) {
    state.loading = data;
  },
};

export const actions = {
  async getPatientDetails({ commit }, { id }) {
    commit("SET_LOADER", true);
    await this.$axios
      .get(`${localStorage.getItem("organizationId")}/patients/${id}`)
      .then((res) => {
        commit("SET_EXISTING_PATIENT", res.data.data);
        commit("SET_LOADER", false);
      });
  },
  async getCoordinators({ commit }) {
    await this.$axios
      .get(`${localStorage.getItem("organizationId")}/coordinators`)
      .then((res) => {
        commit("SET_COORDINATORS", res.data.data);
        commit("SET_LOADER", false);
      });
  },
};

export const getters = {
  getPatient: (state) => () => {
    return state.existingPatient;
  },

  getCoordinator: (state) => () => {
    return state.coordinators.find((e) => {
      return e.id === state.existingPatient.coordinator_id;
    });
  },
  getLoading: (state) => () => {
    return state.loading;
  },
};
