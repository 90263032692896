import Vue from 'vue';
import {ValidationProvider, extend} from 'vee-validate';
import VeeValidate from 'vee-validate';

// Add a rule.
import {required, number, upCase, min_value} from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required"
});

extend("username", {
  validate(value, args) {
    return args.val === 'false';
  },
  params: ['val'],
  message: "This username is already taken"
});

extend("number", {
  getMessage: () => "One number",
  validate: value => value.match(/[0-9]/g) !== null
})

extend("upCase", {
  getMessage: () => "One uppercase character",
  validate: value => value.match(/[A-Z]/g) !== null
});

extend("min_value", min_value);
