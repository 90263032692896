
import MetisMenu from "metismenujs/dist/metismenujs";
import { generateMenu } from "./menu";
import moment from "moment";
import {
  layoutComputed,
  chatsActions,
  chatsGetters,
  chatsMutations,
} from "~/store/helpers";
import { mapState } from "vuex";
import debounce from "lodash/debounce";

/**
 * Sidebar component
 */
export default {
  data() {
    return {
      itemId: null,
      loadingNewPatients: false,
      loading: false,
      loadingChats: true,
      perPage: 20,
      currentPage: 1,
      patientListCopy: [],
      text: null,
      flag: null,
      value: null,
      searchPatientInput: null,
      adminMenuStyle: {
        color: "#7B8190",
        margin: "0px 10px",
        "font-weight": "450",
      },
      previousRoute: null,
      chatRoute: null,
    };
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...layoutComputed,
    ...chatsGetters,
    ...mapState("chat", ["unreadCount"]),
    role() {
      return this.$auth.user ? this.$auth.user.all_roles : [];
    },
    logoRedirect() {
      return {
        name: this.$vianovaConfig.options.home_path ?? "patients",
      };
    },
    chatsLoading() {
      return this.getLoadingChats();
    },
    patientsList(state) {
      return this.getChatPatients();
    },
    allPatients(state) {
      return this.getSearchedPatients();
    },
    navItems() {
      return generateMenu(this.$route, this.$auth.user.all_roles, this).filter(
        (item) => {
          if (item.roles && item.roles.length > 0) {
            return item.roles.some((e) =>
              this.$auth.user.all_roles.includes(e)
            );
          } else {
            return true;
          }
        }
      );
    },
    userGroups() {
      const groups = this.$store.getters['modules/groups/getUserGroups']();
      return groups || [];
    },
    historicalGroups() {
      const groups = this.$store.getters['modules/groups/getHistoricalGroups']();
      return groups || [];
    },
    isUserOverviewPage() {
      return this.$route.name === 'civiclee-users-id-overview' || 
             this.$route.path.includes('/civiclee/users/') && this.$route.path.includes('/overview');
    }
  },
  created() {
    this.$store.commit(
      "modules/dashboard/SET_STEP",
      `${localStorage.getItem("previousStep")}`
    );
  },
  mounted() {
    this.$nextTick(() => {
      // Initialize metisMenu only if side-menu exists
      const sideMenu = document.getElementById('side-menu');
      if (sideMenu) {
        // eslint-disable-next-line no-unused-vars
        var menuRef = new MetisMenu('#side-menu');
      }
      this._activateMenuDropdown();
    });

    this.$router.afterEach((routeTo, routeFrom) => {
      // if (!routeTo || !routeFrom) return
      this.chatRoute
        ? localStorage.setItem("chatRoute", this.chatRoute)
        : (this.chatRoute = localStorage.getItem("chatRoute"));
      if (
        routeFrom.name != "users-id-overview" &&
        routeFrom.name != "users-id-calendar" &&
        routeFrom.name != "users-id-care-plan" &&
        !routeTo.name.includes("admin") &&
        !routeFrom.name.includes("admin")
      ) {
        this.$store.commit("modules/dashboard/SET_STEP", `${routeFrom.name}`);
        localStorage.setItem("previousStep", routeFrom.name);
      }
      if (routeTo.name == "admin-index-users" && routeFrom.name == "admin") {
        this.previousRoute = null;
        localStorage.setItem("previousRoute", null);
        this.previousStep = null;
        localStorage.setItem("previousStep", null);
      }
      if (routeTo.name === "chats" && !routeFrom.name.includes("chats")) {
        this.chatRoute =
          routeFrom.name == "index-name"
            ? routeFrom.name
            : routeFrom.name.includes("onboarding")
            ? "onboarding"
            : routeFrom.fullPath;
      }
      if (
        (routeFrom.name == "index-name" && routeTo.name.includes("users")) ||
        (routeFrom.name == "patients" && routeTo.name.includes("users"))
      ) {
        this.previousRoute =
          routeFrom.name == "users-id-overview" ? "index-name" : routeFrom.name;
        localStorage.setItem("previousRoute", this.previousRoute);
      } else if (!routeTo.name.includes("users")) {
        this.previousRoute = null;
        localStorage.setItem("previousRoute", null);
      }
      this._activateMenuDropdown();
    });
  },
  watch: {
    patientsList(val) {
      this.patientListCopy = val;
    },
  },
  methods: {
    ...chatsActions,
    ...chatsMutations,
    getFirstPatient(patient) {
      if (!patient) return;
      this.$router.push({
        name: `chats-index-id-profile`,
        params: {
          id: patient.conversation.participants.find(
            (e) => e.user.id != patient.messageable_id
          ).messageable_id,
          personOrganizationId: patient.conversation.participants.find(
            (e) => e.user.id != patient.messageable_id
          ).messageable?.organization_id,
        },
        query: {
          cid: patient.conversation_id,
          t: Date.now(),
          patient: patient.conversation.participants.find(
            (e) => e.user.id != patient.messageable_id
          ).user?.display_name,
        },
      });
    },
    addNewPatient(event) {
      let patientHasConversation = this.patientsList.find((patient) =>
        patient.conversation.participants.some(
          (participant) => participant.messageable_id == event.id
        )
      );

      if (!patientHasConversation) {
        if (event.id) {
          this.$axios
            .$post(
              `${localStorage.getItem("organizationId")}/chat/start/${event.id}`
            )
            .then((res) => {
              this.$axios
                .$get(
                  `${localStorage.getItem("organizationId")}/chat/${
                    res.data.id
                  }?page=${1}&per_page=${20}`
                )
                .then(async () => {
                  let result = await this.fetchChatPatients({
                    organizationId: localStorage.getItem("organizationId"),
                    currentPage: this.currentPage,
                    perPage: this.perPage,
                  });
                  if (result) {
                    this.getFirstPatient(
                      result.data.find(
                        (patient) =>
                          this.getPatientName(patient) == event.display_name
                      )
                    );
                  }
                });
            });
        }
      } else {
        let mappedList = JSON.parse(JSON.stringify(this.patientsList));
        mappedList.splice(
          0,
          0,
          mappedList.splice(
            mappedList.findIndex((e) => patientHasConversation.id == e.id),
            1
          )[0]
        );
        this.SET_PATIENTS(mappedList);
        this.getFirstPatient(mappedList[0]);
        this.searchPatientInput = null;
      }
      this.searchNewPatient = null;
      this.$bvModal.hide("addPatient");
    },
    debounceSearchPatients: debounce(function (value) {
      this.searchPatients(value);
    }, 50),
    async searchPatients(value) {
      await this.searchAllPatients(value, true);
    },
    searchAllPatients(value, inbox = true) {
      this.loading = true;
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(
        () =>
          this.searchedPatientsWithInbox({
            organizationId: localStorage.getItem("organizationId"),
            currentPage: 1,
            perPage: this.perPage,
            search: value,
            inbox: inbox,
          }).finally(() => (this.loading = false)),
        300
      );
    },
    getPatientName(patient) {
      return patient.conversation.participants.find((participant) =>
        participant.messageable_type.includes("Patient")
      )
        ? patient.conversation.participants.find((participant) =>
            participant.messageable_type.includes("Patient")
          ).user.display_name
        : "Patient";
    },
    momentTime(date) {
      let duration = moment.duration(moment().diff(date));
      let hours = duration.asHours();
      let minutes = duration.asMinutes();
      let seconds = duration.asSeconds();
      let days = duration.asDays();
      let months = duration.asMonths();
      let years = duration.asMonths();
      return seconds <= 60
        ? `${Math.round(seconds)} ${this.$t("chats.message_times.sec")}`
        : minutes <= 60
        ? `${Math.round(minutes)} ${this.$t("chats.message_times.min")}`
        : hours <= 24
        ? `${Math.round(hours)} ${this.$t("chats.message_times.hr")}`
        : days <= 31
        ? `${Math.round(days)} ${this.$t("chats.message_times.d")}`
        : months <= 12
        ? `${Math.round(months)} ${this.$t("chats.message_times.mon")}`
        : `${Math.round(years)} ${this.$t("chats.message_times.yr")}`;
    },
    previousRouteComp() {
      return localStorage.getItem("previousRoute");
    },

    /**
     * Toggle menu
     */
    toggleMenu() {
      this.$parent.$parent.toggleMenu();
    },
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    /**
     * remove active and mm-active class
     */
    _removeAllClass(className) {
      const els = document.getElementsByClassName(className);
      if (!els.length) return;

      while (els[0]) {
        if (
          els[0].children[0]?.children[0] &&
          els[0].children[0].children[0].className == 'is-online'
        ) {
          els[0].children[0].children[0].style.display = 'block';
        }
        els[0].classList.remove(className);
      }
    },
    _activateMenuDropdown() {
      this._removeAllClass('mm-active');
      this._removeAllClass('mm-show');
      this.itemId = this.$route.params.id;

      this.$nextTick(() => {
        const sideMenu = document.getElementById('side-menu');
        if (!sideMenu) return;

        var links = document.getElementsByClassName('side-nav-link-ref');
        if (!links.length) return;

        var matchingMenuItem = null;
        const paths = [];

        for (var i = 0; i < links.length; i++) {
          paths.push(links[i]['pathname']);
        }

        let pathName =
          this.$route.name === "reports"
            ? "/reports"
            : this.$route.name == "index-name"
            ? "/"
            : window.location.pathname.includes("/chats")
            ? "/chats"
            : window.location.pathname.includes("admin/tracks")
            ? "/admin/tracks"
            : window.location.pathname;

        var itemIndex = paths.indexOf(pathName);

        if (itemIndex === -1) {
          const strIndex = window.location.pathname.lastIndexOf("/");
          const item = window.location.pathname.substr(0, strIndex).toString();
          matchingMenuItem = links[paths.indexOf(item)];
        } else {
          matchingMenuItem = links[itemIndex];
        }

        if (matchingMenuItem) {
          matchingMenuItem.classList.add("active");
          var parent = matchingMenuItem.parentElement;
          /**
           * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
           * We should come up with non hard coded approach
           */
          if (parent) {
            parent.classList.add("mm-active");
            if (
              parent.children[0].children[0] &&
              parent.children[0].children[0].className == "is-online"
            ) {
              parent.children[0].children[0].style.display = "none";
            }
            const parent2 = parent.parentElement.closest("ul");
            if (parent2 && parent2.id !== "side-menu") {
              parent2.classList.add("mm-show");
              const parent3 = parent2.parentElement;

              if (parent3) {
                parent3.classList.add("mm-active");
                var childAnchor = parent3.querySelector(".has-arrow");
                var childDropdown = parent3.querySelector(".has-dropdown");
                if (childAnchor) childAnchor.classList.add("mm-active");
                if (childDropdown) childDropdown.classList.add("mm-active");

                const parent4 = parent3.parentElement;
                if (parent4 && parent4.id !== "side-menu") {
                  parent4.classList.add("mm-show");
                  const parent5 = parent4.parentElement;
                  if (parent5 && parent5.id !== "side-menu") {
                    parent5.classList.add("mm-active");
                    const childanchor = parent5.querySelector(".is-parent");
                    if (childanchor && parent5.id !== "side-menu") {
                      childanchor.classList.add("mm-active");
                    }
                  }
                }
              }
            }
          }
        }
      });
    },
    capitalizeFirstLetter(string) {
      if (!string) return;
      let splitWord = string.includes("index")
        ? string?.split("index-")
        : string?.split("id-");
      splitWord = splitWord[splitWord.length - 1].includes("-")
        ? "Lab Vitals"
        : splitWord[splitWord.length - 1];
      return splitWord.slice(0, 1).toUpperCase() + splitWord.slice(1);
    },
    selectCohort(cohort) {
      this.$store.dispatch('modules/groups/selectCohort', cohort);
      // Emit event to notify parent components
      this.$emit('cohort-selected', cohort);
    },
    isSelectedCohort(cohort) {
      const selectedCohort = this.$store.getters['modules/groups/getSelectedCohort']();
      return selectedCohort && selectedCohort.id === cohort.id;
    }
  },
};
