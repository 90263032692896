export const state = () => ({
  patient: null,
});

export const mutations = {
  setPatient(state, data) {
    state.patient = data;
  },
};

export const actions = {
  async sendMagicLink({}, { patient_id, phone_number }) {
    let organizationId = localStorage.getItem("organizationId");
    return (
      await this.$axios.$post(
        `${organizationId}/patients/${patient_id}/magic-link`,
        { phone_number }
      )
    )["data"];
  },
  async getPatient({ commit }, patient_id) {
    let organizationId = localStorage.getItem("organizationId");
    let data = await this.$axios.$get(
      `${organizationId}/patients/${patient_id}/`
    );
    commit("setPatient", data.data);
  },
};
