export const state = () => ({
  overviewNotes: [],
  communicationNotes: [],
});

export const mutations = {
  SET_OVERVIEW_NOTES(state, data) {
    state.overviewNotes = data;
  },
  SET_COMMUNICATION_NOTES(state, data) {
    state.communicationNotes = data;
  },
};

export const actions = {
  async fetchNotes({ state, commit }, { organizationId, patientId }) {
    let result = await this.$backend.fetchNotes(organizationId, patientId);
    commit("SET_COMMUNICATION_NOTES", result.data);
    return result.data;
  },
  async createNote({ state, commit }, { organizationId, patientId, payload }) {
    await this.$backend.createNote(organizationId, patientId, payload);
  },
  async deleteNote({ state, commit }, { organizationId, patientId, noteId }) {
    await this.$backend.deleteNote(organizationId, patientId, noteId);
  },
  async updateNote(
    { state, commit },
    { organizationId, patientId, noteId, payload }
  ) {
    await this.$backend.updateNote(organizationId, patientId, noteId, payload);
  },
};

export const getters = {
  getOverviewNotes: (state) => () => {
    return state.overviewNotes;
  },
  getCommunicationNotes: (state) => () => {
    return state.communicationNotes;
  },
};
