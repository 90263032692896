export const state = () => ({
  currentOnboarding: {},
})

export const mutations = {
  SET_CURRENT_ONBOARDING(state, data) {
    state.currentOnboarding = data ? { ...state.currentOnboarding, ...data } : {}
  },
};

export const actions = {
  async addPatient({ state, commit }, payload) {
    let result = await this.$backend.addPatient(payload)
    return result.data
  },
  async getSpecialists({state, commit}, {organizationId}){
    let result = await this.$backend.getSpecialists(organizationId)
    return result.data
  },
  async getOnboardingSpecialities({ state, commit }, { organizationId }) {
    let result = await this.$backend.getOnboardingSpecialities(organizationId);
    return result;
  },
  async getUsers({ state, commit }, { organizationId, role }) {
    let result =  await this.$backend.getCoordinators(organizationId, role);
    return result.data;
  }
}

export const getters = {
  getCurrentOnboard: (state) => () => {
    return state.currentOnboarding;
  }
}
