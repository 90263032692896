const middleware = {}

middleware['authentication'] = require('../middleware/authentication.js')
middleware['authentication'] = middleware['authentication'].default || middleware['authentication']

middleware['forbidden'] = require('../middleware/forbidden.js')
middleware['forbidden'] = middleware['forbidden'].default || middleware['forbidden']

middleware['halee-guest'] = require('../middleware/halee-guest.js')
middleware['halee-guest'] = middleware['halee-guest'].default || middleware['halee-guest']

middleware['isadmin'] = require('../middleware/isadmin.js')
middleware['isadmin'] = middleware['isadmin'].default || middleware['isadmin']

middleware['isSuperAdmin'] = require('../middleware/isSuperAdmin.js')
middleware['isSuperAdmin'] = middleware['isSuperAdmin'].default || middleware['isSuperAdmin']

middleware['magic-auth'] = require('../middleware/magic-auth.js')
middleware['magic-auth'] = middleware['magic-auth'].default || middleware['magic-auth']

export default middleware
