export const state = () => ({
  existingPatient: null,
});

export const mutations = {
  SET_PATIENT(state, data) {
    state.existingPatient = data;
  },
};

export const actions = {
  setExistingPatient({ commit }, { patient }) {
    commit("SET_PATIENT", patient);
  },
};

export const getters = {
  getPatient: (state) => () => {
    return state.existingPatient;
  },
};
