import { render, staticRenderFns } from "./layout.vue?vue&type=template&id=1fe1b6e3&scoped=true&"
import script from "./layout.vue?vue&type=script&lang=js&"
export * from "./layout.vue?vue&type=script&lang=js&"
import style0 from "./layout.vue?vue&type=style&index=0&id=1fe1b6e3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fe1b6e3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Topbar: require('/opt/atlassian/pipelines/agent/build/components/Topbar.vue').default,SideMenu: require('/opt/atlassian/pipelines/agent/build/components/SideMenu.vue').default,Footer: require('/opt/atlassian/pipelines/agent/build/components/Footer.vue').default,RightSidebar: require('/opt/atlassian/pipelines/agent/build/components/Right-sidebar.vue').default})
