// plugins/v-draggable.js
import Sortable from "sortablejs";
import Vue from "vue";

const draggabletable = {
  bind(el, binding, vnode) {
    const table = el;
    const tbody = table.querySelector("tbody");
    tbody._draggable = Sortable.create(tbody, {
      ...binding.value,
      onEnd: (evt) => {
        vnode.child.$emit("order-changed", evt);
      },
    });
  },
};

Vue.directive("draggabletable", draggabletable);
