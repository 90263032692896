export const state = () => ({
  filters: null,
  addedNote: null,
  loading: false,
});

export const mutations = {
  SET_FILTERS(state, data) {
    state.filters = data;
  },
  SET_LOADING(state, data) {
    state.loading = data;
  },
  SET_ADDED_NOTE(state, data) {
    state.addedNote = data
  },
};

export const actions = {
  setFilters({ commit }, { filters }) {
    commit("SET_FILTERS", filters);
  },
  setAddedNote({ commit }, { note }) {
    commit("SET_ADDED_NOTE", note);
  },
  setLoading({ commit }, { loading }) {
    commit("SET_LOADING", loading);
  },
};

export const getters = {
  getFilters: (state) => () => {
    return state.filters;
  },
  getAddedNote: (state) => () => {
    return state.addedNote;
  },
  getLoader: (state) => () => {
    return state.loading;
  },
};
