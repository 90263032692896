
import MetisMenu from "metismenujs/dist/metismenujs";
import { mapState } from "vuex";
import {
  layoutComputed,
  organizationsGetters,
  organizationMutations,
  configGetters,
} from "~/store/helpers";
/**
 * Sidebar component
 */
export default {
  data() {
    return {
      itemId: null,
      organizations: [],
      text: null,
      flag: null,
      value: null,
      logoRedirect: "/patients",
      adminMenuStyle: {
        padding: "20px 1.1rem",
        color: "#94A7BD",
        margin: "0px 10px",
        "font-weight": "400",
      },
      previousRoute: null,
      chatRoute: null,
    };
  },
  computed: {
    ...layoutComputed,
    ...configGetters,
  },

  mounted() {},
};
