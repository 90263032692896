export const state = () => ({
  glucose: [],
  blood_pressure: [],
  heart_rate: [],
  weight: [],
  vitals: [],
  units: {
    weight: {
      current: "kg",
      options: [
        { key: "kg", display_name: "kg", convertion_rate: 2.205 },
        { key: "pound", display_name: "pound", convertion_rate: 0.4535 },
      ],
    },
  },
});

export const mutations = {
  SET_ACTIVITY(state, data) {
    if (typeof data === "object") {
      state[data.vital] = data;
      return;
    }
    let type = data.find((e) => e.activity_type_slug);
    state[type.activity_type_slug] = data;
  },
  SET_VITALS(state, data) {
    state.vitals = data;
  },
  SET_WEIGHT_UNIT(state, unit) {
    state.units.weight.current = unit;
  },
};

export const actions = {
  async fetchActivity(
    { state, commit },
    { organizationId, patientId, vital, start_date, end_date }
  ) {
    let result = await this.$backend.getActivity(
      organizationId,
      patientId,
      vital,
      start_date,
      end_date
    );
    commit("SET_ACTIVITY", result.data);
    return result.data;
  },
  async saveActivity(
    { state, commit },
    { organizationId, patientId, payload }
  ) {
    let result = await this.$backend.saveActivity(
      organizationId,
      patientId,
      payload
    );
    return result.data;
  },
  async fetchVitals({ state, commit }, { organizationId, patientId }) {
    let result = await this.$backend.getVitals(organizationId, patientId);
    commit("SET_VITALS", result.data);
    return result.data;
  },
  //Lab Vitals
  async getLabVitalsTypes({ state, commit }, { organizationId, patientId }) {
    let response = await this.$backend.getLabVitalsTypes(
      organizationId,
      patientId
    );
    return response;
  },
  async getLabVitals(
    { state, commit },
    { organizationId, patientId, baseFilters }
  ) {
    let response = await this.$backend.getLabVitals(
      organizationId,
      patientId,
      baseFilters
    );
    return response;
  },
  async addLabVitals(
    { state, commit },
    { organizationId, patientId, payload }
  ) {
    let response = await this.$backend.addLabVitals(
      organizationId,
      patientId,
      payload
    );
    return response;
  },
  async editLabVital(
    { state, commit },
    { organizationId, patientId, vitalId, payload }
  ) {
    let response = await this.$backend.editLabVital(
      organizationId,
      patientId,
      vitalId,
      payload
    );
    return response;
  },
  async deleteLabVital(
    { state, commit },
    { organizationId, patientId, vitalId }
  ) {
    let response = await this.$backend.deleteLabVital(
      organizationId,
      patientId,
      vitalId
    );
    return response;
  },
  setWeightUnit({ state, commit }, { unit }) {
    commit("SET_WEIGHT_UNIT", unit);
  },
};

export const getters = {
  getActivity: (state) => (activity_type) => {
    return state[activity_type];
  },
  getVitals: (state) => () => {
    return state.vitals;
  },
  getWeightUnit: (state) => () => {
    return state.units.weight.current;
  },
};
