import Vue from "vue";

const NotificationHelpers = {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        showWaiting(message) {
          if (!message) {
            message = this.$t("general.processing");
          }
          this.$toast.show(message, { icon: "hourglass_empty" });
        },
        showSuccess(message) {
          this.flashSuccess(message);
        },
        flashSuccess(message) {
          if (!message) {
            message = this.$t("general.success");
          }
          this.$toast.global.success({ message });
        },
        flashWaiting(message) {
          this.showWaiting(message);
        },
        flashErrors(errors) {
          errors.forEach((element) => this.flashError(element));
        },
        showError(error) {
          try {
            if (this.$buildError(error).errors.length > 0) {
              this.flashErrors(this.$buildError(error).errors);
            } else {
              this.flashError(this.$buildError(error).message);
            }
          } catch (e) {
            this.flashError(error);
          }
        },
        flashError(error) {
          if (!error) {
            error = this.$t("general.error");
          }
          this.$toast.global.error({ error });
        },
      },
    });
  },
};

Vue.use(NotificationHelpers);
