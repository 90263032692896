
import Swal from "sweetalert2";
import debounce from "lodash/debounce";
import {
  advancedFiltersGetters,
  amdiabetesSearchGetters,
  layoutComputed,
  organizationsGetters,
  organizationMutations,
  configGetters,
} from "~/store/helpers";

import { mapState } from "vuex";
/**
 * Topbar component
 */
export default {
  props: {
    page: String,
  },
  data() {
    return {
      timer: null,
      showModal: false,
      languages: [
        {
          flag: require("~/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("~/assets/images/flags/spain.jpg"),
          language: "es",
          title: "Spanish",
        },
        {
          flag: require("~/assets/images/flags/turkey.jpg"),
          language: "tr",
          title: "Türkçe",
        },
      ],
      filterSearch: null,
      patientFilterSelected: null,
      current_language: this.$i18n.locale,
      language: null,
      modalIsActive: false,
      search: null,
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  beforeCreate() {
    if (localStorage.getItem("locale")) {
      this.$i18n.locale = localStorage.getItem("locale");
    } else {
      this.$i18n.locale = this.$vianovaConfig.options.locale;
      localStorage.setItem("locale", this.$i18n.locale);
    }
  },
  async mounted() {
    this.language = this.languages.find(
      (x) => x.language === this.$i18n.locale
    );
  },
  computed: {
    ...layoutComputed,
    ...configGetters,
    ...mapState("magic", ["patient"]),
    logoRedirect() {
      return `/magic-link`;
    },
    displayLanguages() {
      return this.languages.filter((e) => {
        return this.$vianovaConfig.options.locales.includes(e.language);
      });
    },
  },

  methods: {
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    /**
     * Toggle rightsidebar
     */
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    /**
     * Set languages
     */
    setLanguage(locale) {
      this.$i18n.locale = locale;
      this.current_language = locale;
      localStorage.setItem("locale", locale);
      window.location.reload();
    },
    async logoutUser() {
      await this.$auth.logout();
      this.showSuccess(this.$t("auth.goodbye"));
      localStorage.clear();
      await this.$router.push({
        path: "/auth/login",
      });
    },
    ...organizationMutations,
    setOrganization(id) {
      let organization = this.organizations.find((e) => e.id == id);
      this.SET_ORGANIZATION(organization);
      this.$route.fullPath.includes("admin")
        ? this.$router.push("/admin")
        : this.$router.push("/");
      setTimeout(() => {
        this.$store.commit("modules/cgm_sync/SET_SYNCING_STATUS", "");
      }, 1000);
    },
    showFilterModal() {
      this.showModal = true;
    },
    closeFilterModal(val) {
      this.showModal = val;
      this.filterSearch = null;
      this.$forceUpdate();
    },
    selectPatientFilter(val) {
      this.patientFilterSelected = val;
      this.showModal = false;
    },
    async getPatientFilter() {
      await this.$store.dispatch("filters/fetchExistingFilters", {
        organizationId: localStorage.getItem("organizationId"),
      });
    },
    removePatientFilter() {
      this.$store.commit("modules/users/SET_LOADING", true);
      this.patientFilterSelected = null;
      localStorage.removeItem("filterActive");
      this.$store
        .dispatch("modules/users/getUsers", {
          organizationId: localStorage.getItem("organizationId"),
          currentPage: 1,
          perPage: 15,
        })
        .then(() => {
          this.$store.commit("modules/users/SET_LOADING", false);
        });
    },
    debounceSearch: debounce(function () {
      this.searchInput();
    }, 300),
    async searchInput() {
      this.$store.commit("modules/search/SET_SEARCH", this.search);
      if (
        this.$route.name.includes("patients") ||
        this.$route.name.includes("call_list")
      ) {
        this.$router.replace({
          name: this.$route.name,
          query: { ...this.$route.query, search: this.search },
        });
      }
      let organizationId = localStorage.getItem("organizationId");
      let routeName = this.$route.name;
      if (
        routeName.includes("my_patients") ||
        routeName.includes("all_patients") ||
        routeName === "dashboard-call_list" ||
        routeName === "civiclee-members"
      ) {
        this.$store.commit(
          "modules/amdiabetes-search/SET_PATIENT_SEARCH",
          this.search
        );
        return;
      }
      if (routeName.includes("dashboard")) {
        if (routeName === "dashboard" && this.search) {
          this.$router.push({
            name: "dashboard-my_patients",
          });
        }
        this.$store.commit(
          "modules/amdiabetes-search/SET_PATIENT_SEARCH",
          this.search
        );

        this.$store.commit("modules/amdiabetes-search/SET_SEARCH", this.search);
        return;
      }
      if (routeName.includes("civiclee")) {
        this.$store.commit("modules/users/SET_SEARCH", this.search);
        return;
      }
      if (routeName.includes("admin")) {
        let page = routeName.includes("organisations_settings")
          ? "organization"
          : routeName.split("-").slice(-1).join("");
        this.$store.commit("modules/admin/SET_SEARCH", {
          page: page,
          search: this.search,
        });
      } else {
        if (routeName == "index-name" || routeName == "patients") {
          this.$store.commit("modules/users/SET_LOADING", true);
          this.$store.commit("modules/users/SET_SEARCH", this.search);

          let searchResult = await this.$axios.$get(
            `${organizationId}/patients?search=${this.search}`
          );
          this.$store.commit("modules/users/SET_USERS", searchResult);
          if (routeName == "index-name") {
            this.$router.push({
              name: "patients",
              params: { name: "dashboard" },
            });
          }
          this.$store.commit("modules/users/SET_LOADING", false);
        } else {
          if (routeName == "index") return;
          this.$store.commit(
            `modules/${routeName}/SET_CURRENT_${routeName.toUpperCase()}`,
            {
              search: this.search,
            }
          );
        }
        // this.$route.name == "chats"
        // this.$axios.$get(`${organizationId}/patients?inbox=true`)
      }
    },
    resetSearch() {
      this.search = null;
      this.$store.commit("modules/search/SET_SEARCH", null);
    },
  },
};
