export const state = () => ({
  conversations: [],
})

export const mutations = {
  SET_CONVERSATIONS(state, data) {
    state.conversations = data;
  },
};

export const actions = {
  async fetchConversations({ state, commit }, {organizationId, patientId}) {
    let result = await this.$backend.fetchConversations(organizationId, patientId)
    commit('SET_CONVERSATIONS', result.data)
    return result.data
  },
}

export const getters = {
  getConversations: (state) => () => {
    return state.conversations;
  },
}

// education, reminders, tasks, vitals,