
/**
 * Loader component
 */
export default {
  name: "Loading",
  props: {
    loading: Boolean,
    isFullscreen: Boolean,
  },
};
