var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"sidebar",staticClass:"vertical-menu"},[_c('div',{staticClass:"navbar-brand-box"},[_c('nuxt-link',{staticClass:"logo logo-dark text-left",attrs:{"to":_vm.logoRedirect}},[_c('span',{staticClass:"logo-sm"},[_c('img',{attrs:{"src":_vm.$vianovaConfig.options.logo_small,"alt":"","height":"22"}})]),_vm._v(" "),_c('span',{staticClass:"logo-lg"},[_c('img',{attrs:{"src":_vm.$vianovaConfig.options.logo,"alt":"","height":"30"}})])]),_vm._v(" "),_c('nuxt-link',{staticClass:"logo logo-light text-left",attrs:{"to":_vm.logoRedirect}},[_c('span',{staticClass:"logo-sm"},[_c('img',{attrs:{"src":_vm.$vianovaConfig.options.logo_small,"alt":"","height":"22"}})]),_vm._v(" "),_c('span',{staticClass:"logo-lg"},[_c('img',{attrs:{"src":_vm.$vianovaConfig.options.logo,"alt":"","height":"30"}})])])],1),_vm._v(" "),_c('button',{staticClass:"btn btn-sm px-3 font-size-16 header-item vertical-menu-btn",attrs:{"type":"button"},on:{"click":_vm.toggleMenu}},[_c('i',{staticClass:"fa fa-fw fa-bars"})]),_vm._v(" "),_c('div',{ref:"sidebar",staticClass:"sidebar-menu-scroll",attrs:{"data-simplebar":""}},[_c('div',{attrs:{"id":"sidebar-menu"}},[_c('ul',{staticClass:"metismenu list-unstyled",attrs:{"id":"side-menu"}},[_vm._l((_vm.navItems),function(item){return [(item.isTitle)?_c('li',{key:item.id,staticClass:"menu-title"},[_vm._v("\n            "+_vm._s(_vm.$t(item.label))+"\n          ")]):_vm._e(),_vm._v(" "),(!item.isTitle && !item.isLayout)?_c('li',{key:item.id,class:{ divider: item.isDivider }},[(_vm.hasItems(item))?_c('a',{staticClass:"is-parent",class:{
                'has-arrow': !item.badge,
                'has-dropdown': item.badge,
              },attrs:{"href":"javascript:void(0);"}},[(item.icon)?_c('i',{class:("" + (item.icon))}):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t(item.label)))]),_vm._v(" "),(item.badge && item.badge.id == 'chat-badge-counter')?_c('span',{class:("badge badge-pill badge-" + (item.badge.variant) + " float-right")},[_vm._v(_vm._s(_vm.unreadCount))]):(item.badge)?_c('span',{class:("badge badge-pill badge-" + (item.badge.variant) + " float-right"),attrs:{"id":item.badge.id}},[_vm._v(_vm._s(_vm.$t(item.badge.text)))]):_vm._e()]):_vm._e(),_vm._v(" "),(!_vm.hasItems(item) && item.display && !item.isDivider)?_c('nuxt-link',{key:item.link,staticClass:"side-nav-link-ref",style:([
                _vm.$route.fullPath.includes('admin') ? _vm.adminMenuStyle : {} ]),attrs:{"to":item.link}},[(item.icon)?_c('i',{class:("" + (item.icon))}):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t(item.label) == "Dashboard" &&
                ((_vm.previousRoute && _vm.previousRoute != "patients") ||
                  (!_vm.previousRoute && _vm.previousRouteComp() != "patients"))
                  ? "Dashboard"
                  : _vm.$t(item.label) == "Dashboard" &&
                    (_vm.previousRoute == "patients" ||
                      _vm.previousRouteComp() == "patients")
                  ? "Patients"
                  : _vm.$t(item.label)))]),_vm._v(" "),(item.badge && item.badge.id == 'chat-badge-counter')?_c('span',{class:("badge badge-pill badge-" + (item.badge.variant) + " float-right")},[_vm._v(_vm._s(_vm.unreadCount))]):(item.badge)?_c('span',{class:("badge badge-pill badge-" + (item.badge.variant) + " float-right"),attrs:{"id":item.badge.id}},[_vm._v(_vm._s(_vm.$t(item.badge.text)))]):_vm._e()]):_vm._e(),_vm._v(" "),(item.isDivider)?_c('span'):_vm._e(),_vm._v(" "),(_vm.hasItems(item))?_c('ul',{staticClass:"sub-menu",attrs:{"aria-expanded":"false"}},_vm._l((item.subItems),function(subitem,index){return _c('li',{key:index},[(!_vm.hasItems(subitem))?_c('nuxt-link',{staticClass:"side-nav-link-ref",attrs:{"to":subitem.link}},[_vm._v(_vm._s(_vm.$t(subitem.label)))]):_vm._e(),_vm._v(" "),(_vm.hasItems(subitem))?_c('a',{staticClass:"side-nav-link-a-ref has-arrow",attrs:{"href":"javascript:void(0);"}},[_vm._v(_vm._s(_vm.$t(subitem.label)))]):_vm._e(),_vm._v(" "),(_vm.hasItems(subitem))?_c('ul',{staticClass:"sub-menu mm-collapse",attrs:{"aria-expanded":"false"}},_vm._l((subitem.subItems),function(subSubitem,index){return _c('li',{key:index},[_c('nuxt-link',{staticClass:"side-nav-link-ref",attrs:{"to":subSubitem.link}},[_vm._v(_vm._s(_vm.$t(subSubitem.label)))])],1)}),0):_vm._e()],1)}),0):_vm._e()],1):_vm._e()]})],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }