export const environments = {
  local: {
    flavor: "local",
    baseURL: "https://vianova.test/api/connect",
    // pusherAppKey: "dc3e380d9e44e0972c9d",
    pusherAppKey: "709bddb76d019fc8e6f3",
    AWS_BUCKET_NAME: "https://vianova.s3.amazonaws.com",
    FILE_UPLOAD_URL: "https://development.vianovahealth.com/file-upload",
  },
  development: {
    flavor: "development",
    baseURL: "https://development.vianovahealth.com/api/connect",
    pusherAppKey: "709bddb76d019fc8e6f3",
    AWS_BUCKET_NAME: "https://vianova.s3.amazonaws.com",
    FILE_UPLOAD_URL: "https://development.vianovahealth.com/file-upload",
  },
  staging: {
    flavor: "staging",
    baseURL: "https://staging.vianovahealth.com/api/connect",
    pusherAppKey: "b600f929b7382a321358",
    AWS_BUCKET_NAME: "https://vianova.s3.amazonaws.com",
    FILE_UPLOAD_URL: "https://staging.vianovahealth.com/file-upload",
  },
  production: {
    flavor: "production",
    baseURL: "https://api.vianovahealth.com/api/connect",
    pusherAppKey: "a3aa92b4cb93c67c69f5",
    AWS_BUCKET_NAME: "https://vianova.s3.amazonaws.com",
    FILE_UPLOAD_URL: "https://api.vianovahealth.com/file-upload",
  },
};
