// import Vue from 'vue'

// import DatePick from 'v-calendar';
// //import 'vue-date-pick/dist/vueDatePick.css';

 
// Vue.component('DatePick', DatePick)


import Vue from 'vue';
import VCalendar from 'v-calendar';

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />            // ...other defaults
});
