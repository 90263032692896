export const state = () => ({
  medications: [],
});

export const mutations = {
  SET_MEDICATIONS(state, data) {
    state.medications = data;
  },
};

export const actions = {
  async fetchMedications(
    { state, commit },
    { organizationId, patientId, startDate, endDate }
  ) {
    let result = await this.$backend.fetchMedications(
      organizationId,
      patientId,
      startDate,
      endDate
    );
    commit("SET_MEDICATIONS", result.data);
    return result.data;
  },
  async fetchMedicationsDetails(
    { state, commit },
    { organizationId, patientId, medicationId, start_date, end_date }
  ) {
    let result = await this.$backend.fetchMedicationDetails(
      organizationId,
      patientId,
      medicationId,
      start_date,
      end_date
    );
    return result.data;
  },
  async addMedications(
    { state, commit },
    { organizationId, patientId, medicationId, payload }
  ) {
    let result = await this.$backend.addMedication(
      organizationId,
      patientId,
      medicationId,
      payload
    );
    return result.data;
  },
  async deletePatientMedication(
    { state, commit },
    { organizationId, patientId, medicationId }
  ) {
    let result = await this.$backend.deleteMedication(
      organizationId,
      patientId,
      medicationId
    );
    return result;
  },
};

export const getters = {
  getMedications: (state) => () => {
    return state.medications;
  },
};
