export const state = () => ({
  appointments: [],
  orgAppointments: [],
  currentAppointment: null,
});

export const mutations = {
  SET_APPOINTMENTS(state, data) {
    state.appointments = data;
  },
  SET_ORG_APPOINTMENTS(state, data) {
    state.orgAppointments = data;
  },
  SET_CURRENT_APPOINTMENTS(state, data) {
    state.currentAppointment = data;
  },
};

export const actions = {
  async fetchAppointments({ state, commit }, { organizationId, patientId }) {
    let result = await this.$backend.fetchAppointments(
      organizationId,
      patientId
    );
    commit("SET_APPOINTMENTS", result.data);
    return result.data;
  },
  async addAppointment(
    { state, commit },
    { organizationId, patientId, payload }
  ) {
    let result = await this.$backend.addAppointment(
      organizationId,
      patientId,
      payload
    );
    return result.data;
  },
  async updateAppointment(
    { state, commit },
    { organizationId, patientId, activityId, payload }
  ) {
    let result = await this.$backend.updateAppointment(
      organizationId,
      patientId,
      activityId,
      payload
    );
    return result.data;
  },
  async deleteAppointment(
    { state, commit },
    { organizationId, patientId, activityId }
  ) {
    let result = await this.$backend.deleteAppointment(
      organizationId,
      patientId,
      activityId
    );
    return result.data;
  },
  async fetchOrgAppointments({ state, commit }, { organizationId }) {
    let result = await this.$backend.fetchOrgAppointments(organizationId);
    commit("SET_ORG_APPOINTMENTS", result.data);
    return result.data;
  },
};
