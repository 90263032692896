export const state = () => ({
  source_patient: null,
  destination_patient: null,
});

export const mutations = {
  SET_SOURCE_PATIENT(state, data) {
    state.source_patient = data;
  },
  SET_DESTINATION_PATIENT(state, data) {
    state.destination_patient = data;
  },
};

export const getters = {
  getSourcePatient: (state) => () => {
    return state.source_patient;
  },
  getDestinationPatient: (state) => () => {
    return state.destination_patient;
  },
};
