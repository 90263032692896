//
//
//
//
//
//
//
//

export default {
  props: {
    imageUrl: String,
  },
  data() {
    return {
      modalTitle: 'Image Preview',
    };
  },
  methods: {
    onModalHidden() {
      this.$emit('hidden');
    },
    show() {
      this.$bvModal.show('image-preview-modal');
    },
  },
};
