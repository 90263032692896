export const state = () => ({
  data: [],
  pagination: {},
});

export const mutations = {
  SET_TOPICS(state, topics) {
    if (topics.page === 1) {
      state.data = topics.data;
    } else {
      state.data.push(...topics.data);
    }
    state.pagination = topics.pagination;
  },
  ADD_TOPIC(state, topic) {
    state.data.unshift(topic);
  },
  UPDATE_TOPIC(state, updatedTopic) {
    const index = state.data.findIndex((topic) => topic.id === updatedTopic.id);
    if (index !== -1) {
      state.data.splice(index, 1, updatedTopic);
    }
  },
  DELETE_TOPIC(state, topicId) {
    state.data = state.data.filter((topic) => topic.id !== topicId);
  },
  SET_TOPICS_ORDER(state, topics) {
    state.data = topics;
  },
};

export const actions = {
  async fetchTopics({ commit }, { organization_id, page = 1, per_page = 50 }) {
    const response = await this.$backend.fetchTopics(organization_id, {
      page,
      per_page,
    });
    commit("SET_TOPICS", {
      data: response.data,
      pagination: response.pagination,
      page,
    });
  },
  async createTopic({ commit, state }, { organization_id, topicData }) {
    const response = await this.$backend.createTopic(
      organization_id,
      topicData
    );
    commit("ADD_TOPIC", response.data);

    commit(
      "SET_TOPICS_ORDER",
      state.data.map((item, index) => ({ ...item, position: index + 1 }))
    );
  },
  async updateTopic({ commit }, { organization_id, topicId, topicData }) {
    const response = await this.$backend.updateTopic(
      organization_id,
      topicId,
      topicData
    );
    commit("UPDATE_TOPIC", response.data);
  },
  async deleteTopic({ commit }, { organization_id, topicId }) {
    await this.$backend.deleteTopic(organization_id, topicId);
    commit("DELETE_TOPIC", topicId);
  },
  async orderTopics({ commit }, { organization_id, topics, start_order }) {
    await this.$backend.orderTopics(
      organization_id,
      topics.map((item) => item.id),
      start_order
    );
    commit(
      "SET_TOPICS_ORDER",
      topics.map((item, index) => ({
        ...item,
        position: index + start_order,
      }))
    );
  },
};
