export const state = () => ({
  providers: [],
  coordinators: [],
});

export const mutations = {
  SET_PROVIDERS(state, data) {
    state.providers = data;
  },
  SET_COORDINATORS(state, data) {
    state.coordinators;
  },
};
export const actions = {
  async fetchProviders({ commit }, { organizationId }) {
    let result = await this.$backend.getOnboardingProviders(organizationId);
    commit("SET_PROVIDERS", result.data);
    return result;
  },
  async fetchCoordinators({ commit }, { organizationId }) {
    let result = await this.$backend.getCoordinators(organizationId);
    commit("SET_COORDINATORS", result.data);
    return result;
  },
};

export const getters = {
  getProviders: (state) => () => {
    return state.providers;
  },
  getCoordinators: (state) => () => {
    return state.coordinators;
  },
};
