export default function ({ route, redirect, $auth, store }) {
  if ($auth.user) {
    if (
      $auth.user.all_organizations &&
      $auth.user.all_organizations.length > 0
    ) {
      redirect("/");
    }
  }
}
