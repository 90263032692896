export const state = () => ({
  data: [],
  pagination: {},
});

export const mutations = {
  SET_SHARED_DOCUMENTS(state, sharedDocuments) {
    if (sharedDocuments.page === 1) {
      state.data = sharedDocuments.data;
    } else {
      state.data.push(...sharedDocuments.data);
    }
    state.pagination = sharedDocuments.pagination;
  },
  ADD_SHARED_DOCUMENT(state, sharedDocument) {
    state.data.unshift(sharedDocument);
  },
  UPDATE_SHARED_DOCUMENT(state, updatedSharedDocument) {
    const index = state.data.findIndex(
      (doc) => doc.id === updatedSharedDocument.id
    );
    if (index !== -1) {
      state.data.splice(index, 1, updatedSharedDocument);
    }
  },
  DELETE_SHARED_DOCUMENT(state, sharedDocumentId) {
    state.data = state.data.filter((doc) => doc.id !== sharedDocumentId);
  },
};

export const actions = {
  async fetchSharedDocuments(
    { commit },
    {
      organization_id,
      profile_type = null,
      page = 1,
      per_page = 10,
      search = null,
    }
  ) {
    const response = await this.$backend.fetchSharedDocuments(organization_id, {
      profile_type,
      page,
      per_page,
      search,
    });
    commit("SET_SHARED_DOCUMENTS", {
      data: response.data,
      pagination: response.pagination,
      page,
    });
  },
  async createSharedDocument(
    { commit },
    { organization_id, sharedDocumentData }
  ) {
    const response = await this.$backend.createSharedDocument(
      organization_id,
      sharedDocumentData
    );
    commit("ADD_SHARED_DOCUMENT", response.data);
  },
  async updateSharedDocument(
    { commit },
    { organization_id, sharedDocumentId, sharedDocumentData }
  ) {
    const response = await this.$backend.updateSharedDocument(
      organization_id,
      sharedDocumentId,
      sharedDocumentData
    );
    commit("UPDATE_SHARED_DOCUMENT", response.data);
  },
  async deleteSharedDocument(
    { commit },
    { organization_id, sharedDocumentId }
  ) {
    await this.$backend.deleteSharedDocument(organization_id, sharedDocumentId);
    commit("DELETE_SHARED_DOCUMENT", sharedDocumentId);
  },
};
