import Vue from "vue";

const AuthHelpers = {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        isSystemAdmin() {
          return this.$auth.user.all_roles.some((e) => e == "SystemAdmin");
        },
      },
    });
  },
};

Vue.use(AuthHelpers);
