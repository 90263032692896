import moment from "moment";

export const state = () => ({
  all_appointments: [],
  appointment_event_list: [],
  selected_event: null,
  selectedAppointment: {
    appointment: null,
    type: null,
    confirmed_date: null,
  },
});

export const mutations = {
  PUSH_NEW_ACTIVITY(state, data) {
    state.selectedAppointment.appointment.activity_responses.push(data);
  },
  SET_SELECTED_APPOINTMENT(state, data) {
    state.selectedAppointment = data;
  },
  SET_APPOINTMENTS(state, data) {
    state.appointment_event_list = [];
    state.all_appointments = data.appointments;

    for (let index = 0; index < state.all_appointments.length; index++) {
      const element = state.all_appointments[index];
      const frequency = element.reminder.frequency;

      if (frequency === "once") {
        let end = `${element.reminder.created_at.split(" ")[0]} ${
          element.reminder.end_time ||
          moment(element.reminder.time, "HH:mm").add(1, "hours").format("HH:mm")
        }`;
        state.appointment_event_list.push({
          title: element.name,
          end: moment(end).toDate(),
          start: moment.utc(element.reminder.created_at).toDate(),
          data: { ...element },
          mappedDate: moment.utc(element.reminder.created_at).toDate(),
        });
      }

      if (frequency === "daily") {
        let calEndDate = moment(data.dates.endStr);
        let calStartDate = moment(data.dates.startStr);

        while (moment(calStartDate).isSameOrBefore(moment(calEndDate))) {
          let mappedDate = `${moment(calStartDate).format().split("T")[0]} ${
            element.reminder.time
          }`;
          let mappedEndDate = `${moment(calStartDate).format().split("T")[0]} ${
            element.reminder.end_time ||
            moment(element.reminder.time, "HH:mm")
              .add(1, "hours")
              .format("HH:mm")
          }`;
          let newEvent = {
            title: element.name,
            end: moment(mappedEndDate).toDate(),
            start: moment.utc(mappedDate).toDate(),
            data: { ...element },
            mappedDate: moment.utc(mappedDate).toDate(),
          };
          if (
            moment(element.reminder.created_at, "YYYY-MM-DD").isSameOrBefore(
              moment(mappedDate, "YYYY-MM-DD")
            )
          ) {
            state.appointment_event_list.push(newEvent);
          }
          calStartDate = moment(calStartDate).add(1, "days");
        }
      }

      if (frequency === "monthly") {
        let calEndDate = moment(data.dates.endStr);
        let calStartDate = moment(data.dates.startStr);
        let monthDay = element.reminder.day_of_month;
        while (moment(calStartDate).isSameOrBefore(moment(calEndDate))) {
          if (Number(moment(calStartDate).format("DD")) === monthDay) {
            let mappedDate = `${moment(calStartDate).format().split("T")[0]} ${
              element.reminder.time
            }`;
            let mappedEndDate = `${
              moment(calStartDate).format().split("T")[0]
            } ${
              element.reminder.end_time ||
              moment(element.reminder.time, "HH:mm")
                .add(1, "hours")
                .format("HH:mm")
            }`;
            let newEvent = {
              title: element.name,
              end: moment(mappedEndDate).toDate(),
              start: moment.utc(mappedDate).toDate(),
              data: { ...element },
              mappedDate: moment.utc(mappedDate).toDate(),
            };

            if (
              !moment(mappedDate, "YYYY-MM-DD").isBefore(
                moment(element.reminder.created_at, "YYYY-MM-DD")
              )
            ) {
              state.appointment_event_list.push(newEvent);
            }
          }
          calStartDate = moment(calStartDate).add(1, "days");
        }
      }

      if (frequency === "weekly") {
        let calEndDate = moment(data.dates.endStr);
        let calStartDate = moment(data.dates.startStr);
        let weekDays = Object.keys(element.reminder.weekly_data);
        while (moment(calStartDate).isSameOrBefore(moment(calEndDate))) {
          if (
            weekDays.includes(moment(calStartDate).format("dddd").toLowerCase())
          ) {
            let mappedDate = `${moment(calStartDate).format().split("T")[0]} ${
              element.reminder.time
            }`;
            let mappedEndDate = `${
              moment(calStartDate).format().split("T")[0]
            } ${
              element.reminder.end_time ||
              moment(element.reminder.time, "HH:mm")
                .add(1, "hours")
                .format("HH:mm")
            }`;
            let newEvent = {
              title: element.name,
              end: moment(mappedEndDate).toDate(),
              start: moment.utc(mappedDate).toDate(),
              data: { ...element },
              mappedDate: moment.utc(mappedDate).toDate(),
            };
            if (
              !moment(mappedDate, "YYYY-MM-DD").isBefore(
                moment(element.reminder.created_at, "YYYY-MM-DD")
              )
            ) {
              state.appointment_event_list.push(newEvent);
            }
          }
          calStartDate = moment(calStartDate).add(1, "days");
        }
      }
      if (frequency === "custom") {
        let startDate = moment(element.created_at);
        let endDate = moment(data.dates.endStr);
        let interval = element.reminder.custom_data.interval;
        while (moment(startDate).isSameOrBefore(moment(endDate))) {
          let mappedDate = `${moment(startDate).format().split("T")[0]} ${
            element.reminder.time
          }`;
          let mappedEndDate = `${moment(startDate).format().split("T")[0]} ${
            element.reminder.end_time ||
            moment(element.reminder.time, "HH:mm")
              .add(1, "hours")
              .format("HH:mm")
          }`;

          let newEvent = {
            title: element.name,
            end: moment(mappedEndDate).toDate(),
            start: moment.utc(mappedDate).toDate(),
            data: { ...element },
            mappedDate: moment.utc(mappedDate).toDate(),
          };
          if (
            !moment(mappedDate, "YYYY-MM-DD").isBefore(
              moment(element.created_at, "YYYY-MM-DD")
            )
          ) {
            state.appointment_event_list.push(newEvent);
          }
          startDate = moment(startDate).add(interval, "days");
        }
      }

      if (frequency === "until_done") {
        let calEndDate = moment(data.dates.endStr);
        let calStartDate = moment(data.dates.startStr);
        if (element.activity_responses.length) {
          let hasHeldAppointment = element.activity_responses.find((e) => {
            return e.value === "held";
          });

          if (hasHeldAppointment) {
            calEndDate = moment(hasHeldAppointment.created_at);
          }
        }
        while (moment(calStartDate).isSameOrBefore(moment(calEndDate))) {
          let mappedDate = `${moment(calStartDate).format().split("T")[0]} ${
            element.reminder.time
          }`;
          let mappedEndDate = `${moment(calStartDate).format().split("T")[0]} ${
            element.reminder.end_time ||
            moment(element.reminder.time, "HH:mm")
              .add(1, "hours")
              .format("HH:mm")
          }`;
          let newEvent = {
            title: element.name,
            end: moment(mappedEndDate).toDate(),
            start: moment.utc(mappedDate).toDate(),
            data: { ...element },
            mappedDate: moment.utc(mappedDate).toDate(),
          };
          if (
            moment(element.reminder.created_at, "YYYY-MM-DD").isSameOrBefore(
              moment(mappedDate, "YYYY-MM-DD")
            )
          ) {
            state.appointment_event_list.push(newEvent);
          }
          calStartDate = moment(calStartDate).add(1, "days");
        }
      }
    }
  },
  SET_SELECTED_EVENT(state, data) {
    state.selected_event = data;
  },
};

export const actions = {};

export const getters = {
  getAppointmentType: (state) => (key) => {
    return state[key];
  },
};
