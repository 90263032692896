export default ({ app }) => {
  // Override the default router push method
  app.router.beforeEach((to, from, next) => {
    // Check if the route already has a trailing slash or if it's a file (like .html)
    if (to.path !== "/" && !to.path.endsWith("/") && !to.path.includes(".")) {
      next({ path: `${to.path}/`, query: to.query });
    } else {
      next();
    }
  });
};
