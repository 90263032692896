
import { layoutComputed } from "~/store/helpers";

import Layout from "./layout";

export default {
  components: {
    Layout,
  },
  computed: {
    ...layoutComputed,
  },
  mounted() {
    document.body.classList.remove("authentication-bg");
  },
};
