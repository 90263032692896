export const state = () => ({
  organizations: [],
  organization: null,
  users: {},
  user_fields: [
    {
      key: "check",
      label: "",
    },
    {
      key: "full_name",
    },
    {
      key: "email",
    },
    "action",
  ],
  user: null,
});

export const mutations = {
  SET_USERS(state, data) {
    state.users = data;
  },
  SET_USER(state, data) {
    state.user = data;
  },
  SET_ORGANIZATIONS(state, data) {
    state.organizations = data;
  },
  SET_ORGANIZATION(state, data) {
    localStorage.setItem("organizationId", data.id);
    localStorage.setItem("organization", JSON.stringify(data));
    state.organization = data;
  },
};
export const actions = {
  async createOrganization(
    {},
    { name, contact_name, contact_phone_number, call_center_fax_number }
  ) {
    await this.$backend.createOrganization({
      name: name,
      contact_name: contact_name,
      contact_phone_number: contact_phone_number,
      call_center_fax_number,
    });
  },
  async getOrganizationDetails({ commit }, { organizationId }) {
    let result = await this.$backend.getOrganizationDetails(organizationId);
    commit("SET_ORGANIZATION", result.data ? result.data : result);
  },
  async getOrganizations({ commit }) {
    let result = await this.$backend.getOrganizations();
    commit("SET_ORGANIZATIONS", result.data);
    return result.data;
  },
  async getUsers({ commit }, { perPage, currentPage, organizationId }) {
    let result = await this.$backend.getOrganizationUsers(
      perPage,
      currentPage,
      organizationId
    );
    commit("SET_USERS", result);
  },
  async getUserDetails({ commit }, { userId, organizationId }) {
    let result = await this.$backend.getOrganizationUserDetails(
      organizationId,
      userId
    );
    commit("SET_USER", result.data);
  },
};
export const getters = {
  getOrganizations: (state) => () => {
    return state.organizations;
  },
  getOrganization: (state) => () => {
    return state.organization;
  },
};
