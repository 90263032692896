
import { layoutComputed, patientNotesGetters } from "~/store/helpers";
import { mapGetters } from "vuex";
import noteDrawerModal from "../components/notes/modals/noteDrawerModal.vue";
import moment from "moment";

export default {
  // head() {
  //   return {
  //     meta: [
  //       { charset: "utf-8" },
  //       { name: "viewport", content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" },
  //     ],
  //   };
  // },
  head() {
    return this.makeHeader();
  },
  data() {
    return {
      timer: null,
    };
  },
  components: { noteDrawerModal },
  computed: {
    ...layoutComputed,
    ...patientNotesGetters,
    activeNote() {
      return this.getNoteType("active_note");
    },
    ...mapGetters("patient", {
      patientInfo: "patient",
    }),
  },
  watch: {
    patientInfo(val, oldVal) {
      if (val) {
        clearInterval(this.timer);
        this.checkDatetime();
        this.timer = setInterval(this.checkDatetime, 60000);
      } else {
        clearInterval(this.timer);
      }
    },
    $route(to, from) {
      if (!to.name.includes("users")) {
        this.$store.commit("patient/setPatient", null);
      }
      if (this.activeNote && this.activeNote.state !== "minimized") {
        this.$nextTick(() => {
          this.minimizeModal({
            form: this.activeNote.form,
            questions: this.activeNote.questions,
          });
        });
      }
    },
  },
  mounted() {
    document.body.classList.remove("authentication-bg");
  },
  created() {
    document.body.removeAttribute("data-layout");
    document.body.removeAttribute("data-topbar");
  },
  methods: {
    checkDatetime() {
      if (!this.patientInfo) return;
      if (!this.patientInfo.dnd_end_date) return;
      const currentTime = moment();
      const patientDNDEndTime = moment(this.patientInfo.dnd_end_date);
      if (currentTime.isAfter(patientDNDEndTime)) {
        this.turnOffDnd();
      }
    },
    async turnOffDnd() {
      let payload = {
        dnd_start_date: null,
        dnd_end_date: null,
      };

      await this.$axios.patch(
        `${localStorage.getItem("organizationId")}/patients/${
          this.patientInfo.id
        }/dnd`,
        payload
      );
      this.fetchPatient();
    },
    async fetchPatient() {
      const id = this.$route.params.id;
      await this.$store.dispatch("patient/getPatientDetails", {
        organization_id: localStorage.getItem("organizationId"),
        id,
      });
    },
    expandModal(event) {
      this.$store.commit("modules/patient-notes/SET_ACTIVE_NOTE", {
        ...this.activeNote,
        form: event.form,
        questions: event.questions,
        message: event.message,
        state: "expanded",
      });
    },
    unexpandModal(event) {
      this.$store.commit("modules/patient-notes/SET_ACTIVE_NOTE", {
        ...this.activeNote,
        form: event.form,
        questions: event.questions,
        message: event.message,
        state: "open",
      });
    },
    reopenMinimizedModal(event) {
      this.$store.commit("modules/patient-notes/SET_ACTIVE_NOTE", {
        ...this.activeNote,
        form: this.activeNote.form,
        questions: this.activeNote.questions,
        message: this.activeNote.message,
        state: "open",
      });
    },
    minimizeModal(event) {
      this.$store.commit("modules/patient-notes/SET_ACTIVE_NOTE", {
        ...this.activeNote,
        form: event.form,
        message: event.message,
        questions: event.questions,
        state: "minimized",
      });
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        this.$router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        this.$router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
  },
};
