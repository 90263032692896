//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Recorder from "~/utils/audioRecorder.js";

export default {
  data() {
    return {
      duration: 0,

      input: null,
      bufferSize: 4096,
      context: null,
      processor: null,
      stream: null,
      duration: 0,
      volume: 0,
      lameEncoder: null,
      recordingStartedAt: null,
      elapsedTime: null,
      timer: null,
      recorder: this._initRecorder(),
      chunks: [],
      device: null,
      blobObj: null,
      isRecording: false,
      stream: null,
    };
  },
  mounted() {
    // console.log("converting time", convertTimeMMSS(12.2));
  },
  methods: {
    _initRecorder() {
      return new Recorder({
        afterRecording: this.afterRecording,
        micFailed: this.micFailed,
      });
    },
    updateElapsedTime() {
      const now = new Date();
      const difference = now - this.recordingStartedAt;
      const minutes = Math.floor(difference / 60000);
      const seconds = Math.floor((difference % 60000) / 1000);
      const formattedMinutes = minutes.toString().padStart(2, "0");
      const formattedSeconds = seconds.toString().padStart(2, "0");
      this.elapsedTime = `${formattedMinutes}:${formattedSeconds}`;
    },
    micFailed(event) {
      console.log(`micFailed event is`, event);
    },
    afterRecording(event) {
      console.log(`afterRecording event is`, event);
      this.$emit("onAudio", event);
    },
    async recordAudio() {
      this.isRecording = true;
      this.recorder.start();
      this.recordingStartedAt = new Date();
      setInterval(this.updateElapsedTime, 100);
    },
    stop() {
      if (this.isRecording === false) return;
      this.recorder.stop();
      clearInterval(this.timer);
      this.isRecording = false;
    },
  },
  beforeDestroy() {
    this.stop();
  },
};
