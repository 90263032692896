export const state = () => ({
  assessment: null,
  steps: ["patient_information"],
});

export const mutations = {
  SET_ASSESSMENT(state, data) {
    state.assessment = data;
  },
  SET_STEPS(state, data) {
    state.steps = data;
  },
};

export const actions = {
  setAssessment({ commit }, { assessment }) {
    commit("SET_ASSESSMENT", assessment);
    if (!assessment) {
      commit("SET_STEPS", ["patient_information"]);
    }
    if (assessment && assessment.data.allergies) {
      commit("SET_STEPS", ["patient_information", "allergies"]);
    }
    if (assessment && assessment.data.medications) {
      commit("SET_STEPS", ["patient_information", "allergies", "medications"]);
    }
    if (assessment && assessment.data.vaccinations) {
      commit("SET_STEPS", [
        "patient_information",
        "allergies",
        "medications",
        "vaccinations",
      ]);
    }
    if (assessment && assessment.data.care_plans) {
      commit("SET_STEPS", [
        "patient_information",
        "allergies",
        "medications",
        "vaccinations",
        "care_plans",
      ]);
    }
    if (assessment && assessment.finalized_at) {
      commit("SET_STEPS", [
        "patient_information",
        "allergies",
        "medications",
        "vaccinations",
        "care_plans",
        "finalize_cmr",
      ]);
    }
  },
};

export const getters = {
  getAssessment: (state) => () => {
    return state.assessment;
  },
  getAvailableSteps: (state) => () => {
    return state.steps;
  },
};
