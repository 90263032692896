export const state = () => ({
  recentlyAddedNote: false,
  all_notes: [],
  encounter_notes: [],
  alert_notes: [],
  internal_notes: [],
  active_note: null,
  active_filters: {
    program: null,
    date_time: null,
    care_coordinator: null,
    level: null,
  },
  note_history: [],
  note_to_be_recovered: {},
  note_to_delete: null,
});

export const mutations = {
  SET_RECENTLY_ADDED_NOTE(state, data) {
    state.recentlyAddedNote = data
  },
  SET_DELETE_NOTE(state, data) {
    state.note_to_delete = data;
  },
  SET_RECOVER_NOTE(state, data) {
    state.note_to_be_recovered = data;
  },
  SET_NOTE_HISTORY(state, data) {
    state.note_history = [{ ...data }, ...data.history];
  },
  SET_ACTIVE_NOTE(state, data) {
    state.active_note = data;
  },
  ADD_NOTE_TO_LIST(state, data) {
    state.all_notes = [...state.all_notes, data];
    state.encounter_notes = state.all_notes.filter((e) => {
      return e.type !== "internal" && !e.alert_id;
    });
    state.alert_notes = state.all_notes.filter((e) => {
      return e.alert_id;
    });
    state.internal_notes = state.all_notes.filter((e) => {
      return e.type === "internal";
    });
  },
  SET_ACTIVE_FILTERS(state, data) {
    state.active_filters = data;
  },
  SET_NOTES(state, data) {
    state.all_notes = data;
    state.encounter_notes = data.filter((e) => {
      return e.type !== "internal" && !e.alert_id;
    });
    state.alert_notes = data.filter((e) => {
      return e.alert_id;
    });
    state.internal_notes = data.filter((e) => {
      return e.type === "internal";
    });
  },
};

export const getters = {
  getNoteType: (state) => (key) => {
    return state[key];
  },
};
