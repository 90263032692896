export const state = () => ({
  patientAlerts:[],
  organizationAlerts: [],
  currentAlert: null,
})

export const mutations = {
  SET_ALERTS(state, data) {
    state.patientAlerts = data;
  },
  SET_ORG_ALERTS(state, data) {
    state.organizationAlerts = data;
  },
  SET_CURRENT_ALERTS(state, data) {
    state.currentAlert = data;
  },
};

export const actions = {
  async fetchAlerts({ state, commit }, {organizationId, patientId}) {
    let result = await this.$backend.fetchAlerts(organizationId, patientId)
    commit('SET_ALERTS', result.data)
    return result.data
  },
  async fetchOrgAlerts({ state, commit }, {organizationId}) {
    let result = await this.$backend.fetchOrgAlerts(organizationId)
    commit('SET_ORG_ALERTS', result.data)
    return result.data
  },
}

export const getters = {
  getAlerts: (state) => () => {
    return state.patientAlerts;
  },
  getOrgAlerts: (state) => () => {
    return state.organizationAlerts
  }
}
