export const state = () => ({
  search: null,
  patient_list_search: null,
});

export const mutations = {
  SET_SEARCH(state, data) {
    state.search = data;
  },
  SET_PATIENT_SEARCH(state, data) {
    state.patient_list_search = data;
  },
};

export const actions = {
  setSearchInput({ commit }, { search }) {
    commit("SET_SEARCH", search);
  },
  setPatientSearchInput({ commit }, { search }) {
    commit("SET_PATIENT_SEARCH", search);
  },
};

export const getters = {
  getSearch: (state) => () => {
    return state.search;
  },
  getPatientSearch: (state) => () => {
    return state.patient_list_search;
  },
};
