export const state = () => ({
  config: {
    //USER DETAILS
    user_details: require("./../../helpers/config/default-user-details")
      .default,
  },
});

export const mutations = {
  SET_LIST_FIELDS(state, data) {
    state.config["users_page"]["list_fields"] = data;
  },
  SET_NEW_FIELDS(state, data) {
    actions
      .getUserDetails()
      .user_tabs.find((e) => e.name == "dashboard")
      .content.tabs.push(data);
  },
  SET_NEW_CARDS(state, { board, data }) {
    actions
      .getUserDetails()
      .user_tabs.find((e) => e.name == "dashboard")
      .content.tabs.find((e) => e.name == board)
      .content.push(data);
  },
  REARRANGE_TABS(state, data) {
    actions
      .getUserDetails()
      .user_tabs.find((e) => e.name == "dashboard").content.tabs = data;
  },
};

export const actions = {};

export const getters = {
  getUserTableHeader: (state) => (page_name) => {
    return state.config[page_name]["list_fields"]; //.filter(e => !!e.display).map(e => e.name)
  },
  getPatientsInfo: (state) => (page_name) => {
    return state.config[page_name]["patients_info"].filter((e) => !!e.display);
  },
  getUserHeader: (state) => () => {
    return state.config.user_details.user_header;
  },
  getStats: (state) => (page_name, data_source) => {
    return state.config[page_name][data_source]
      ? state.config[page_name][data_source]
      : state.config[page_name].user_tabs.find((e) => e.name == "overview")
          .content[data_source];
  },
  getUserTabs: (state) => () => {
    return state.config.user_details.user_tabs;
  },
  getTabs: (state) => (field) => {
    return state.config.user_details.user_tabs.find((e) => e.name == field)
      .content.tabs;
  },

  getFields: (state) => (tab, field) => {
    if (!field) return;
    return state.config.user_details.user_tabs.find((e) => e.name == tab)
      .content[field]["fields"]; //.filter(e => !!e.display_table)
  },
  getCarePlanModalItems: (state) => (field) => {
    return state.config.user_details.user_tabs.find(
      (e) => e.name == "care-plan"
    ).content[field]["modalItems"];
  },
  getOverviewModalItems: (state) => (tab, field) => {
    if (!tab || !field) return;
    return state.config.user_details.user_tabs
      .find((e) => e.name == "overview")
      .content.tabs.find((e) => e.name == tab)
      .content.find((e) => e.name == field)["modalItems"];
  },
  getConfig: (state) => (key) => {
    var res = key.split(".");
    var return_value = state.config;
    res.forEach((element) => {
      return_value = return_value[element];
    });
    return return_value;
  },
};
