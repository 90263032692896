export const state = () => ({
  boards: [],
  tiles: [],
  currentTile: {},
  currentCard: {
    name: "",
    description: "",
    query: {
      type: "table",
      filters: {
        condition: "AND",
        rules: [],
      },
      fields: [],
    },
  },
  previousStep: null,
});

export const mutations = {
  SET_BOARDS(state, data) {
    state.boards = data;
  },
  DELETE_BOARD(state, boardId) {
    const index = state.boards.findIndex((x) => x.id === boardId);
    if (index !== -1) {
      state.boards.splice(index, 1);
    }
  },
  CREATE_BOARD(state, data) {
    state.boards.unshift(data);
  },
  SET_TILES(state, data) {
    state.tiles = data;
  },
  ADD_TO_CURRENT_TILE(state, data) {
    state.currentTile = {
      ...state.currentTile,
      ...data,
    };
  },
  SET_CURRENT_CARD(state, data) {
    if (!Object.keys(data)) {
      state.currentCard = {
        name: "",
        description: "",
        query: {
          type: "table",
          filters: {
            condition: "AND",
            rules: [],
          },
          fields: [],
        },
      };
    }
    for (const [key, value] of Object.entries(data)) {
      state.currentCard.hasOwnProperty(key)
        ? (state.currentCard[key] = value)
        : state.currentCard.query.hasOwnProperty(key)
        ? (state.currentCard.query[key] = value)
        : state.currentCard.query.filters.hasOwnProperty(key)
        ? (state.currentCard.query.filters[key] = value)
        : null;
    }
  },
  SET_STEP(state, data) {
    state.previousStep = data;
  },
};

export const actions = {
  async getBoards({ state, commit }, { organizationId }) {
    let response = await this.$backend.getBoards(organizationId);
    commit("SET_BOARDS", response.data);
    return response;
  },
  async createBoard({ state, commit }, { organizationId, payload }) {
    let response = await this.$backend.createBoard(organizationId, payload);
    commit("CREATE_BOARD", response);
    return response;
  },
  async updateBoard({ state, commit }, { organizationId, boardId, payload }) {
    let response = await this.$backend.updateBoard(
      organizationId,
      boardId,
      payload
    );
    return response;
  },
  async deleteBoard({ state, commit }, { organizationId, boardId }) {
    let response = await this.$backend.deleteBoard(organizationId, boardId);
    return response;
  },
  async getBoardsTiles({ state, commit }, { organizationId, boardId }) {
    let response = await this.$backend.getBoardsTiles(organizationId, boardId);
    return response;
  },
  async createBoardTile(
    { state, commit },
    { organizationId, boardId, payload }
  ) {
    let response = await this.$backend.createBoardTile(
      organizationId,
      boardId,
      payload
    );
    return response;
  },
  async updateBoardTile(
    { state, commit },
    { organizationId, boardId, tileId, payload }
  ) {
    let response = await this.$backend.updateBoardTile(
      organizationId,
      boardId,
      tileId,
      payload
    );
    return response;
  },
  async deleteBoardTile(
    { state, commit },
    { organizationId, boardId, tileId }
  ) {
    let response = await this.$backend.deleteBoardTiles(
      organizationId,
      boardId,
      tileId
    );
    return response;
  },
  async getQuery({ state, commit }, { organizationId, query }) {
    let result = await this.$backend.getQuery(organizationId, query);
    return result;
  },
};

export const getters = {
  getOrgBoards: (state) => () => {
    return state.boards;
  },
  getTiles: (state) => () => {
    return state.tiles;
  },
  getCurrentTile: (state) => () => {
    return state.currentTile;
  },
  getDashboardTableHeader: (state) => (page_name, app) => {
    let items = state[page_name].filter((e) => !!e.display).map((e) => e.name);
    return items.map((element) => {
      return {
        key: element,
        label: app.$i18n.t(`columns.${element}`),
      };
    });
    return state[page_name].filter((e) => !!e.display).map((e) => e.name);
  },
  getCurrentCard: (state) => () => {
    return state.currentCard;
  },
};
