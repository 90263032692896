
import { layoutMethods } from "~/store/helpers";

/**
 * Right-sidebar component
 */
export default {
  data() {
    return {
      config: {
        handler: this.handleRightBarClick,
        middleware: this.middleware,
        events: ["click"],
      },
      layout: this.$store
        ? this.$store.state.modules.layout.layoutType
        : {} || {},
      width: this.$store
        ? this.$store.state.modules.layout.layoutWidth
        : {} || {},
      topbar: this.$store ? this.$store.state.modules.layout.topbar : {} || {},
      sidebarType: this.$store
        ? this.$store.state.modules.layout.leftSidebarType
        : {} || {},
    };
  },
  methods: {
    ...layoutMethods,
    hide() {
      this.$parent.toggleRightSidebar();
    },
    handleRightBarClick(e, el) {
      this.$parent.hideRightSidebar();
    },
    middleware(event, el) {
      return !event.target.classList.contains("toggle-right");
    },
    changeLayout(layout) {
      this.changeLayoutType({
        layoutType: layout,
      });
    },
    changeType(type) {
      return this.changeLeftSidebarType({
        leftSidebarType: type,
      });
    },
    changeWidth(width) {
      return this.changeLayoutWidth({
        layoutWidth: width,
      });
    },
    changeTopbartype(value) {
      return this.changeTopbar({
        topbar: value,
      });
    },
  },
};
