import { error } from "pdf-lib";
import Vue from "vue";

export default ({ app }, inject) => {
  const buildError = (error) => {
    let statusCode = null;
    let customCode = null;
    let message = null;
    const response = error.response;
    let responseData = null;
    if (response) {
      statusCode = response.status;

      if (
        Boolean((responseData = response.data)) &&
        responseData.message &&
        responseData.message.trim() != ""
      ) {
        message = responseData.message;
        if (responseData.code) {
          customCode = responseData.code;
        }
      } else if (error.message && error.message.trim() != "") {
        message = error.message;
      } else {
        message = error;
      }

      var errors = [];
      if (response && response.data && response.data.errors) {
        var respErrors = response.data.errors;
        if (Array.isArray(respErrors)) {
          for (var respError in respErrors) {
            if (respErrors.hasOwnProperty(respError)) {
              errors.push(respErrors[respError]);
            }
          }
        } else if (typeof respErrors === "object") {
          Object.keys(respErrors).forEach(function (key) {
            errors.push(respErrors[key]);
          });
        } else {
          errors.push(respErrors);
        }
      } else {
        errors.push(message);
      }

      return {
        customCode: customCode,
        statusCode: statusCode,
        message: message,
        errors: errors,
      };
    }
  };
  const errorMessage = function (e) {
    let error = buildError(e);
    if (error.errors.length > 0) {
      return error.errors.join("<br>");
    } else {
      return error.message;
    }
  };
  inject("buildError", buildError);
  inject("errorMessage", errorMessage);

  const capitalizeWord = function (str) {
    if (!str) return;
    if (!str.split("_")) return e.charAt(0).toUpperCase() + e.slice(1);
    let arr = str.split("_");
    arr = arr.map((e) => {
      return e.charAt(0).toUpperCase() + e.slice(1);
    });
    return arr.join(" ");
  };
  inject("capitalizeWord", capitalizeWord);
};
