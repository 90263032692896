export const state = () => ({
  data: [],
  pagination: {},
});

export const mutations = {
  SET_ACHIEVEMENTS(state, topics) {
    state.data = topics.data;
    state.pagination = topics.pagination;
  },
};

export const actions = {
  async fetchAchievements(
    { commit },
    { organization_id, page = 1, patient_id = null }
  ) {
    const response = await this.$backend.getAchievements(organization_id, {
      page,
      patient_id,
    });

    commit("SET_ACHIEVEMENTS", {
      data: response.data,
      pagination: response.pagination,
    });
  },
};
