//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from "vuex";

export default {
  props: {
    chatWindowsCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters("chat", {
      conversations: "getInbox",
      nameForConversation: "nameForConversation",
      lastMessage: "lastMessage",
      initialsForConversation: "initialsForConversation",
      lastMessageTime: "lastMessageTime",
    }),
    ...mapState("chat", {
      unreadCount: (state) => state.unreadCount,
    }),
  },
  data() {
    return {
      checkForUnreadMessagesTimer: null,
      isOpen: false,
      currentPage: 1,
      perPage: 10,
      organizationId: localStorage.getItem("organizationId"),
    };
  },
  mounted() {
    this.$store.dispatch("chat/getInbox", {
      organizationId: this.organizationId,
      currentPage: this.currentPage,
    });
  },
  beforeDestroy() {
    // clearInterval(this.checkForUnreadMessagesTimer);
  },
  methods: {
    toggleInbox() {
      this.isOpen = !this.isOpen;
      this.$emit("inbox-toggle", this.isOpen);
      this.$store.dispatch("chat/getUnreadMessageCount");
      this.$store.dispatch("chat/getInbox", {
        organizationId: this.organizationId,
        currentPage: this.currentPage,
      });
    },

    selectChat(chat) {
      this.$store.dispatch("chat/selectChat", { chatId: chat.id });
      this.toggleInbox();
    },
  },
};
