export const state = () => ({
  users: {},
  tiles: {},
  transactions: {},
  started_timer_id: null,
  columns: [],
  search: null,
  loading: false,
  ccmRpmItems: [],
});

export const mutations = {
  SET_TRACKER_ID(state, data) {
    state.started_timer_id = data
  },
  SET_USERS(state, data) {
    state.users = data;
  },
  SET_LOADING(state, data) {
    state.loading = data;
  },
  SET_TILES_DATA(state, data) {
    this._vm.$set(state.tiles, `tile_${data.tileId}`, data.result);
  },
  SET_COLUMNS(state, data) {
    state.columns = data;
  },
  SET_TRANSACTIONS(state, data) {
    state.transactions = data;
  },
  SET_SEARCH(state, data) {
    state.search = data;
  },
  SET_CCM_RPM_ITEMS(state, data) {
    state.ccmRpmItems = data;
  },
};
export const actions = {
  setSearchInput({ commit }, { search }) {
    commit("SET_SEARCH", search);
  },
  filterResults({ commit }, { data }) {
    commit("SET_LOADING", true);
    commit("SET_USERS", data);
    commit("SET_LOADING", false);
  },
  async getTileResults(
    { commit },
    { organizationId, perPage, currentPage, filters, tileId }
  ) {
    commit("SET_TILES_DATA", { tileId: tileId, result: [] });
    let result = await this.$backend.getUsers(
      organizationId,
      perPage,
      currentPage,
      filters
    );
    commit("SET_TILES_DATA", { tileId, result });
    return result;
  },
  async getUsers(
    { commit },
    {
      organizationId,
      perPage,
      currentPage,
      filters,
      parent_id,
      disease_id,
      sort,
      search,
    }
  ) {
    commit("SET_LOADING", true);
    if (localStorage.getItem("filterActive")) return;
    let result = await this.$backend.getUsers(
      organizationId,
      perPage,
      currentPage,
      filters,
      parent_id,
      disease_id,
      sort,
      search
    );
    commit("SET_USERS", result);
    commit("SET_LOADING", false);
    return result;
  },
  async getUserTransactions(
    { commit },
    { organizationId, perPage, currentPage, parent_id }
  ) {
    let result = await this.$backend.getUserTransactions(
      organizationId,
      perPage,
      currentPage,
      parent_id
    );
    commit("SET_TRANSACTIONS", result);
    return result;
  },
  async getUserDetails({ commit }, { organizationId, patientId }) {
    let result = await this.$backend.getUserDetails(organizationId, patientId);
    return result;
  },
  async getPatientTableColumns({ commit }, { organizationId }) {
    let result = await this.$backend.getPatientTableColumns(organizationId);
    commit("SET_COLUMNS", result.data.columns);
    return result;
  },
  async getAllColumnFields({}, { organizationId }) {
    let result = await this.$backend.getAllColumnFields(organizationId);
    return result;
  },
  async createOrUpdateColumnFields({ commit }, { organizationId, payload }) {
    let result = await this.$backend.createOrUpdateColumnFields(
      organizationId,
      payload
    );
    return result;
  },
  async startTracker({ commit }, { organizationId, patientId, payload }) {
    let result = await this.$backend.startTracker(
      organizationId,
      patientId,
      payload
    ).then(res => {
      commit("SET_TRACKER_ID",res.id)
    });
    return result;
  },
  async stopTracker({ commit }, { organizationId, patientId, payload }) {
    let result = await this.$backend.stopTracker(
      organizationId,
      patientId,
      payload
    );
    commit('SET_TRACKER_ID', null)
    return result;
  },
  async getActiveTracker({ commit }, { organizationId, patientId, type }) {
    let result = await this.$backend.getActiveTracker(
      organizationId,
      patientId,
      type
    );
    return result;
  },
  async getCcmRpm({ commit }, { organizationId, patientId }) {
    let result = await this.$backend.getCcmRpm(organizationId, patientId);
    commit("SET_CCM_RPM_ITEMS", [
      {
        key: "CCM",
        ccm_data: result.ccm_data,
        ccm_target: result.ccm_target,
      },
      {
        key: "RPM",
        rpm_data: result.rpm_data,
        rpm_target: result.rpm_target,
      },
      {
        key: "RTM",
        rtm_data: result.rtm_data,
        rtm_target: result.rtm_target,
      },
    ]);
    return result;
  },
  async getDeviceTypes({ commit }, { organizationId, patientId }) {
    let result = await this.$backend.getDeviceTypes(organizationId, patientId);
    return result;
  },
  async getDevices({ commit }, { organizationId, patientId, currentPage }) {
    let result = await this.$backend.getDevices(
      organizationId,
      patientId,
      currentPage
    );
    return result;
  },
};

export const getters = {
  getUserTableHeaders: (state) => (app) => {
    let initialArray =
      state.columns.length <= 10
        ? state.columns
        : [
            "alerts",
            "display_name",
            "age",
            "plan_name",
            "blood_pressure",
            "glucose",
            "oxygen_saturation",
            "weight",
            "heart_rate",
          ];
    return initialArray.map((element) => {
      return {
        key: element,
        label: app.$i18n.t(`columns.${element}`),
        Sortable:
          element === "age" ||
          element === "created_at" ||
          element === "last_active"
            ? true
            : false,
      };
    });
    return state.columns.length > 10
      ? initialArray.sort((a, b) => a.id - b.id).map((e) => e.name)
      : state.columns;
  },
  getUserTransactionsHeaders: (state) => (app) => {
    return state.columns.map((element) => {
      return {
        key: element,
        label: app.$i18n.t(`columns.${element}`),
      };
    });

    let initialArray = [
      { name: "display_name", id: 1, label: "Student" },
      { name: "dollars_per_book", id: 2 },
      { name: "total_dollars", id: 3 },
      { name: "transaction_date", id: 4 },
    ];
    return state.columns.length > 10
      ? initialArray.sort((a, b) => a.id - b.id).map((e) => e.name)
      : state.columns;
  },
  getLeeHeaders: (state) => (app) => {
    let initialArray = [
      "coach",
      "display_name",
      "activity",
      "engagement",
      "race",
      "partner_org",
      "region",
    ];

    return initialArray.map((element) => {
      return {
        key: element,
        label: app.$i18n.t(`columns.${element}`),
        Sortable:
          element === "engagement" || element === "activity" ? true : false,
      };
    });
  },
  getCurrentTrackerId: (state) => () => {
    return state.started_timer_id
  },
  getUsers: (state) => () => {
    return state.users;
  },
  getSearch: (state) => () => {
    return state.search;
  },
  getLoadingState: (state) => () => {
    return state.loading;
  },
};
