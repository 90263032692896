export default function ({ route, redirect, $auth, store }) {
  if (!store.state.magic.patient && localStorage.getItem("magic_link_id")) {
    return redirect(`/magic-link/${localStorage.getItem("magic_link_id")}`);
  } else if (
    !store.state.magic.patient &&
    !localStorage.getItem("magic_link_id")
  ) {
    return redirect(`/magic-link/expired`);
  }
}
