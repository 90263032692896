import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted, {"iconPack":"material","position":"bottom-right","type":"default","theme":"bubble","duration":1500,"className":"listto-toast"})

const globals = [{"name":"success","message":(payload) => {
      return payload.message;
    },"options":{"type":"success","icon":{"name":"check_circle","after":false}}},{"name":"error","message":(payload) => {
      return payload.error;
    },"options":{"type":"error","duration":7000,"icon":{"name":"error_outline","after":false}}}]
if(globals) {
  globals.forEach(global => {
    Vue.toasted.register(global.name, global.message, global.options)
  })
}

export default function (ctx, inject) {
  inject('toast', Vue.toasted)
}
