export const state = () => ({
  syncingStatus: null,
});

export const mutations = {
  SET_SYNCING_STATUS(state, data) {
    state.syncingStatus = data
  }
};

export const getters = {
  getSyncStatus: (state) => () => {
    return state.syncingStatus
  }
};
