var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeModal),expression:"closeModal"}]},[_c('div',{staticClass:"container"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center","height":"2rem"}},[_c('p',{staticClass:"mt-3",staticStyle:{"font-weight":"500","font-size":"14px"}},[_vm._v("\n        "+_vm._s(_vm.$t("filters.button"))+"\n      ")]),_vm._v(" "),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.push({
            name: 'patient_filters-id',
            params: { id: '/' },
            query: { model: 'patients', show: 'no' },
          })}}},[_vm._v(_vm._s(_vm.$t("filters.filter_modal.create")))])],1),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"mt-1"},[_c('b-input',{attrs:{"placeholder":_vm.$t('chats.search_placeholder')},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1),_vm._v(" "),(_vm.loading)?_c('div',[(_vm.loading)?_c('Loading',{attrs:{"loading":_vm.loading}}):_vm._e()],1):_vm._e(),_vm._v(" "),(!_vm.loading && !_vm.patientFilters.length)?_c('div',{staticClass:"empty_state__class"},[_c('p',{staticClass:"text-primary"},[_vm._v("\n        "+_vm._s(_vm.$t("filters.filter_modal.empty_state"))+".\n      ")])]):_vm._e(),_vm._v(" "),(!_vm.loading && _vm.patientFilters)?_c('div',{staticStyle:{"overflow":"scroll","max-height":"50vh"}},_vm._l((_vm.patientFilters),function(filter){return _c('div',{key:filter.id,staticClass:"singular_filter__container mt-2"},[_c('div',{staticClass:"pt-2 d-flex flex-column",on:{"click":function($event){return _vm.selectFilter(filter)}}},[_c('b',{staticClass:"filter__title"},[_vm._v(" "+_vm._s(filter.name)+" ")]),_vm._v(" "),_c('span',{staticClass:"text-muted"},[_vm._v("\n            "+_vm._s(filter.description)+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"actions_display__btn"},[_c('b-dropdown',{attrs:{"no-caret":"","dropleft":"","toggle-class":"rounded-circle px-2","variant":"light","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon',{attrs:{"scale":"1","icon":"three-dots-vertical","aria-hidden":"true"}})]},proxy:true}],null,true)},[_vm._v(" "),_c('b-dropdown-item',{on:{"click":function($event){return _vm.$router.push({
                  name: 'patient_filters-id',
                  params: { id: filter.id },
                  query: { model: 'patients', show: 'no' },
                })}}},[_c('b-icon',{attrs:{"icon":"pencil-fill","scale":"0.7"}}),_c('b',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t("filters.filter_modal.edit")))])],1),_vm._v(" "),_c('b-dropdown-item',{on:{"click":function($event){return _vm.duplicateFilter(filter)}}},[_c('i',{staticClass:"mdi-content-duplicate"}),_vm._v(" "),_c('b',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t("filters.filter_modal.duplicate")))])]),_vm._v(" "),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteModalOpen(filter.id)}}},[_c('b-icon',{attrs:{"scale":"0.7","icon":"trash-fill","aria-hidden":"true"}}),_c('b',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t("filters.filter_modal.delete")))])],1)],1)],1)])}),0):_vm._e()]),_vm._v(" "),_c('delete-confirmation-modal',{attrs:{"name":_vm.$t('filters.button'),"id":"delete"},on:{"delete":_vm.deleteFilter,"cancel":function($event){_vm.selectedId = null}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('hr')])}]

export { render, staticRenderFns }