
export default {
  data() {
    return {
      modalTitle: "Image Preview",
    };
  },
  methods: {
    onModalHidden() {
      this.$emit("hidden");
    },
    show() {
      this.$bvModal.show("delete-message-modal");
    },
    hide() {
      this.$bvModal.hide("delete-message-modal");
    },
  },
};
