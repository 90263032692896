export const state = () => ({
  memberships: [],
});

export const mutations = {
  SET_MEMBERSHIPS(state, data) {
    state.memberships = data;
  },
};
export const actions = {
  async fetchMemberships({ commit }, { organizationId }) {
    let result = await this.$backend.getMemberships(organizationId);
    commit("SET_MEMBERSHIPS", result.data);
    return result;
  },
};

export const getters = {
  getMemberships: (state) => () => {
    return state.memberships;
  },
};
