export const state = () => ({
  filters: {
    service: null,
    state: null,
    provider: null,
    start_date: null,
    end_date: null,
  },
});

export const mutations = {
  SET_FILTERS(state, data) {
    state.filters = data;
  },
};

export const getters = {
  getFilters: (state) => () => {
    return state.filters;
  },
};
