var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"sidebar",staticClass:"vertical-menu"},[_c('div',{staticClass:"navbar-brand-box"},[_c('nuxt-link',{staticClass:"logo logo-dark text-left",attrs:{"to":_vm.logoRedirect}},[_c('span',{staticClass:"logo-sm"},[_c('img',{attrs:{"src":_vm.$vianovaConfig.options.logo,"alt":"","height":"22"}})]),_vm._v(" "),_c('span',{staticClass:"logo-lg"},[_c('img',{attrs:{"src":_vm.$vianovaConfig.options.logo,"alt":"","height":"30"}})])]),_vm._v(" "),_c('nuxt-link',{staticClass:"logo logo-light text-left",attrs:{"to":_vm.logoRedirect}},[_c('span',{staticClass:"logo-sm"},[_c('img',{attrs:{"src":_vm.$vianovaConfig.options.logo,"alt":"","height":"22"}})]),_vm._v(" "),_c('span',{staticClass:"logo-lg"},[_c('img',{attrs:{"src":_vm.$vianovaConfig.options.logo,"alt":"","height":"30"}})])])],1),_vm._v(" "),_c('div',{ref:"sidebar",staticClass:"sidebar-menu-scroll",attrs:{"data-simplebar":""}},[_c('div',{attrs:{"id":"sidebar-menu"}},[_c('ul',{staticClass:"metismenu list-unstyled",attrs:{"id":"side-menu"}},[[_c('li',[_c('nuxt-link',{staticClass:"side-nav-link-ref",attrs:{"to":{
                name: 'users-id-overview',
                params: {
                  id: _vm.$route.params.id,
                },
              }}},[_c('i',{staticClass:"uil-left-arrow-to-left"}),_vm._v(" "),_c('span',[_vm._v("Back")])])],1),_vm._v(" "),[_c('li',{staticClass:"menu-title"},[_vm._v("Playground")])],_vm._v(" "),_c('li',[_c('nuxt-link',{staticClass:"side-nav-link-ref",attrs:{"to":{
                name: 'users-id-playground',
                params: {
                  id: _vm.$route.params.id,
                },
              }}},[_c('i',{staticClass:"fas fa-user-shield"}),_vm._v(" "),_c('span',[_vm._v("Details")])])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{staticClass:"side-nav-link-ref",attrs:{"to":{
                name: 'users-id-playground-magic-link',
                params: {
                  id: _vm.$route.params.id,
                },
              }}},[_c('i',{staticClass:"fas fa-magic"}),_vm._v(" "),_c('span',[_vm._v("Magic Link")])])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{staticClass:"side-nav-link-ref",attrs:{"to":{
                name: 'users-id-playground-demo-data',
                params: {
                  id: _vm.$route.params.id,
                },
              }}},[_c('i',{staticClass:"fas fa-atom"}),_vm._v(" "),_c('span',[_vm._v("Demo Data")])])],1)]],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }