export const state = () => ({
  tasks: [],
  medications: [],
  carePlans: [],
});

export const mutations = {
  SET_TASKS(state, payload) {
    state[payload.store] = payload.data;
  },
  SET_CARE_PLANS(state, payload) {
    state.carePlans.push(payload);
  },
  SET_ALERTS(state, payload) {
    //state.carePlans[payload.id].alerts.find(e=>e.id==payload.alertId) = payload.event
  },
};

export const actions = {
  createTask({ state, commit }, { data, store }) {
    commit("SET_TASKS", { data, store });
  },
  updateTask({ state, commit }, { data, store }) {
    commit("SET_TASKS", { data, store });
  },
  deleteTask({ state, commit }, { data, store }) {
    commit("SET_TASKS", { data, store });
  },
};

export const getters = {
  getTasks: (state) => () => {
    return state.tasks;
  },
  getMedications: (state) => () => {
    return state.medications;
  },
  getCarePlans: (state) => () => {
    return state.carePlans;
  },
};
