import Vue from "vue";

const HeaderHelper = {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        makeHeader(title = ``) {
          return {
            meta: [
              { charset: "utf-8" },
              {
                name: "viewport",
                content:
                  "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
              },
            ],
            link: [
              {
                hid: "icon",
                rel: "icon",
                type: "image/x-icon",
                href: this.$vianovaConfig.options.favicon,
              },
            ],
            title: title,
            titleTemplate: `%s | ${this.$vianovaConfig.options.name}`,
          };
        },
      },
    });
  },
};

Vue.use(HeaderHelper);
