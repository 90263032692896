export const state = () => ({
  patients: [],
  currentPatient: {},
  loadingChats: false,
  searchedPatients: [],
  allPages: 1,
});

export const mutations = {
  SET_LOADING_CHATS(state, data) {
    state.loadingChats = data;
  },
  SET_PATIENTS(state, data) {
    state.patients = data;
  },
  SET_PATIENT_CONVERSATION(state, data) {
    state.currentPatient = data;
  },
  SET_SEARCHED_PATIENTS(state, data) {
    state.searchedPatients = data;
  },
  SET_ALL_PAGES(state, data) {
    state.allPages = data;
  },
};

export const actions = {
  setConversationAsRead({ state, commit }, { chatId }) {
    console.log(`setting conversation as read ${chatId}`);
    let allPatientsArray = state.patients.map((patient) => {
      let pt = { ...patient };
      if (pt.conversation_id === chatId) {
        pt.unread_count = 0;
      }
      return pt;
    });

    commit(
      "SET_PATIENTS",
      allPatientsArray.filter(
        (value, index, array) =>
          array.map((e) => e.id).indexOf(value.id) === index
      )
    );
  },
  async fetchChatPatients(
    { state, commit },
    { organizationId, currentPage, perPage }
  ) {
    if (currentPage > state.allPages) {
      return;
    }
    let result = await this.$backend.getChatPatients(
      organizationId,
      currentPage,
      perPage
    );
    let allPatientsArray = result.data;
    commit("SET_ALL_PAGES", result.pagination.last_page);
    commit(
      "SET_PATIENTS",
      allPatientsArray.filter(
        (value, index, array) =>
          array.map((e) => e.id).indexOf(value.id) === index
      )
    );
    return result;
  },
  async searchedPatientsWithInbox(
    { state, commit },
    { organizationId, currentPage, perPage, search, inbox, new_message }
  ) {
    let result = await this.$backend.getSearchedChatPatients(
      organizationId,
      currentPage,
      perPage,
      search,
      inbox,
      new_message
    );
    commit("SET_SEARCHED_PATIENTS", result.data);
  },
  async fetchPatientConversation(
    { state, commit },
    { organizationId, chatId, currentPage, perPage }
  ) {
    let result = await this.$backend.getPatientConversation(
      organizationId,
      chatId,
      currentPage,
      perPage
    );
    commit("SET_PATIENT_CONVERSATION", result.data.reverse());
  },
  async startNewConverssation(
    { state, commit },
    { organizationId, patientId }
  ) {
    let result = await this.$backend.patientNewConversation(
      organizationId,
      patientId
    );
    return result;
  },
};

export const getters = {
  getLoadingChats: (state) => () => {
    return state.loadingChats;
  },
  getChatPatients: (state) => () => {
    let patients = [...state.patients].filter((e) => {
      let userDeleted = e.conversation.participants.some((e) => {
        return e.user && e.user.deleted_at;
      });
      if (!userDeleted) return e;
    });
    return patients;
  },
  getCurrentPatientConversation: (state) => () => {
    return state.currentPatient;
  },
  getSearchedPatients: (state) => () => {
    return state.searchedPatients;
  },
};
