export default {
  path: "/user",
  // USER TABS
  user_tabs: [
    {
      id: 12,
      label: "menuitems.menu.text",
      isTitle: true,
      display: true,
    },
    {
      id: 13,
      label: "menuitems.dashboard.text",
      icon: "uil-left-arrow-to-left",
      name: "dashboard",
      link: "/",
      display: true,
      content: {
        tabs: [
          {
            id: 18,
            name: "overview",
            path: "overview",
            display_name: "Overview",
            display: true,
            content: [],
          },
        ],
      },
    },
    {
      id: 14,
      label: "menuitems.user_tabs.text",
      isTitle: true,
      display: true,
    },
    {
      id: 18,
      name: "reports",
      link: "reports",
      label: "menuitems.reports.text",
      icon: "uil-link",
      display: false,
      content: {
        tabs: [
          {
            id: 20,
            name: "multiple_patients",
            path: "multiple_patients",
            display_name: "Multiple Patients",
            display: true,
            content: [],
            tabs: [
              {
                id: 18,
                name: "ccm",
                link: "ccm",
              },
              {
                id: 19,
                name: "general",
                link: "general",
              },
            ],
          },
          {
            id: 21,
            name: "single_patients",
            path: "single_patients",
            display_name: "Single Patients",
            display: true,
            content: [],
          },
          {
            id: 19,
            name: "conversation_reports",
            path: "conversation_reports",
            display_name: "Conversation Reports",
            display: true,
            content: [],
          },
        ],
      },
    },
    {
      id: 15,
      name: "overview",
      label: "menuitems.overview.text",
      display: true,
      link: "overview",
      icon: "uil-apps",
      content: {
        tabs: [
          {
            id: 18,
            name: "vitals",
            path: "vitals",
            display_name: "tabs.vitals",
            icon: "uil-heart-rate",
            display: true,
            content: [
              {
                name: "pain_scale",
                display_name: "tabs.pain_scale",
                hasRange: false,
                display: true,
                isTable: true,
                icon: "mdi mdi-water",
                modalItems: [
                  {
                    display: true,
                    value: "tabs.pain_scale",
                    key: "vital",
                    display_name: "Vital",
                    type: "text",
                    placeholder: "tabs.add_vital",
                  },
                  {
                    display: true,
                    value: null,
                    key: "value",
                    display_name: "tabs.value",
                    type: "text",
                    placeholder: "tabs.pain_score_value",
                  },
                  {
                    display: true,
                    value: null,
                    key: "date",
                    display_name: "tabs.date",
                    type: "date-picker",
                    placeholder: "Choose date",
                  },
                  {
                    display: true,
                    value: null,
                    key: "time",
                    display_name: "tabs.time_of_day",
                    type: "time-picker",
                    placeholder: "tabs.time_of_day",
                  },
                  {
                    display: true,
                    value: null,
                    key: "notes",
                    display_name: "tabs.notes",
                    type: "textarea",
                    placeholder: "Notes",
                  },
                ],
              },
              {
                name: "glucose",
                display_name: "tabs.glucose",
                hasRange: true,
                display: true,
                isTable: true,
                icon: "mdi mdi-water",
                modalItems: [
                  {
                    display: true,
                    value: "tabs.glucose",
                    key: "vital",
                    display_name: "Vital",
                    type: "text",
                    placeholder: "tabs.add_vital",
                  },
                  {
                    display: true,
                    value: null,
                    key: "value",
                    display_name: "tabs.value",
                    type: "text",
                    placeholder: "tabs.value",
                  },
                  {
                    display: true,
                    value: null,
                    key: "date",
                    display_name: "tabs.date",
                    type: "date-picker",
                    placeholder: "Choose date",
                  },
                  {
                    display: true,
                    value: null,
                    key: "time",
                    display_name: "tabs.time_of_day",
                    type: "time-picker",
                    placeholder: "tabs.time_of_day",
                  },
                  {
                    display: true,
                    value: null,
                    key: "notes",
                    display_name: "tabs.notes",
                    type: "textarea",
                    placeholder: "Notes",
                  },
                ],
              },
              {
                name: "blood_pressure",
                display_name: "tabs.blood_pressure",
                isTable: true,
                display: true,
                hasRange: true,
                icon: "mdi mdi-heart",
                modalItems: [
                  {
                    display: true,
                    value: "tabs.blood_pressure",
                    key: "vital",
                    display_name: "Vital",
                    type: "text",
                    placeholder: "tabs.add_vital",
                  },
                  {
                    display: true,
                    value: null,
                    key: "value",
                    display_name: "tabs.value",
                    type: "text",
                    placeholder: "tabs.value",
                  },
                  {
                    display: true,
                    value: null,
                    key: "date",
                    display_name: "Date",
                    type: "date-picker",
                    placeholder: "Choose date",
                  },
                  {
                    display: true,
                    value: null,
                    key: "time",
                    display_name: "tabs.time_of_day",
                    type: "time-picker",
                    placeholder: "Time of Day",
                  },
                  {
                    display: true,
                    value: null,
                    key: "notes",
                    display_name: "tabs.notes",
                    type: "textarea",
                    placeholder: "tabs.notes",
                  },
                ],
              },
              {
                name: "heart_rate",
                display_name: "tabs.heart_rate",
                isTable: true,
                hasRange: true,
                display: true,
                icon: "mdi mdi-heart-pulse",
                modalItems: [
                  {
                    display: true,
                    value: "tabs.heart_rate",
                    key: "vital",
                    display_name: "Vital",
                    type: "text",
                    placeholder: "tabs.add_vital",
                  },
                  {
                    display: true,
                    value: null,
                    key: "value",
                    display_name: "tabs.value",
                    type: "text",
                    placeholder: "tabs.value",
                  },
                  {
                    display: true,
                    value: null,
                    key: "date",
                    display_name: "tabs.date",
                    type: "date-picker",
                    placeholder: "Choose date",
                  },
                  {
                    display: true,
                    value: null,
                    key: "time",
                    display_name: "tabs.time_of_day",
                    type: "time-picker",
                    placeholder: "Time of Day",
                  },
                  {
                    display: true,
                    value: null,
                    key: "notes",
                    display_name: "tabs.notes",
                    type: "textarea",
                    placeholder: "tabs.notes",
                  },
                ],
              },
              {
                name: "weight",
                display_name: "tabs.weight",
                isTable: true,
                display: true,
                hasRange: true,
                icon: "mdi mdi-scale",
                modalItems: [
                  {
                    display: true,
                    value: "tabs.weight",
                    key: "vital",
                    display_name: "tabs.add_vital",
                    type: "text",
                    placeholder: "tabs.add_vital",
                  },
                  {
                    display: true,
                    value: null,
                    key: "value",
                    display_name: "tabs.value",
                    type: "text",
                    placeholder: "tabs.value",
                  },
                  {
                    display: false,
                    value: "pound",
                    key: "unit",
                    display_name: "Unit",
                    type: "multiselect",
                    multiple: false,
                    placeholder: "Add Unit",
                    options: [
                      { key: "kg", display_name: "kg" },
                      { key: "pound", display_name: "pound" },
                    ],
                  },
                  {
                    display: true,
                    value: null,
                    key: "date",
                    display_name: "tabs.date",
                    type: "date-picker",
                    placeholder: "Choose date",
                  },
                  {
                    display: true,
                    value: null,
                    key: "time",
                    display_name: "tabs.time_of_day",
                    type: "time-picker",
                    placeholder: "Time of Day",
                  },
                  {
                    display: true,
                    value: null,
                    key: "notes",
                    display_name: "tabs.notes",
                    type: "textarea",
                    placeholder: "tabs.notes",
                  },
                ],
              },
              {
                name: "oxygen_saturation",
                display_name: "tabs.oxygen_saturation",
                isTable: true,
                display: true,
                hasRange: true,
                icon: "mdi mdi-heart",
                modalItems: [
                  {
                    display: true,
                    value: "tabs.oxygen_saturation",
                    key: "vital",
                    display_name: "Vital",
                    type: "text",
                    placeholder: "tabs.add_vital",
                  },
                  {
                    display: true,
                    value: null,
                    key: "value",
                    display_name: "tabs.value",
                    type: "text",
                    placeholder: "tabs.value",
                  },
                  {
                    display: true,
                    value: null,
                    key: "date",
                    display_name: "tabs.date",
                    type: "date-picker",
                    placeholder: "Choose date",
                  },
                  {
                    display: true,
                    value: null,
                    key: "time",
                    display_name: "tabs.time_of_day",
                    type: "time-picker",
                    placeholder: "Time of Day",
                  },
                  {
                    display: true,
                    value: null,
                    key: "notes",
                    display_name: "tabs.notes",
                    type: "textarea",
                    placeholder: "Notes",
                  },
                ],
              },
            ],
          },
          {
            name: "lab-vitals",
            display_name: "tabs.lab_vitals",
            path: "lab-vitals",
            display: true,
            content: {
              name: {
                display: true,
                display_name: "Name",
              },
              value: {
                display: true,
                display_name: "Value",
              },
              reference: {
                display: true,
                display_name: "Reference",
              },
              date: {
                display: true,
                display_name: "Date",
              },
              modalItems: [
                {
                  display: true,
                  value: null,
                  key: "vital",
                  display_name: "tabs.lab_vitals",
                  type: "multiselect",
                  options: [],
                  placeholder: "tabs.add_vital",
                },
                {
                  display: true,
                  value: null,
                  key: "value",
                  display_name: "tabs.value",
                  type: "text",
                  placeholder: "tabs.value",
                },
                {
                  display: true,
                  value: null,
                  key: "date",
                  display_name: "tabs.date",
                  type: "date-picker",
                  placeholder: "Choose date",
                },
                {
                  display: true,
                  value: null,
                  key: "time",
                  display_name: "tabs.time_of_day",
                  type: "time-picker",
                  placeholder: "Time of Day",
                },
                {
                  display: false,
                  value: null,
                  key: "notes",
                  display_name: "tabs.notes",
                  type: "textarea",
                  placeholder: "tabs.notes",
                },
              ],
            },
            add_button: {
              name: "add_new_vitals_button",
              display_name: "Add New Vital",
              enabled: true,
            },
          },
          {
            name: "medications",
            display_name: "tabs.medications",
            path: "medications",
            display: true,
            content: [
              {
                name: "plavix",
                display_name: "Plavix",
                display: true,
                modalItems: [
                  {
                    display: true,
                    value: "Plavix",
                    key: "medication",
                    display_name: "Medication",
                    type: "text",
                    placeholder: "Add Medication",
                  },
                  {
                    display: true,
                    value: "no",
                    key: "medicine_taken",
                    display_name: "Medicine Taken?",
                    type: "checkbox",
                    placeholder: "No",
                  },
                  {
                    display: true,
                    value: null,
                    key: "date",
                    display_name: "Date",
                    type: "date-picker",
                    placeholder: "Choose date",
                  },
                  {
                    display: true,
                    value: null,
                    key: "time",
                    display_name: "Time of Day",
                    type: "time-picker",
                    placeholder: "Time of Day",
                  },
                  {
                    display: true,
                    value: null,
                    key: "meal_association",
                    display_name: "Meal Association",
                    type: "multiselect",
                    multiple: false,
                    placeholder: "Meal Association",
                    options: [
                      { key: "before", display_name: "Before" },
                      { key: "after", display_name: "After" },
                      { key: "with", display_name: "With" },
                    ],
                  },
                  {
                    display: true,
                    value: null,
                    key: "notes",
                    display_name: "Notes",
                    type: "textarea",
                    placeholder: "Notes",
                  },
                ],
              },
              {
                name: "aldactone",
                display_name: "Aldactone",
                display: true,
                modalItems: [
                  {
                    display: true,
                    value: "Aldactone",
                    key: "medication",
                    display_name: "Medication",
                    type: "text",
                    placeholder: "Add Medication",
                  },
                  {
                    display: true,
                    value: "no",
                    key: "medicine_taken",
                    display_name: "Medicine Taken?",
                    type: "checkbox",
                    placeholder: "No",
                  },
                  {
                    display: true,
                    value: null,
                    key: "date",
                    display_name: "Date",
                    type: "date-picker",
                    placeholder: "Choose date",
                  },
                  {
                    display: true,
                    value: null,
                    key: "time",
                    display_name: "Time of Day",
                    type: "time-picker",
                    placeholder: "Time of Day",
                  },
                  {
                    display: true,
                    value: null,
                    key: "meal_association",
                    display_name: "Meal Association",
                    type: "multiselect",
                    multiple: true,
                    placeholder: "Meal Association",
                    options: [
                      { key: "breakfast", display_name: "Breakfast" },
                      { key: "brunch", display_name: "Brunch" },
                      { key: "lunch", display_name: "Lunch" },
                      { key: "dinner", display_name: "Dinner" },
                    ],
                  },
                  {
                    display: true,
                    value: null,
                    key: "notes",
                    display_name: "Notes",
                    type: "textarea",
                    placeholder: "Notes",
                  },
                ],
              },
              {
                name: "aspirin",
                display_name: "Aspirin",
                display: true,
                modalItems: [
                  {
                    display: true,
                    value: "Aspirin",
                    key: "medication",
                    display_name: "Medication",
                    type: "text",
                    placeholder: "Add Medication",
                  },
                  {
                    display: true,
                    value: "no",
                    key: "medicine_taken",
                    display_name: "Medicine Taken?",
                    type: "checkbox",
                    placeholder: "No",
                  },
                  {
                    display: true,
                    value: null,
                    key: "date",
                    display_name: "Date",
                    type: "date-picker",
                    placeholder: "Choose date",
                  },
                  {
                    display: true,
                    value: null,
                    key: "time",
                    display_name: "Time of Day",
                    type: "time-picker",
                    placeholder: "Time of Day",
                  },
                  {
                    display: true,
                    value: null,
                    key: "meal_association",
                    display_name: "Meal Association",
                    type: "multiselect",
                    multiple: true,
                    placeholder: "Meal Association",
                    options: [
                      { key: "breakfast", display_name: "Breakfast" },
                      { key: "brunch", display_name: "Brunch" },
                      { key: "lunch", display_name: "Lunch" },
                      { key: "dinner", display_name: "Dinner" },
                    ],
                  },
                  {
                    display: true,
                    value: null,
                    key: "notes",
                    display_name: "Notes",
                    type: "textarea",
                    placeholder: "Notes",
                  },
                ],
              },
              {
                name: "nebivolol",
                display_name: "Nebivolol",
                display: true,
                modalItems: [
                  {
                    display: true,
                    value: "Nebivolol",
                    key: "medication",
                    display_name: "Medication",
                    type: "text",
                    placeholder: "Add Medication",
                  },
                  {
                    display: true,
                    value: "no",
                    key: "medicine_taken",
                    display_name: "Medicine Taken?",
                    type: "checkbox",
                    placeholder: "No",
                  },
                  {
                    display: true,
                    value: null,
                    key: "date",
                    display_name: "Date",
                    type: "date-picker",
                    placeholder: "Choose date",
                  },
                  {
                    display: true,
                    value: null,
                    key: "time",
                    display_name: "Time of Day",
                    type: "time-picker",
                    placeholder: "Time of Day",
                  },
                  {
                    display: true,
                    value: null,
                    key: "meal_association",
                    display_name: "Meal Association",
                    type: "multiselect",
                    multiple: true,
                    placeholder: "Meal Association",
                    options: [
                      { key: "breakfast", display_name: "Breakfast" },
                      { key: "brunch", display_name: "Brunch" },
                      { key: "lunch", display_name: "Lunch" },
                      { key: "dinner", display_name: "Dinner" },
                    ],
                  },
                  {
                    display: true,
                    value: null,
                    key: "notes",
                    display_name: "Notes",
                    type: "textarea",
                    placeholder: "Notes",
                  },
                ],
              },
            ],
          },
          {
            name: "tasks",
            display_name: "Tasks",
            path: "tasks",
            display: false,
            content: {
              name: "name_of_content",
              display_name: "",
              display: true,
            },
          },
          {
            name: "dental-tasks",
            path: "tasks",
            display_name: "tabs.dental_tasks",
            icon: "uil-tooth",
            display: false,
            content: [
              {
                name: "brushing",
                display_name: "tabs.brushing",
                hasRange: false,
                display: true,
                isTable: true,
                icon_url:
                  "https://vianova.s3.amazonaws.com/82b0a51f6f8e95269b6d681e7a9a99367caa15a9.svg",
                icon: "mdi mdi-toothbrush",
                modalItems: [
                  {
                    display: true,
                    value: "tabs.brushing",
                    key: "task",
                    display_name: "Task",
                    type: "text",
                    placeholder: "tabs.add_task",
                  },
                  {
                    display: true,
                    value: true,
                    key: "value",
                    display_name: "tabs.completed",
                    type: "checkbox",
                    placeholder: "tabs.value",
                  },
                  {
                    display: true,
                    value: null,
                    key: "date",
                    display_name: "tabs.date",
                    type: "date-picker",
                    placeholder: "Choose date",
                  },
                  {
                    display: true,
                    value: null,
                    key: "time",
                    display_name: "tabs.time_of_day",
                    type: "time-picker",
                    placeholder: "tabs.time_of_day",
                  },
                  {
                    display: true,
                    value: null,
                    key: "notes",
                    display_name: "tabs.notes",
                    type: "textarea",
                    placeholder: "Notes",
                  },
                ],
              },
              {
                name: "flossing",
                display_name: "tabs.flossing",
                hasRange: false,
                display: true,
                isTable: true,
                icon_url:
                  "https://vianova.s3.amazonaws.com/54e2369bbf4a31b8b6e7b7bfef29f042156ad2c8.svg",
                icon: "mdi mdi-toothbrush",
                modalItems: [
                  {
                    display: true,
                    value: "tabs.flossing",
                    key: "task",
                    display_name: "Task",
                    type: "checkbox",
                    placeholder: "tabs.add_task",
                  },
                  {
                    display: true,
                    value: true,
                    key: "value",
                    display_name: "tabs.completed",
                    type: "text",
                    placeholder: "tabs.value",
                  },
                  {
                    display: true,
                    value: null,
                    key: "date",
                    display_name: "tabs.date",
                    type: "date-picker",
                    placeholder: "Choose date",
                  },
                  {
                    display: true,
                    value: null,
                    key: "time",
                    display_name: "tabs.time_of_day",
                    type: "time-picker",
                    placeholder: "tabs.time_of_day",
                  },
                  {
                    display: true,
                    value: null,
                    key: "notes",
                    display_name: "tabs.notes",
                    type: "textarea",
                    placeholder: "Notes",
                  },
                ],
              },
              {
                name: "mouthwash",
                display_name: "tabs.mouthwash",
                hasRange: false,
                display: true,
                isTable: true,
                icon_url:
                  "https://vianova.s3.amazonaws.com/bc5abb87b6bd90a00f7e72bd90f01aa7611ff9a1.svg",
                icon: "mdi mdi-toothbrush",
                modalItems: [
                  {
                    display: true,
                    value: "tabs.mouthwash",
                    key: "task",
                    display_name: "Task",
                    type: "text",
                    placeholder: "tabs.add_task",
                  },
                  {
                    display: true,
                    value: true,
                    key: "value",
                    display_name: "tabs.completed",
                    type: "checkbox",
                    placeholder: "tabs.value",
                  },
                  {
                    display: true,
                    value: null,
                    key: "date",
                    display_name: "tabs.date",
                    type: "date-picker",
                    placeholder: "Choose date",
                  },
                  {
                    display: true,
                    value: null,
                    key: "time",
                    display_name: "tabs.time_of_day",
                    type: "time-picker",
                    placeholder: "tabs.time_of_day",
                  },
                  {
                    display: true,
                    value: null,
                    key: "notes",
                    display_name: "tabs.notes",
                    type: "textarea",
                    placeholder: "Notes",
                  },
                ],
              },
              {
                name: "tongue_cleaning",
                display_name: "tabs.tongue_cleaning",
                hasRange: false,
                display: true,
                isTable: true,
                icon_url:
                  "https://vianova.s3.amazonaws.com/d42e03b95507ddc2fe4f850e2abd9f476fadd3f5.svg",
                icon: "mdi mdi-toothbrush",
                modalItems: [
                  {
                    display: true,
                    value: "tabs.tongue_cleaning",
                    key: "task",
                    display_name: "Task",
                    type: "text",
                    placeholder: "tabs.add_task",
                  },
                  {
                    display: true,
                    value: true,
                    key: "value",
                    display_name: "tabs.completed",
                    type: "checkbox",
                    placeholder: "tabs.value",
                  },
                  {
                    display: true,
                    value: null,
                    key: "date",
                    display_name: "tabs.date",
                    type: "date-picker",
                    placeholder: "Choose date",
                  },
                  {
                    display: true,
                    value: null,
                    key: "time",
                    display_name: "tabs.time_of_day",
                    type: "time-picker",
                    placeholder: "tabs.time_of_day",
                  },
                  {
                    display: true,
                    value: null,
                    key: "notes",
                    display_name: "tabs.notes",
                    type: "textarea",
                    placeholder: "Notes",
                  },
                ],
              },
            ],
          },
        ],
        notifications: {
          name: "notifications",
          display_name: "Notifications",
          display: true,
          body: [
            {
              type: "notification_type",
              patient: "patient",
              time: "time",
            },
          ],
          patients: [
            {
              patient_name: "James Kamlenlecki",
              priority: "MEDIUM",
              disease: "Blood Pressure",
              disease_value: "151/084",
            },
            {
              patient_name: "James Kamlenlecki",
              priority: "HIGH",
              disease: "Glucose",
              disease_value: "141/084",
            },
            {
              patient_name: "James Kamlenlecki",
              priority: "HIGH",
              disease: "Glucose",
              disease_value: "141/084",
            },
          ],
        },
        reminders: {
          name: "reminders",
          display_name: "Reminders",
          display: true,
          body: [],
          patients: [
            {
              patient_name: "Shihan",
              title: "Check Shihan blood",
              date: "November 26, 2020 09:00 AM",
              status: "Upcoming",
            },
            {
              patient_name: "Shihan",
              title: "Check Shihan blood",
              date: "November 26, 2020 09:00 AM",
              status: "Past Due",
            },
          ],
        },
        trending_data: {
          fields: [
            { key: "vital", label: "Vital" },
            { key: "last", label: "Last" },
            {
              key: "month",
              label: "30 Days",
            },
            { key: "fortnight", label: "14 Days" },
            { key: "week", label: "7 Days" },
            {
              key: "overall",
              label: "Overall",
            },
          ],
        },
      },
    },
    {
      id: 28,
      name: "dental-overview",
      label: "menuitems.overview.text",
      display: true,
      link: "dental-overview",
      icon: "uil-apps",
      content: {
        tabs: [
          {
            id: 17,
            name: "tasks",
            path: "tasks",
            display_name: "menuitems.overview.text",
            icon: "uil-medal",
            display: true,
          },
          {
            id: 18,
            name: "achievements",
            path: "achievements",
            display_name: "tabs.achievements",
            icon: "uil-medal",
            display: true,
          },
        ],
        notifications: {
          name: "notifications",
          display_name: "Notifications",
          display: true,
          body: [
            {
              type: "notification_type",
              patient: "patient",
              time: "time",
            },
          ],
        },
      },
    },
    {
      id: 19,
      name: "appointments",
      label: "menuitems.appointments.text",
      icon: "uil-map-pin-alt",
      display: false,
      link: "appointments",
      content: {
        name: {
          display: true,
          display_name: "Name",
          input_type: "text",
        },
        frequency: {
          display: true,
          display_name: "Frequency",
          input_type: "select",
          options: ["once", "often"],
        },
        appointment_date_time: {
          display: true,
          display_name: "Appointment Date/Time",
          input_type: "datetime-local",
        },
        type: {
          display: true,
          display_name: "Type",
          input_type: "file",
        },
      },
    },
    {
      id: 20,
      name: "communications",
      label: "menuitems.communications.text",
      icon: "uil-calling",
      display: true,
      link: "communications",
      content: {
        tabs: [
          {
            id: 199,
            name: "care-notes",
            path: "care-notes",
            display_name: "tabs.care_notes",
            display: true,
          },
          {
            name: "vitals",
            display_name: "tabs.vitals",
            path: "vitals",
            display: true,
          },
          {
            name: "medications",
            display_name: "tabs.medications",
            path: "medications",
            display: true,
          },
        ],
        modalItems: [
          {
            display: true,
            value: null,
            // "linkedOption": "appointment_type",
            key: "appointment_name",
            display_name: "Appointment Title",
            type: "text",
            placeholder: "Add Appointment Title",
          },
          {
            display: true,
            key: "appointment_type",
            selected: "office_visit",
            mini: true,
            rounded: false,
            display_name: "Appointment Type",
            type: "basebox",
            options: [
              { key: "office_visit", name: "Office Visit" },
              { key: "video_call", name: "Video Call" },
              { key: "phone_call", name: "Phone Call" },
              // {"key": "general", "name": "General"},
            ],
            subOptions: [
              {
                linkedOption: "office_visit",
                display: true,
                key: "address",
                value: null,
                display_name: "Address",
                // "type": "text",
                placeholder: "Set the location",
                type: "multiselect",
                options: [],
              },
              {
                linkedOption: "office_visit",
                display: true,
                key: "doc_name",
                value: null,
                display_name: "Doctor's Name",
                type: "text",
                placeholder: "Doctor's Name",
              },
              {
                linkedOption: "office_visit",
                display: true,
                key: "venue",
                value: null,
                display_name: "Location",
                type: "text",
                placeholder: "Location",
              },
              {
                linkedOption: "office_visit",
                display: true,
                key: "phone_number",
                value: null,
                display_name: "Phone Number",
                type: "text",
                placeholder: "Phone Number",
              },
              {
                linkedOption: "video_call",
                display: false,
                key: "nurse",
                value: null,
                display_name: "Care Team Member",
                type: "text",
                placeholder: "Care Team Member",
              },
              {
                linkedOption: "video_call",
                display: false,
                key: "participants",
                value: null,
                display_name: "Manage Participants",
                type: "button",
                icon: "people-fill",
                variant: "outline-primary",
                placeholder: "Manage Participants",
              },
              {
                linkedOption: "phone_call",
                display: false,
                key: "nurse",
                value: null,
                display_name: "Care Team Member",
                type: "text",
                placeholder: "Care Team Member",
              },
              // {
              //   "linkedOption": "general",
              //   "display": false,
              //   "key": "appointment_name1",
              //   "value": null,
              //   "display_name": "Appointment Name",
              //   "type": "text",
              //   "placeholder": "Appointment Name",
              // }
            ],
          },
          {
            display: true,
            selected: "custom",
            key: "frequency",
            display_name: "Frequency",
            mini: true,
            rounded: false,
            type: "basebox",
            options: [
              { key: "once", name: "Once" },
              { key: "daily", name: "Daily" },
              { key: "weekly", name: "Weekly" },
              { key: "monthly", name: "Monthly" },
              { key: "custom", name: "Custom Days" },
              { key: "until_done", name: "Until Done" },
            ],
            subOptions: [
              {
                linkedOption: [
                  "custom",
                  "office_visit",
                  "video_call",
                  "phone_call",
                  "general",
                ],
                value: 1,
                key: "start_after",
                display: false,
                display_name: "Start On:",
                type: "date-picker",
              },
              {
                linkedOption: [
                  "custom",
                  "office_visit",
                  "video_call",
                  "phone_call",
                  "general",
                ],
                value: 1,
                key: "repeat_every",
                display: false,
                display_name: "Repeat Every:",
                type: "number",
              },
              {
                linkedOption: [
                  "once",
                  "daily",
                  "weekly",
                  "monthly",
                  "custom",
                  "until_done",
                  "office_visit",
                  "video_call",
                  "phone_call",
                  "general",
                ],
                display: true,
                key: "time",
                value: null,
                display_name: "Time",
                type: "time-picker",
                placeholder: "Choose time",
              },
              {
                linkedOption: [
                  "monthly",
                  "office_visit",
                  "video_call",
                  "phone_call",
                  "general",
                ],
                display: false,
                key: "day_of_month",
                value: null,
                display_name: "Day of Month",
                placeholder: "Choose day of month",
                type: "multiselect",
                options: [...Array(31).keys()].map((x) => ({
                  key: x++,
                  display_name: `${x++}`,
                })),
              },

              {
                linkedOption: "once",
                display: true,
                key: "date",
                value: null,
                display_name: "Date",
                type: "date-picker",
                placeholder: "Choose the date",
              },
              {
                linkedOption: "weekly",
                display: false,
                selected: null,
                key: "week_days",
                display_name: "Week Days",
                rounded: true,
                mini: false,
                type: "basebox",
                multiple: true,
                options: [
                  { id: 1, key: "sun", name: "S" },
                  { id: 2, key: "mon", name: "M" },
                  { id: 3, key: "tue", name: "T" },
                  { id: 4, key: "wed", name: "W" },
                  { id: 5, key: "thu", name: "T" },
                  { id: 6, key: "fri", name: "F" },
                  { id: 7, key: "sat", name: "S" },
                ],
              },
              // {
              //   "linkedOption": ["once", "daily", "weekly", "monthly", "custom", "until_done", "video_call"],
              //   "display": false,
              //   "key": "link",
              //   "value": 'selected',
              //   "display_name": "Create Video Call Link",
              //   "type": "checkbox",
              //   "placeholder": "Create Video Call Link",
              // },
              {
                linkedOption: [
                  "once",
                  "daily",
                  "weekly",
                  "monthly",
                  "custom",
                  "until_done",
                  "video_call",
                ],
                display: false,
                key: "invite",
                value: "selected",
                display_name: null,
                type: "checkbox",
                placeholder: "Allow Invites",
              },
              // {
              //   linkedOption: [
              //     "once",
              //     "daily",
              //     "weekly",
              //     "monthly",
              //     "custom",
              //     "until_done",
              //     "video_call",
              //   ],
              //   display: false,
              //   key: "external_meeting",
              //   value: true,
              //   display_name: null,
              //   type: "checkbox",
              //   placeholder: "External Meeting",
              // },
            ],
          },
          {
            display: true,
            key: "note",
            value: null,
            display_name: "Notes",
            type: "textarea",
            placeholder: "Write something...",
          },
        ],
      },
    },
    {
      id: 25,
      name: "alerts",
      label: "menuitems.alerts.text",
      display: true,
      icon: "uil-bell",
      link: "alerts",
      body: {
        display: true,
        type: "notification_type",
        patient: {
          name: "patient",
          display: true,
        },
        time: {
          name: "time",
          display: true,
        },
        forward_button: {
          name: "forward_button",
          display_name: "Forward",
          enable: true,
        },
        call_button: {
          name: "call_button",
          display_name: "Call",
          enable: true,
        },
      },
    },
    {
      id: 22,
      name: "calendar",
      label: "menuitems.calendar.text",
      icon: "uil-calendar-alt",
      display: true,
      link: "calendar",
      content: {
        calendar_events: {
          display: true,
          display_name: "Calendar Event",
          name: {
            display_name: "Event Name",
            display: true,
          },
          category: {
            display_name: "Category",
            display: true,
            types: [],
          },
          time: {
            display_name: "Time",
            display: true,
          },
        },
      },
    },
    {
      id: 21,
      name: "care-plan",
      label: "menuitems.care_plan.text",
      icon: "uil-book-medical",
      display: true,
      link: "care-plan",
      content: {
        tasks: {
          fields: [
            {
              key: "display_name",
              label: "Display Name",
              display: true,
              display_table: true,
            },
            {
              key: "task_type",
              label: "Task Type",
              display: true,
              display_table: true,
            },
            {
              key: "frequency",
              label: "Frequency",
              display: true,
              display_table: true,
            },
            { key: "plan", label: "Plan", display: true, display_table: true },
            { key: "actions", label: "", display: true, display_table: true },
          ],
          modalItems: [
            {
              display: true,
              key: "task_type",
              value: null,
              display_name: "Name",
              type: "multiselect",
              placeholder: "Choose Task Type",
              options: [
                { key: "blood_pressure", display_name: "Blood Pressure" },
                { key: "glucose", display_name: "Glucose" },
                { key: "weight", display_name: "Weight" },
                { key: "heart_rate", display_name: "Heart Rate" },
              ],
            },
            {
              display: true,
              value: null,
              linkedOption: "task_type",
              key: "display_name",
              display_name: "Display Name",
              type: "text",
            },
            {
              display: true,
              value: null,
              key: "frequency",
              display_name: "Frequency",
              type: "multiselect",
              placeholder: "Once",
              options: [
                { key: "once", display_name: "Once" },
                { key: "daily", display_name: "Daily" },
                { key: "weekly", display_name: "Weekly" },
                { key: "monthly", display_name: "Monthly" },
                { key: "custom", display_name: "Custom Days" },
                { key: "until_done", display_name: "Until Done" },
              ],
            },
            {
              linkedSubOption: "Daily",
              value: null,
              key: "day",
              display: false,
              multiple: true,
              display_name: "Time of the day",
              type: "multiselect",
              options: [
                { key: "am", display_name: "AM" },
                { key: "noon", display_name: "Noon" },
                { key: "afternoon", display_name: "Afternoon" },
                { key: "pm", display_name: "PM" },
              ],
            },
            {
              linkedSubOption: "Weekly",
              value: null,
              key: "week_days",
              display: false,
              multiple: true,
              display_name: "Week Days",
              type: "multiselect",
              options: [
                { id: 1, key: "sun", name: "S" },
                { id: 2, key: "mon", name: "M" },
                { id: 3, key: "tue", name: "T" },
                { id: 4, key: "wed", name: "W" },
                { id: 5, key: "thu", name: "T" },
                { id: 6, key: "fri", name: "F" },
                { id: 7, key: "sat", name: "S" },
              ],
            },
            {
              linkedSubOption: "Monthly",
              value: null,
              key: "months",
              display: false,
              display_name: "Day of months",
              type: "multiselect",
              options: [...Array(31).keys()].map((x) => ({
                key: x++,
                display_name: `${x++}`,
              })),
            },
            // {
            //   "linkedSubOption": "Custom Days",
            //   "value": 1,
            //   "key": "start_after",
            //   "display": false,
            //   "display_name": "Start After",
            //   "type": "number",
            // },
            // {
            //   "linkedSubOption": "Custom Days",
            //   "value": 1,
            //   "key": "repeat_every",
            //   "display": false,
            //   "display_name": "Repeat Every",
            //   "type": "number",
            // },
            // {
            //   "display": false,
            //   "value": '(User Added)',
            //   "key": "plan",
            //   "display_name": "Goal",
            //   "type": "text",
            // },
          ],
        },
        medications: {
          fields: [
            { key: "name", label: "Name", display: true, display_table: true },
            {
              key: "frequency",
              label: "Frequency",
              display: true,
              display_table: true,
            },
            { key: "PRN", label: "PRN", display: true, display_table: true },
            {
              key: "dosage",
              label: "Dosage",
              display: true,
              display_table: true,
            },
            {
              key: "timesPerDay",
              label: "Times per Day",
              display: true,
              display_table: true,
            },
            // { "key": "time", "label": "Time", "display": true, "display_table": false },
            // { "key": "numOfPills", "label": "Number of Pills", "display": true, "display_table": false },
            {
              key: "RxNumber",
              label: "Rx Number",
              display: true,
              display_table: false,
            },
            {
              key: "description",
              label: "Description",
              display: true,
              display_table: false,
            },
            { key: "actions", label: "", display: true, display_table: true },
          ],
          modalItems: [
            {
              display: true,
              key: "name",
              value: null,
              display_name: "Name",
              type: "multiselect",
              placeholder: "Choose Medication Type",
              options: [
                { key: "januvia", display_name: "Januvia" },
                { key: "farxiga", display_name: "Farxiga" },
                { key: "victoza", display_name: "Victoza" },
                { key: "tanzeum", display_name: "Tanzeum" },
              ],
            },
            {
              display: true,
              value: null,
              key: "frequency",
              display_name: "Frequency",
              type: "multiselect",
              placeholder: "Once",
              options: [
                { key: "once", display_name: "Once" },
                { key: "daily", display_name: "Daily" },
                { key: "weekly", display_name: "Weekly" },
                { key: "monthly", display_name: "Monthly" },
                { key: "custom", display_name: "Custom Days" },
                { key: "until_done", display_name: "Until Done" },
              ],
            },
            {
              linkedSubOption: "Daily",
              value: null,
              key: "day",
              display: false,
              multiple: true,
              display_name: "Time of the day",
              type: "multiselect",
              options: [
                { key: "am", display_name: "AM" },
                { key: "noon", display_name: "Noon" },
                { key: "afternoon", display_name: "Afternoon" },
                { key: "pm", display_name: "PM" },
              ],
            },
            {
              linkedSubOption: "Weekly",
              value: null,
              key: "week_days",
              display: false,
              multiple: true,
              display_name: "Week Days",
              type: "multiselect",
              options: [
                { id: 1, key: "sun", name: "S" },
                { id: 2, key: "mon", name: "M" },
                { id: 3, key: "tue", name: "T" },
                { id: 4, key: "wed", name: "W" },
                { id: 5, key: "thu", name: "T" },
                { id: 6, key: "fri", name: "F" },
                { id: 7, key: "sat", name: "S" },
              ],
            },
            {
              linkedSubOption: "Monthly",
              value: null,
              key: "months",
              display: false,
              display_name: "Day of months",
              type: "multiselect",
              options: [...Array(31).keys()].map((x) => ({
                key: x++,
                display_name: `${x++}`,
              })),
            },
            // {
            //   "linkedSubOption": "Custom Days",
            //   "value": 1,
            //   "key": "start_after",
            //   "display": false,
            //   "display_name": "Start After",
            //   "type": "number",
            // },
            // {
            //   "linkedSubOption": "Custom Days",
            //   "value": 1,
            //   "key": "repeat_every",
            //   "display": false,
            //   "display_name": "Repeat Every",
            //   "type": "number",
            // },
            {
              display: true,
              key: "PRN",
              value: null,
              display_name: "PRN",
              type: "multiselect",
              placeholder: "No",
              options: [
                { key: "no", display_name: "No" },
                { key: "yes", display_name: "Yes" },
              ],
            },
            {
              display: true,
              value: "Example: 20mg",
              key: "dosage",
              placeholder: "Example: 20mg",
              display_name: "Dose",
              type: "text",
            },
            {
              display: true,
              key: "timesPerDay",
              value: null,
              display_name: "Times per day",
              type: "multiselect",
              options: [...Array(12).keys()].map((x) => ({
                key: x++,
                display_name: `${x++}`,
              })),
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: null,
              key: "time1",
              display: false,
              display_name: "Time",
              type: "time-picker",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: 1,
              key: "numOfPills1",
              display: false,
              display_name: "Number of Pills",
              type: "number",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: null,
              key: "time2",
              display: false,
              display_name: "Time",
              type: "time-picker",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: 1,
              key: "numOfPills2",
              display: false,
              display_name: "Number of Pills",
              type: "number",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: null,
              key: "time3",
              display: false,
              display_name: "Time",
              type: "time-picker",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: 1,
              key: "numOfPills3",
              display: false,
              display_name: "Number of Pills",
              type: "number",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: null,
              key: "time4",
              display: false,
              display_name: "Time",
              type: "time-picker",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: 1,
              key: "numOfPills4",
              display: false,
              display_name: "Number of Pills",
              type: "number",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: null,
              key: "time5",
              display: false,
              display_name: "Time",
              type: "time-picker",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: 1,
              key: "numOfPills5",
              display: false,
              display_name: "Number of Pills",
              type: "number",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: null,
              key: "time6",
              display: false,
              display_name: "Time",
              type: "time-picker",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: 1,
              key: "numOfPills6",
              display: false,
              display_name: "Number of Pills",
              type: "number",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: null,
              key: "time7",
              display: false,
              display_name: "Time",
              type: "time-picker",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: 1,
              key: "numOfPills7",
              display: false,
              display_name: "Number of Pills",
              type: "number",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: null,
              key: "time8",
              display: false,
              display_name: "Time",
              type: "time-picker",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: 1,
              key: "numOfPills8",
              display: false,
              display_name: "Number of Pills",
              type: "number",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: null,
              key: "time9",
              display: false,
              display_name: "Time",
              type: "time-picker",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: 1,
              key: "numOfPills9",
              display: false,
              display_name: "Number of Pills",
              type: "number",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: null,
              key: "time10",
              display: false,
              display_name: "Time",
              type: "time-picker",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: 1,
              key: "numOfPills10",
              display: false,
              display_name: "Number of Pills",
              type: "number",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: null,
              key: "time11",
              display: false,
              display_name: "Time",
              type: "time-picker",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: 1,
              key: "numOfPills11",
              display: false,
              display_name: "Number of Pills",
              type: "number",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: null,
              key: "time12",
              display: false,
              display_name: "Time",
              type: "time-picker",
            },
            {
              // "linkedSubOption": 1,
              linkedTo: "timesPerday",
              value: 1,
              key: "numOfPills12",
              display: false,
              display_name: "Number of Pills",
              type: "number",
            },
            {
              display: true,
              value: "Rx Number",
              key: "RxNumber",
              display_name: "Rx Number",
              type: "text",
            },
            {
              name: "actions",
              display: true,
              value: "",
              key: "description",
              placeholder: "Description",
              display_name: "Description",
              type: "textarea",
            },
          ],
        },
        plan_builder_task: {
          modalItems: [
            {
              display: true,
              value: null,
              key: "task_type",
              display_name: "Task Type",
              type: "multiselect",
              options: [],
              disabled: false,
              placeholder: "Add Task Title",
            },
            {
              display: true,
              key: "priority",
              value: "High",
              display_name: "Priority",
              type: "multiselect",
              placeholder: "Choose Priority",
              options: [
                { key: "high", display_name: "High" },
                { key: "medium", display_name: "Medium" },
                { key: "low", display_name: "Low" },
              ],
            },
            {
              display: true,
              value: null,
              key: "task_name",
              display_name: "Task Name",
              type: "text",
              placeholder: "Add Task Name",
            },
            {
              display: true,
              selected: "once",
              key: "frequency",
              display_name: "Frequency",
              mini: true,
              rounded: false,
              type: "basebox",
              options: [
                { key: "once", name: "Once" },
                { key: "daily", name: "Daily" },
                { key: "weekly", name: "Weekly" },
                { key: "monthly", name: "Monthly" },
                { key: "custom", name: "Custom Days" },
                { key: "until_done", name: "Until Done" },
              ],
              subOptions: [
                {
                  linkedOption: [
                    "custom",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  value: 1,
                  key: "start_after",
                  display: false,
                  display_name: "Start On:",
                  type: "date-picker",
                },
                {
                  linkedOption: [
                    "custom",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  value: 1,
                  key: "repeat_every",
                  display: false,
                  display_name: "Repeat Every:",
                  type: "number",
                },
                {
                  linkedOption: [
                    "daily",
                    "weekly",
                    "monthly",
                    "until_done",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  display: false,
                  key: "start_date",
                  value: null,
                  display_name: "Start Date:",
                  type: "date-picker",
                },
                {
                  linkedOption: [
                    "daily",
                    "weekly",
                    "monthly",
                    "until_done",
                    "custom",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  display: true,
                  key: "end_date",
                  value: null,
                  display_name: "End Date:",
                  type: "date-picker",
                },
                {
                  linkedOption: [
                    "monthly",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  display: false,
                  key: "day_of_month",
                  value: null,
                  display_name: "Day of Month",
                  placeholder: "Choose day of month",
                  type: "multiselect",
                  options: [...Array(31).keys()].map((x) => ({
                    key: x++,
                    display_name: `${x++}`,
                  })),
                },

                // {
                //   "linkedOption": ["once", "daily", "weekly", "monthly", "custom", "until_done", "office_visit", "video_call", "phone_call", "general"],
                //   "display": true,
                //   "key": "time",
                //   "value": null,
                //   "display_name": "Time",
                //   "type": "time-picker",
                //   "placeholder": "Choose time",
                // },
                {
                  linkedOption: "once",
                  display: true,
                  key: "date",
                  value: null,
                  display_name: "Date",
                  type: "date-picker",
                  placeholder: "Choose the date",
                },
                {
                  linkedOption: "weekly",
                  display: false,
                  selected: null,
                  key: "week_days",
                  display_name: "Week Days",
                  rounded: true,
                  mini: false,
                  type: "basebox",
                  multiple: true,
                  options: [
                    { id: 1, key: "sun", name: "S" },
                    { id: 2, key: "mon", name: "M" },
                    { id: 3, key: "tue", name: "T" },
                    { id: 4, key: "wed", name: "W" },
                    { id: 5, key: "thu", name: "T" },
                    { id: 6, key: "fri", name: "F" },
                    { id: 7, key: "sat", name: "S" },
                  ],
                },
                // {
                //   "linkedOption": ["once", "daily", "weekly", "monthly", "custom", "until_done", "video_call"],
                //   "display": false,
                //   "key": "link",
                //   "value": 'not_selected',
                //   "display_name": "Create Video Call Link",
                //   "type": "checkbox",
                //   "placeholder": "Create Video Call Link",
                // },
              ],
            },
          ],
        },
        plan_builder_medication: {
          modalItems: [
            {
              display: true,
              value: null,
              key: "medication_type",
              display_name: "Medication Type",
              type: "multiselect",
              placeholder: "Add Medication Title",
              options: [],
            },
            {
              display: true,
              key: "priority",
              value: "High",
              display_name: "Priority",
              type: "multiselect",
              placeholder: "Choose Priority",
              options: [
                { key: "high", display_name: "High" },
                { key: "medium", display_name: "Medium" },
                { key: "low", display_name: "Low" },
              ],
            },
            // {
            //   "display": true,
            //   "value": null,
            //   "key": "medication_name",
            //   "display_name": "Medication Name",
            //   "type": "text",
            //   "placeholder": "Add Medication Name"
            // },
            {
              display: true,
              selected: "once",
              value: "Once",
              key: "timesPerday",
              display_name: "How many times a day?",
              type: "multiselect",
              placeholder: "Add Medication Time",
              options: [
                { key: "once", display_name: "Once a day" },
                { key: "twice", display_name: "Twice a day" },
                { key: "thrice", display_name: "3 times a day" },
                { key: "four", display_name: "4 times a day" },
                { key: "five", display_name: "5 times a day" },
                { key: "six", display_name: "6 times a day" },
              ],
              subOptions: [
                {
                  linkedOption: "Once a day",
                  key: "time-1",
                  value: "08:00",
                  display_name: "Time:",
                  type: "time-picker",
                  placeholder: "Time",
                  display: true,
                },
                {
                  linkedOption: "Once a day",
                  key: "dosage-1",
                  value: 1,
                  display_name: "Dosage:",
                  type: "number",
                  placeholder: "Dosage",
                  display: true,
                },
                {
                  linkedOption: "Twice a day",
                  key: "time-2",
                  value: "08:00",
                  display_name: "Time:",
                  type: "time-picker",
                  placeholder: "Time",
                  display: false,
                },
                {
                  linkedOption: "Twice a day",
                  key: "dosage-2",
                  value: 1,
                  display_name: "Dosage:",
                  type: "number",
                  placeholder: "Dosage",
                  display: false,
                },
                {
                  linkedOption: "Twice a day",
                  key: "time-3",
                  value: "23:00",
                  display_name: "Time:",
                  type: "time-picker",
                  placeholder: "Time",
                  display: false,
                },
                {
                  linkedOption: "Twice a day",
                  key: "dosage-3",
                  value: 1,
                  display_name: "Dosage:",
                  type: "number",
                  placeholder: "Dosage",
                  display: false,
                },
                {
                  linkedOption: "3 times a day",
                  key: "time-4",
                  value: "08:00",
                  display_name: "Time:",
                  type: "time-picker",
                  placeholder: "Time",
                  display: false,
                },
                {
                  linkedOption: "3 times a day",
                  key: "dosage-4",
                  value: 1,
                  display_name: "Dosage:",
                  type: "number",
                  placeholder: "Dosage",
                  display: false,
                },
                {
                  linkedOption: "3 times a day",
                  key: "time-5",
                  value: "15:30",
                  display_name: "Time:",
                  type: "time-picker",
                  placeholder: "Time",
                  display: false,
                },
                {
                  linkedOption: "3 times a day",
                  key: "dosage-5",
                  value: 1,
                  display_name: "Dosage:",
                  type: "number",
                  placeholder: "Dosage",
                  display: false,
                },
                {
                  linkedOption: "3 times a day",
                  key: "time-6",
                  value: "23:00",
                  display_name: "Time:",
                  type: "time-picker",
                  placeholder: "Time",
                  display: false,
                },
                {
                  linkedOption: "3 times a day",
                  key: "dosage-6",
                  value: 1,
                  display_name: "Dosage:",
                  type: "number",
                  placeholder: "Dosage",
                  display: false,
                },
                {
                  linkedOption: "4 times a day",
                  key: "time-7",
                  value: "08:00",
                  display_name: "Time:",
                  type: "time-picker",
                  placeholder: "Time",
                  display: false,
                },
                {
                  linkedOption: "4 times a day",
                  key: "dosage-7",
                  value: 1,
                  display_name: "Dosage:",
                  type: "number",
                  placeholder: "Dosage",
                  display: false,
                },
                {
                  linkedOption: "4 times a day",
                  key: "time-8",
                  value: "13:00",
                  display_name: "Time:",
                  type: "time-picker",
                  placeholder: "Time",
                  display: false,
                },
                {
                  linkedOption: "4 times a day",
                  key: "dosage-8",
                  value: 1,
                  display_name: "Dosage:",
                  type: "number",
                  placeholder: "Dosage",
                  display: false,
                },
                {
                  linkedOption: "4 times a day",
                  key: "time-9",
                  value: "18:00",
                  display_name: "Time:",
                  type: "time-picker",
                  placeholder: "Time",
                  display: false,
                },
                {
                  linkedOption: "4 times a day",
                  key: "dosage-9",
                  value: 1,
                  display_name: "Dosage:",
                  type: "number",
                  placeholder: "Dosage",
                  display: false,
                },
                {
                  linkedOption: "4 times a day",
                  key: "time-10",
                  value: "23:00",
                  display_name: "Time:",
                  type: "time-picker",
                  placeholder: "Time",
                  display: false,
                },
                {
                  linkedOption: "4 times a day",
                  key: "dosage-10",
                  value: 1,
                  display_name: "Dosage:",
                  type: "number",
                  placeholder: "Dosage",
                  display: false,
                },
                {
                  linkedOption: "5 times a day",
                  key: "time-11",
                  value: "08:00",
                  display_name: "Time:",
                  type: "time-picker",
                  placeholder: "Time",
                  display: false,
                },
                {
                  linkedOption: "5 times a day",
                  key: "dosage-11",
                  value: 1,
                  display_name: "Dosage:",
                  type: "number",
                  placeholder: "Dosage",
                  display: false,
                },
                {
                  linkedOption: "5 times a day",
                  key: "time-12",
                  value: "11:45",
                  display_name: "Time:",
                  type: "time-picker",
                  placeholder: "Time",
                  display: false,
                },
                {
                  linkedOption: "5 times a day",
                  key: "dosage-12",
                  value: 1,
                  display_name: "Dosage:",
                  type: "number",
                  placeholder: "Dosage",
                  display: false,
                },
                {
                  linkedOption: "5 times a day",
                  key: "time-13",
                  value: "15:30",
                  display_name: "Time:",
                  type: "time-picker",
                  placeholder: "Time",
                  display: false,
                },
                {
                  linkedOption: "5 times a day",
                  key: "dosage-13",
                  value: 1,
                  display_name: "Dosage:",
                  type: "number",
                  placeholder: "Dosage",
                  display: false,
                },
                {
                  linkedOption: "5 times a day",
                  key: "time-14",
                  value: "19:15",
                  display_name: "Time:",
                  type: "time-picker",
                  placeholder: "Time",
                  display: false,
                },
                {
                  linkedOption: "5 times a day",
                  key: "dosage-14",
                  value: 1,
                  display_name: "Dosage:",
                  type: "number",
                  placeholder: "Dosage",
                  display: false,
                },
                {
                  linkedOption: "5 times a day",
                  key: "time-15",
                  value: "23:00",
                  display_name: "Time:",
                  type: "time-picker",
                  placeholder: "Time",
                  display: false,
                },
                {
                  linkedOption: "5 times a day",
                  key: "dosage-15",
                  value: 1,
                  display_name: "Dosage:",
                  type: "number",
                  placeholder: "Dosage",
                  display: false,
                },
                {
                  linkedOption: "6 times a day",
                  key: "time-16",
                  value: "08:00",
                  display_name: "Time:",
                  type: "time-picker",
                  placeholder: "Time",
                  display: false,
                },
                {
                  linkedOption: "6 times a day",
                  key: "dosage-16",
                  value: 1,
                  display_name: "Dosage:",
                  type: "number",
                  placeholder: "Dosage",
                  display: false,
                },
                {
                  linkedOption: "6 times a day",
                  key: "time-17",
                  value: "12:00",
                  display_name: "Time:",
                  type: "time-picker",
                  placeholder: "Time",
                  display: false,
                },
                {
                  linkedOption: "6 times a day",
                  key: "dosage-17",
                  value: 1,
                  display_name: "Dosage:",
                  type: "number",
                  placeholder: "Dosage",
                  display: false,
                },
                {
                  linkedOption: "6 times a day",
                  key: "time-18",
                  value: "16:00",
                  display_name: "Time:",
                  type: "time-picker",
                  placeholder: "Time",
                  display: false,
                },
                {
                  linkedOption: "6 times a day",
                  key: "dosage-18",
                  value: 1,
                  display_name: "Dosage:",
                  type: "number",
                  placeholder: "Dosage",
                  display: false,
                },
                {
                  linkedOption: "6 times a day",
                  key: "time-19",
                  value: "20:00",
                  display_name: "Time:",
                  type: "time-picker",
                  placeholder: "Time",
                  display: false,
                },
                {
                  linkedOption: "6 times a day",
                  key: "dosage-19",
                  value: 1,
                  display_name: "Dosage:",
                  type: "number",
                  placeholder: "Dosage",
                  display: false,
                },
                {
                  linkedOption: "6 times a day",
                  key: "time-20",
                  value: "00:00",
                  display_name: "Time:",
                  type: "time-picker",
                  placeholder: "Time",
                  display: false,
                },
                {
                  linkedOption: "6 times a day",
                  key: "dosage-20",
                  value: 1,
                  display_name: "Dosage:",
                  type: "number",
                  placeholder: "Dosage",
                  display: false,
                },
                {
                  linkedOption: "6 times a day",
                  key: "time-21",
                  value: "04:00",
                  display_name: "Time:",
                  type: "time-picker",
                  placeholder: "Time",
                  display: false,
                },
                {
                  linkedOption: "6 times a day",
                  key: "dosage-21",
                  value: 1,
                  display_name: "Dosage:",
                  type: "number",
                  placeholder: "Dosage",
                  display: false,
                },
              ],
            },
            {
              display: true,
              selected: "once",
              key: "frequency",
              display_name: "Frequency",
              mini: true,
              rounded: false,
              type: "basebox",
              options: [
                { key: "once", name: "Once" },
                { key: "daily", name: "Daily" },
                { key: "weekly", name: "Weekly" },
                { key: "monthly", name: "Monthly" },
                // {"key": "custom", "name": "Custom Days"},
                { key: "until_done", name: "Until Done" },
              ],
              subOptions: [
                {
                  linkedOption: [
                    "custom",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  value: 1,
                  key: "start_after",
                  display: false,
                  display_name: "Start On:",
                  type: "date-picker",
                },
                {
                  linkedOption: [
                    "custom",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  value: 1,
                  key: "repeat_every",
                  display: false,
                  display_name: "Repeat Every:",
                  type: "number",
                },
                {
                  linkedOption: [
                    "daily",
                    "weekly",
                    "monthly",
                    "until_done",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  display: false,
                  key: "start_date",
                  value: null,
                  display_name: "Start Date:",
                  type: "date-picker",
                },
                {
                  linkedOption: [
                    "daily",
                    "weekly",
                    "monthly",
                    "until_done",
                    "custom",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  display: true,
                  key: "end_date",
                  value: null,
                  display_name: "End Date:",
                  type: "date-picker",
                },
                {
                  linkedOption: [
                    "monthly",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  display: false,
                  key: "day_of_month",
                  value: null,
                  display_name: "Day of Month",
                  placeholder: "Choose day of month",
                  type: "multiselect",
                  options: [...Array(31).keys()].map((x) => ({
                    key: x++,
                    display_name: `${x++}`,
                  })),
                },

                // {
                //   "linkedOption": ["once", "daily", "weekly", "monthly", "custom", "until_done", "office_visit", "video_call", "phone_call", "general"],
                //   "display": true,
                //   "key": "time",
                //   "value": null,
                //   "display_name": "Time",
                //   "type": "time-picker",
                //   "placeholder": "Choose time",
                // },
                {
                  linkedOption: "once",
                  display: true,
                  key: "date",
                  value: null,
                  display_name: "Date",
                  type: "date-picker",
                  placeholder: "Choose the date",
                },
                {
                  linkedOption: "weekly",
                  display: false,
                  selected: null,
                  key: "week_days",
                  display_name: "Week Days",
                  rounded: true,
                  mini: false,
                  type: "basebox",
                  multiple: true,
                  options: [
                    { id: 1, key: "sun", name: "S" },
                    { id: 2, key: "mon", name: "M" },
                    { id: 3, key: "tue", name: "T" },
                    { id: 4, key: "wed", name: "W" },
                    { id: 5, key: "thu", name: "T" },
                    { id: 6, key: "fri", name: "F" },
                    { id: 7, key: "sat", name: "S" },
                  ],
                },
              ],
            },
            {
              display: true,
              value: null,
              key: "notes",
              display_name: "Notes",
              type: "textarea",
              placeholder: "Notes",
            },
          ],
        },
        plan_builder_education: {
          modalItems: [
            {
              display: true,
              mini: true,
              selected: "video_call",
              value: "Video",
              key: "education_type",
              display_name: "Education Type",
              type: "multiselect",
              placeholder: "Add Education Title",
              options: [
                { key: "video_call", display_name: "Video" },
                { key: "chat", display_name: "Chat" },
              ],
              subOptions: [
                {
                  display: true,
                  value: null,
                  linkedOption: "Video",
                  key: "video_url",
                  display_name: "Video URL",
                  type: "text",
                  placeholder: "Add Video URL",
                },
                {
                  display: false,
                  value: null,
                  linkedOption: "Chat",
                  key: "topic",
                  display_name: "Topic",
                  // "type": "text",
                  type: "multiselect",
                  placeholder: "Choose Topic",
                  options: [],
                },
                {
                  display: false,
                  value: null,
                  linkedOption: "Chat",
                  key: "conversation",
                  display_name: "Conversation",
                  //"type": "text",
                  //"placeholder": "Conversation"
                  type: "multiselect",
                  placeholder: "Choose Conversation",
                  options: [],
                },
              ],
            },
            {
              display: true,
              key: "priority",
              value: "High",
              display_name: "Priority",
              type: "multiselect",
              placeholder: "Choose Priority",
              options: [
                { key: "high", display_name: "High" },
                { key: "medium", display_name: "Medium" },
                { key: "low", display_name: "Low" },
              ],
            },
            {
              display: true,
              value: null,
              key: "education_name",
              display_name: "Education Name",
              type: "text",
              placeholder: "Add Education Name",
            },
            {
              display: true,
              selected: "once",
              key: "frequency",
              display_name: "Frequency",
              mini: true,
              rounded: false,
              type: "basebox",
              options: [
                { key: "once", name: "Once" },
                { key: "daily", name: "Daily" },
                { key: "weekly", name: "Weekly" },
                { key: "monthly", name: "Monthly" },
                { key: "custom", name: "Custom Days" },
                { key: "until_done", name: "Until Done" },
              ],
              subOptions: [
                {
                  linkedOption: [
                    "custom",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  value: 1,
                  key: "start_after",
                  display: false,
                  display_name: "Start On:",
                  type: "date-picker",
                },
                {
                  linkedOption: [
                    "custom",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  value: 1,
                  key: "repeat_every",
                  display: false,
                  display_name: "Repeat Every:",
                  type: "number",
                },
                {
                  linkedOption: [
                    "daily",
                    "weekly",
                    "monthly",
                    "until_done",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  display: false,
                  key: "start_date",
                  value: null,
                  display_name: "Start Date:",
                  type: "date-picker",
                },
                {
                  linkedOption: [
                    "daily",
                    "weekly",
                    "monthly",
                    "until_done",
                    "custom",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  display: true,
                  key: "end_date",
                  value: null,
                  display_name: "End Date:",
                  type: "date-picker",
                },
                {
                  linkedOption: [
                    "monthly",
                    "office_visit",
                    "video",
                    "phone_call",
                    "general",
                  ],
                  display: false,
                  key: "day_of_month",
                  value: null,
                  display_name: "Day of Month",
                  placeholder: "Choose day of month",
                  type: "multiselect",
                  options: [...Array(31).keys()].map((x) => ({
                    key: x++,
                    display_name: `${x++}`,
                  })),
                },
                // {
                //   "linkedOption": ["once", "daily", "weekly", "monthly", "custom", "until_done", "office_visit", "video"],
                //   "display": true,
                //   "key": "time",
                //   "value": null,
                //   "display_name": "Time",
                //   "type": "time-picker",
                //   "placeholder": "Choose time",
                // },
                {
                  linkedOption: "once",
                  display: true,
                  key: "date",
                  value: null,
                  display_name: "Date",
                  type: "date-picker",
                  placeholder: "Choose the date",
                },
                {
                  linkedOption: "weekly",
                  display: false,
                  selected: null,
                  key: "week_days",
                  display_name: "Week Days",
                  rounded: true,
                  mini: false,
                  type: "basebox",
                  multiple: true,
                  options: [
                    { id: 1, key: "sun", name: "S" },
                    { id: 2, key: "mon", name: "M" },
                    { id: 3, key: "tue", name: "T" },
                    { id: 4, key: "wed", name: "W" },
                    { id: 5, key: "thu", name: "T" },
                    { id: 6, key: "fri", name: "F" },
                    { id: 7, key: "sat", name: "S" },
                  ],
                },
                // {
                //   "linkedOption": ["once", "daily", "weekly", "monthly", "custom", "until_done", "video_call"],
                //   "display": false,
                //   "key": "link",
                //   "value": 'not_selected',
                //   "display_name": "Create Video Call Link",
                //   "type": "checkbox",
                //   "placeholder": "Create Video Call Link",
                // },
              ],
            },
          ],
        },
        plan_builder_vital: {
          modalItems: [
            {
              display: true,
              value: null,
              key: "vital",
              display_name: "Vital Type",
              type: "multiselect",
              placeholder: "Add Vital Title",
              options: [
                { key: "heart_rate", display_name: "Heart Rate" },
                { key: "weight", display_name: "Weight" },
                { key: "blood_pressure", display_name: "Blood Pressure" },
                { key: "glucose", display_name: "Glucose" },
                { key: "oxygen_saturation", display_name: "Oxygen Saturation" },
              ],
            },
            {
              display: true,
              key: "priority",
              value: "High",
              display_name: "Priority",
              type: "multiselect",
              placeholder: "Choose Priority",
              options: [
                { key: "high", display_name: "High" },
                { key: "medium", display_name: "Medium" },
                { key: "low", display_name: "Low" },
              ],
            },
            {
              display: true,
              value: null,
              key: "task_name",
              display_name: "Task Name",
              type: "text",
              placeholder: "Add Task Name",
            },
            {
              display: true,
              selected: "once",
              key: "frequency",
              display_name: "Frequency",
              mini: true,
              rounded: false,
              type: "basebox",
              options: [
                { key: "once", name: "Once" },
                { key: "daily", name: "Daily" },
                { key: "weekly", name: "Weekly" },
                { key: "monthly", name: "Monthly" },
                { key: "custom", name: "Custom Days" },
                { key: "until_done", name: "Until Done" },
              ],
              subOptions: [
                {
                  linkedOption: [
                    "custom",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  value: 1,
                  key: "start_after",
                  display: false,
                  display_name: "Start On:",
                  type: "date-picker",
                },
                {
                  linkedOption: [
                    "custom",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  value: 1,
                  key: "repeat_every",
                  display: false,
                  display_name: "Repeat Every:",
                  type: "number",
                },
                {
                  linkedOption: [
                    "daily",
                    "weekly",
                    "monthly",
                    "until_done",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  display: false,
                  key: "start_date",
                  value: null,
                  display_name: "Start Date:",
                  type: "date-picker",
                },
                {
                  linkedOption: [
                    "daily",
                    "weekly",
                    "monthly",
                    "until_done",
                    "custom",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  display: true,
                  key: "end_date",
                  value: null,
                  display_name: "End Date:",
                  type: "date-picker",
                },
                {
                  linkedOption: [
                    "monthly",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  display: false,
                  key: "day_of_month",
                  value: null,
                  display_name: "Day of Month",
                  placeholder: "Choose day of month",
                  type: "multiselect",
                  options: [...Array(31).keys()].map((x) => ({
                    key: x++,
                    display_name: `${x++}`,
                  })),
                },
                // {
                //   "linkedOption": ["once", "daily", "weekly", "monthly", "custom", "until_done", "office_visit", "video_call", "phone_call", "general"],
                //   "display": true,
                //   "key": "time",
                //   "value": null,
                //   "display_name": "Time",
                //   "type": "time-picker",
                //   "placeholder": "Choose time",
                // },
                {
                  linkedOption: [
                    "once",
                    "daily",
                    "weekly",
                    "monthly",
                    "custom",
                    "until_done",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  display: true,
                  selected: null,
                  key: "time_type_data",
                  display_name: "Selected Time",
                  rounded: false,
                  mini: true,
                  type: "basebox",
                  multiple: true,
                  options: [
                    { id: 1, key: "AM", name: "AM" },
                    { id: 2, key: "Noon", name: "Noon" },
                    { id: 3, key: "Afternoon", name: "Afternoon" },
                    { id: 4, key: "PM", name: "PM" },
                  ],
                },
                {
                  linkedOption: "once",
                  display: true,
                  key: "date",
                  value: null,
                  display_name: "Date",
                  type: "date-picker",
                  placeholder: "Choose the date",
                },
                {
                  linkedOption: "weekly",
                  display: false,
                  selected: null,
                  key: "week_days",
                  display_name: "Week Days",
                  rounded: true,
                  mini: false,
                  type: "basebox",
                  multiple: true,
                  options: [
                    { id: 1, key: "sun", name: "S" },
                    { id: 2, key: "mon", name: "M" },
                    { id: 3, key: "tue", name: "T" },
                    { id: 4, key: "wed", name: "W" },
                    { id: 5, key: "thu", name: "T" },
                    { id: 6, key: "fri", name: "F" },
                    { id: 7, key: "sat", name: "S" },
                  ],
                },
                // {
                //   "linkedOption": ["once", "daily", "weekly", "monthly", "custom", "until_done", "video_call"],
                //   "display": false,
                //   "key": "link",
                //   "value": 'not_selected',
                //   "display_name": "Create Video Call Link",
                //   "type": "checkbox",
                //   "placeholder": "Create Video Call Link",
                // },
              ],
            },
          ],
        },
        plan_builder_conversations: {
          modalItems: [
            {
              display: true,
              key: "priority",
              value: "High",
              display_name: "Priority",
              type: "multiselect",
              placeholder: "Choose Priority",
              options: [
                { key: "high", display_name: "High" },
                { key: "medium", display_name: "Medium" },
                { key: "low", display_name: "Low" },
              ],
            },
            {
              display: true,
              value: null,
              key: "conversation_name",
              display_name: "Conversation Name",
              type: "text",
              placeholder: "Add Task Name",
            },
            // {
            //   "display": true,
            //   "value": null,
            //   "linkedOption": "Video",
            //   "key": "video_url",
            //   "display_name": "Video URL",
            //   "type": "text",
            //   "placeholder": "Add Video URL"
            // },
            {
              display: true,
              value: null,
              // "linkedOption": "Chat",
              key: "topic",
              display_name: "Topic",
              // "type": "text",
              type: "multiselect",
              placeholder: "Choose Topic",
              options: [],
            },
            {
              display: true,
              value: null,
              // "linkedOption": "Chat",
              key: "conversation",
              display_name: "Conversation",
              //"type": "text",
              //"placeholder": "Conversation"
              type: "multiselect",
              placeholder: "Choose Conversation",
              options: [],
            },
            {
              display: true,
              selected: "once",
              key: "frequency",
              display_name: "Frequency",
              mini: true,
              rounded: false,
              type: "basebox",
              options: [
                { key: "once", name: "Once" },
                { key: "daily", name: "Daily" },
                { key: "weekly", name: "Weekly" },
                { key: "monthly", name: "Monthly" },
                { key: "custom", name: "Custom Days" },
                { key: "until_done", name: "Until Done" },
              ],
              subOptions: [
                {
                  linkedOption: [
                    "custom",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  value: 1,
                  key: "start_after",
                  display: false,
                  display_name: "Start On:",
                  type: "date-picker",
                },
                {
                  linkedOption: [
                    "custom",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  value: 1,
                  key: "repeat_every",
                  display: false,
                  display_name: "Repeat Every:",
                  type: "number",
                },
                {
                  linkedOption: [
                    "daily",
                    "weekly",
                    "monthly",
                    "until_done",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  display: false,
                  key: "start_date",
                  value: null,
                  display_name: "Start Date:",
                  type: "date-picker",
                },
                {
                  linkedOption: [
                    "daily",
                    "weekly",
                    "monthly",
                    "until_done",
                    "custom",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  display: true,
                  key: "end_date",
                  value: null,
                  display_name: "End Date:",
                  type: "date-picker",
                },
                {
                  linkedOption: [
                    "monthly",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  display: false,
                  key: "day_of_month",
                  value: null,
                  display_name: "Day of Month",
                  placeholder: "Choose day of month",
                  type: "multiselect",
                  options: [...Array(31).keys()].map((x) => ({
                    key: x++,
                    display_name: `${x++}`,
                  })),
                },
                // {
                //   "linkedOption": ["once", "daily", "weekly", "monthly", "custom", "until_done", "office_visit", "video_call", "phone_call", "general"],
                //   "display": true,
                //   "key": "time",
                //   "value": null,
                //   "display_name": "Time",
                //   "type": "time-picker",
                //   "placeholder": "Choose time",
                // },
                {
                  linkedOption: "once",
                  display: true,
                  key: "date",
                  value: null,
                  display_name: "Date",
                  type: "date-picker",
                  placeholder: "Choose the date",
                },
                {
                  linkedOption: "weekly",
                  display: false,
                  selected: null,
                  key: "week_days",
                  display_name: "Week Days",
                  rounded: true,
                  mini: false,
                  type: "basebox",
                  multiple: true,
                  options: [
                    { id: 1, key: "sun", name: "S" },
                    { id: 2, key: "mon", name: "M" },
                    { id: 3, key: "tue", name: "T" },
                    { id: 4, key: "wed", name: "W" },
                    { id: 5, key: "thu", name: "T" },
                    { id: 6, key: "fri", name: "F" },
                    { id: 7, key: "sat", name: "S" },
                  ],
                },
                // {
                //   "linkedOption": ["once", "daily", "weekly", "monthly", "custom", "until_done", "video_call"],
                //   "display": false,
                //   "key": "link",
                //   "value": 'not_selected',
                //   "display_name": "Create Video Call Link",
                //   "type": "checkbox",
                //   "placeholder": "Create Video Call Link",
                // },
              ],
            },
          ],
        },
        plan_builder_reminder: {
          modalItems: [
            // {
            //   "display": true,
            //   "value": null,
            //   "key": "task_name",
            //   "display_name": "Task Name",
            //   "type": "text",
            //   "placeholder": "Add Task Name"
            // },
            {
              display: true,
              value: null,
              key: "task_type",
              display_name: "Task Name",
              type: "multiselect",
              options: [],
              disabled: false,
              placeholder: "Add Task Name",
            },
            {
              display: true,
              value: null,
              key: "reminder",
              display_name: "Reminder",
              type: "textarea",
              placeholder: "Write Something...",
            },
            {
              display: true,
              selected: "once",
              key: "frequency",
              display_name: "Frequency",
              mini: true,
              rounded: false,
              type: "basebox",
              options: [
                { key: "once", name: "Once" },
                { key: "daily", name: "Daily" },
                { key: "weekly", name: "Weekly" },
                { key: "monthly", name: "Monthly" },
                { key: "custom", name: "Custom Days" },
                { key: "until_done", name: "Until Done" },
              ],
              subOptions: [
                {
                  linkedOption: [
                    "monthly",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  display: false,
                  key: "day_of_month",
                  value: null,
                  display_name: "Day of Month",
                  placeholder: "Choose day of month",
                  type: "multiselect",
                  options: [...Array(31).keys()].map((x) => ({
                    key: x++,
                    display_name: `${x++}`,
                  })),
                },
                {
                  linkedOption: [
                    "custom",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  value: 1,
                  key: "start_after",
                  display: false,
                  display_name: "Start On:",
                  type: "date-picker",
                },
                {
                  linkedOption: [
                    "custom",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  value: 1,
                  key: "repeat_every",
                  display: false,
                  display_name: "Repeat Every:",
                  type: "number",
                },
                {
                  linkedOption: [
                    "once",
                    "daily",
                    "weekly",
                    "monthly",
                    "custom",
                    "until_done",
                    "office_visit",
                    "video_call",
                    "phone_call",
                    "general",
                  ],
                  display: true,
                  key: "time",
                  value: null,
                  display_name: "Time",
                  type: "time-picker",
                  placeholder: "Choose time",
                },
                {
                  linkedOption: "once",
                  display: true,
                  key: "date",
                  value: null,
                  display_name: "tabs.date",
                  type: "date-picker",
                  placeholder: "Choose the date",
                },
                {
                  linkedOption: "weekly",
                  display: false,
                  selected: null,
                  key: "week_days",
                  display_name: "Week Days",
                  rounded: true,
                  mini: false,
                  type: "basebox",
                  multiple: true,
                  options: [
                    { id: 1, key: "sun", name: "S" },
                    { id: 2, key: "mon", name: "M" },
                    { id: 3, key: "tue", name: "T" },
                    { id: 4, key: "wed", name: "W" },
                    { id: 5, key: "thu", name: "T" },
                    { id: 6, key: "fri", name: "F" },
                    { id: 7, key: "sat", name: "S" },
                  ],
                },
                // {
                //   "linkedOption": ["once", "daily", "weekly", "monthly", "custom", "until_done", "video_call"],
                //   "display": false,
                //   "key": "link",
                //   "value": 'not_selected',
                //   "display_name": "Create Video Call Link",
                //   "type": "checkbox",
                //   "placeholder": "Create Video Call Link",
                // },
              ],
            },
          ],
        },
      },
    },
    {
      id: 21,
      name: "Care Plan [DEV]",
      label: "Care Plan [DEV]",
      icon: "uil-book-medical",
      display: true,
      link: "tracks",
    },
    {
      id: 17,
      name: "attachments",
      link: "attachments",
      label: "menuitems.attachments.text",
      icon: "uil-link",
      display: false,
      content: {
        tabs: [
          {
            id: 18,
            name: "images",
            path: "images",
            display_name: "Images",
            icon: "uil-heart-rate",
            display: true,
            content: [
              {
                name: "glucose",
                display_name: "Glucose",
                display: true,
                isTable: true,
                icon: "mdi mdi-water",
                modalItems: [
                  {
                    display: true,
                    value: "Glucose",
                    key: "vital",
                    display_name: "Vital",
                    type: "text",
                    placeholder: "Add Vital",
                  },
                  {
                    display: true,
                    value: null,
                    key: "value",
                    display_name: "Value",
                    type: "text",
                    placeholder: "Add Value",
                  },
                  {
                    display: true,
                    value: null,
                    key: "date",
                    display_name: "tabs.date",
                    type: "date-picker",
                    placeholder: "Choose date",
                  },
                  {
                    display: true,
                    value: null,
                    key: "time",
                    display_name: "Time of Day",
                    type: "time-picker",
                    placeholder: "Time of Day",
                  },
                  {
                    display: true,
                    value: null,
                    key: "notes",
                    display_name: "Notes",
                    type: "textarea",
                    placeholder: "Notes",
                  },
                ],
              },
            ],
          },
          {
            name: "videos",
            display_name: "Videos",
            path: "videos",
            display: true,
            content: [],
          },
          {
            name: "documents",
            display_name: "Documents",
            path: "documents",
            display: true,
            content: [],
          },
        ],
      },
    },

    {
      id: 23,
      name: "devices",
      label: "menuitems.devices.text",
      icon: "uil-desktop",
      display: true,
      link: "devices",
      content: {
        serial_number: {
          display_name: "Serial Number",
          display: true,
        },
        solution_id: {
          display_name: "Solution ID",
          display: true,
        },
        type: {
          display_name: "Type",
          display: true,
        },
      },
      add_button: {
        enabled: true,
        display_name: "Add New Device",
      },
    },
    {
      id: 24,
      name: "reminders",
      label: "menuitems.reminders.text",
      icon: "uil-stopwatch",
      display: false,
      link: "reminders",
      add_button: {
        name: "add_new_reminder",
        display_name: "Add New Reminder",
        enabled: true,
      },
    },
    {
      id: 26,
      name: "plan",
      label: "Plan",
      display: false,
      icon: "uil-package",
      link: "plan",
      plan_type: {
        display_name: "Plan",
        display: true,
      },
      reset_data: {
        display_name: "Reset Data",
        display: true,
      },
      assign_button: {
        display_name: "Assign Button",
        display: true,
      },
    },
    {
      id: 27,
      name: "playground",
      label: "Playground",
      display: process.env.NUXT_ENV_ENVIRONMENT !== "production",
      icon: "fas fa-atom",
      link: "playground",
    },
  ],
};
